import { CHAIN_TYPE_EVM, CHAIN_TYPE_TVM } from "../constants/ChainTypes";
import {
  CONNECTED_PROVIDERS,
  PROVIDER_EVERWALLET,
  PROVIDER_METAMASK,
  PROVIDER_TRUST_BROWSER,
  PROVIDER_VENOM,
} from "../constants/ProviderTypes";
import { WalletList } from "../constants/WalletList";
import { localStorageObj } from "../features/localstorage";
import { emptyWalletInfo } from "../features/walletService/walletService";

export const isNetworkWalletConnected = (connectedWallets, currentNetwork) => {
  let mainWallet = connectedWallets.find((connectedWallet) =>
    currentNetwork.wallets.includes(connectedWallet.providerType)
  );
  return mainWallet ? true : false;
};

export const getNetworkWalletAddress = (connectedWallets, currentNetwork) => {
  let mainWallet = connectedWallets.find((connectedWallet) =>
    currentNetwork.wallets.includes(connectedWallet.providerType)
  );
  return mainWallet &&
    (currentNetwork.chainType == CHAIN_TYPE_EVM ||
      (currentNetwork.chainType == CHAIN_TYPE_TVM &&
        mainWallet.networkId == currentNetwork.id))
    ? mainWallet?.accountAddress
    : null;
};

export const getNetworkWallet = (connectedWallets, currentNetwork) => {
  let wallet = emptyWalletInfo;

  if (!currentNetwork) {
    return wallet;
  }

  let mainWallet = connectedWallets.find((connectedWallet) =>
    currentNetwork.wallets.includes(connectedWallet.providerType)
  );

  return mainWallet || wallet;
};

export const getWalletType = (networkType) => {
  let walletTypes = [];
  switch (networkType) {
    case CHAIN_TYPE_EVM:
      walletTypes = [PROVIDER_METAMASK, PROVIDER_TRUST_BROWSER];
      break;
    case CHAIN_TYPE_TVM:
      walletTypes = [PROVIDER_EVERWALLET];
      break;
    default:
      break;
  }
  return walletTypes;
};

export const checkNetworkWalletType = (network, walletTypes) => {
  return network.wallets.some((walletProvider) =>
    walletTypes.includes(walletProvider)
  );
};

export const isEvmWallet = (network) => {
  return checkNetworkWalletType(network, [PROVIDER_METAMASK]);
};

export const isEverWallet = (network) => {
  return checkNetworkWalletType(network, [PROVIDER_EVERWALLET]);
};

export const isVenomWallet = (network) => {
  return checkNetworkWalletType(network, [PROVIDER_VENOM]);
};

export const getWallet = (
  connectedWallets,
  networkType = CHAIN_TYPE_EVM,
  returnEmpty = true
) => {
  let mainWallet = connectedWallets.find(
    (connectedWallet) =>
      WalletList.findIndex(
        (wallet) =>
          wallet.key == connectedWallet.providerType &&
          wallet.supportNetworks.includes(networkType)
      ) != -1
  );
  return returnEmpty && mainWallet == null ? emptyWalletInfo : mainWallet;
};

export const getWalletByProviderType = (
  connectedWallets,
  providerType,
  returnEmpty = false
) => {
  let mainWallet = connectedWallets.find(
    (connectedWallet) => connectedWallet.providerType == providerType
  );
  return returnEmpty && mainWallet == null ? emptyWalletInfo : mainWallet;
};

export const getWalletByTransport = (
  connectedWallets,
  transportType,
  returnEmpty = false
) => {
  let mainWallet = connectedWallets.find(
    (connectedWallet) => connectedWallet.transportType == transportType
  );
  return returnEmpty && mainWallet == null ? emptyWalletInfo : mainWallet;
};

export const isWalletConnected = (
  connectedWallets,
  networkType = CHAIN_TYPE_EVM
) => {
  if (connectedWallets.length == 0) {
    return false;
  }
  const wallet = getWallet(connectedWallets, networkType);
  return wallet != null;
};

export const hasOldConnectionWallet = (providerType) => {
  let providers = localStorageObj.get(CONNECTED_PROVIDERS);
  let connected = providers ? JSON.parse(providers) : [];
  return connected.includes(providerType);
};

export const cutAddressFormat = (accountAddress) => {
  return accountAddress.slice(0, 6) + "..." + accountAddress.slice(-4);
};
