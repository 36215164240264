import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAgreeBtnClicked,
  showAgreementDialog,
} from "../../features/walletService/walletService";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import { BigNumber, ethers, utils } from "ethers";
import {
  formatAmountToUint,
  getDataByUrl,
  getPiceByCryptoSymbol,
  isAmountLess,
  numberToHex,
  setApprove,
  transferStart,
} from "../../utils/TransferApiUtil";
import axios from "axios";
import TransactionSubmitPopup from "../../components/Swap/TransactionSubmitPopup";
import TransferHeader from "../../components/SwapMenu/SwapMenu";
import { isZeroAddress } from "../../features/walletService/utils";
import {
  amountErrorText,
  BSC_CHAIN_ID,
  DEFAULT_TOKEN_SYMBOL,
  DEFAULT_TOKEN_SYMBOL2,
  ETH_CHAIN_ID,
  GAS_AMOUNT_USD,
  GAS_AMOUNT_USD_ETHER,
  MAX_ROUTE_REFRESH_TIME,
  PROCESS_FIND_ROUTE,
  PROCESS_SWAP_ROUTE,
  STEP_APPROVE_APPROVED,
  STEP_APPROVE_REJECTED,
  STEP_APPROVE_STARTED,
  STEP_FORM_FILL,
  STEP_FORM_NO_ROUTE_FOUND,
  STEP_IN_TRANSFER_APPROVED,
  STEP_IN_TRANSFER_REJECTED,
  STEP_IN_TRANSFER_REJECTED_BY_CONTRACT,
  STEP_IN_TRANSFER_START,
  STEP_LOAD_QUOTA,
  STEP_READY_APPROVE,
  // STEP_READY_START_SWAP,
  // STEP_READY_TRANSACTION,
  STEP_SWITCH_NETWORK,
  STEP_TRANSFER_APPROVED,
  STEP_TRANSFER_FINISHED,
  STEP_TRANSFER_REJECTED,
  STEP_TRANSFER_START,
  STEP_UPDATE_RATES,
  TCPT_TOKEN_SYMBOL,
  // STEP_TRANSFER_START,
  // STEP_TRANSFER_PROCESS,
  TOKEN_DIRECTION_FROM,
  VENOM_CHAIN_ID,
} from "../../constants/TransferConstants";
import TransferProcess from "../../components/Swap/TransferProcess";
import {
  BTN_KEY_APPROVE,
  BTN_KEY_APPROVE_RETRY,
  BTN_KEY_CHAIN_CHANGE,
  BTN_KEY_IN_TRANSFER_RETRY,
  BTN_KEY_RETURN_SWAP_FORM,
  BTN_KEY_TRANSFER,
  BTN_KEY_TRANSFER_RETRY,
  BTN_KEY_UPDATE_RATE,
  BTN_START_FROM_ROUTE,
} from "../../constants/SwapButtonConstants";
import {
  TYPE_ALOWANCE,
  TYPE_APPROVE,
  // TYPE_REFUSED,
  TYPE_REJECTED,
  // TYPE_TRANSFER,
} from "../../constants/TransactionStepConstants";
import SwapFormBock from "../../components/Swap/SwapFormBock";
import SwapTelegramLink from "../../components/Swap/SwapTelegramLink";
import SwapDisclaimer from "../../components/Swap/SwapDisclaimer";
import TransactionStepAlert from "../../components/Swap/TransactionStepAlert";
import { ALERT_WARNING } from "../../constants/AlertTypes";
import {
  closeNotification,
  showNotifaction,
} from "../../features/dialogs/notificationPopupSlice";
import {
  clearChooseToken,
  showChooseToken,
} from "../../features/dialogs/chooseTokenSlice";
import useGatewayApprove from "../../hooks/walletGateway/useGatewayApprove";
import useGatewayTransfer from "../../hooks/walletGateway/useGatewayTransfer";
import useGatewayNetworkChange from "../../hooks/walletGateway/useGatewayNetworkChange";
import useGatewayBalance from "../../hooks/walletGateway/useGatewayBalance";
import useGatewayCalcFee from "../../hooks/walletGateway/useGatewayCalcFee";
import {
  DEBRIDGE,
  DESWAP,
  VENOM_BRIDGE,
} from "../../constants/ServiceConstants";
import { ymSwapClick } from "../../utils/BrowserUtil";
import { VenomContext } from "../../App";
// import useVenomTransfer from "../../hooks/venom/useVenomTransfer";
import { PROVIDER_METAMASK } from "../../constants/ProviderTypes";
import {
  getVenomRate,
  isVenomConfirmed,
  transferEvmAlienToVenomData,
} from "../../hooks/venom/VenomUtil";
import {
  PROXY_CONTRACT_ADDRESS,
  TCPT_TOKEN_ADDRESS,
} from "../../constants/CustomContracts";
import ClaimToken from "../../components/Swap/ClaimToken";
import { getWallet } from "../../utils/WalletUtil";
import { CHAIN_TYPE_TVM } from "../../constants/ChainTypes";
import {
  IsTvmApproveRequired,
  getNetworkImageByChainId,
  isTvm,
  isVenomRoute,
} from "../../utils/NetworkList";
import { getProxyCallData } from "../../utils/ProxyCall";
import { useTransferRouteRefresh } from "../../hooks/transfer/useTransferRouteRefresh";
import { useVenomEventUpdater } from "../../hooks/transfer/useVenomEventUpdater";
import { useTokenApprove } from "../../hooks/transfer/useTokenApprove";
import { useTransferApprove } from "../../hooks/transfer/useTransferApprove";
import SwapDisclaimerNew from "../../components/Swap/SwapDisclaimerNew";
import { clearAmount } from "../../utils/CommonUtil";
import { VENOM_TRANSFER_URL } from "../../constants/RoutePaths";

export default function TransferForm() {
  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );
  const walletInfo = getWallet(connectedWallets);
  const tvmWallet = getWallet(connectedWallets, CHAIN_TYPE_TVM, false);
  const venomProvider = useContext(VenomContext);

  // let walletInfo = useSelector(
  //   (state) => state.walletServiceProvider.walletInfo
  // );
  let error = useSelector((state) => state.walletServiceProvider.error);
  let chooseTokenState = useSelector((state) => state.chooseTokenManager);
  let agreeBtnCliked = useSelector(
    (state) => state.walletServiceProvider.agreeBtnCliked
  );

  const [showSubmitPopup, toggleSubmitPopup] = useState(false);

  const [popupInfo, setPopupInfo] = useState({
    show: false,
    type: null,
  });

  const [routes, setRoutes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [directType, setTokenDiretType] = useState(null);
  const [prevRoute, setPrevRoute] = useState({
    routeFrom: null,
    cryptoFrom: null,
    routeTo: null,
    cryptoTo: null,
  });

  const [transactionStep, setTransactionStep] = useState(STEP_FORM_FILL);

  // const onProcessTransactions = useRef([]);
  const curTransId = useRef(null);

  const [onProcessTransactions, setTransactionList] = useState([]);
  const process = onProcessTransactions.find((v) => v.id == curTransId.current);

  const [transactionProccessStep, setTransactionProccessStep] =
    useState(PROCESS_FIND_ROUTE);

  const [transactionData, setDataTransaction] = useState({
    chainId: 1,
    data: null,
    from: null,
    gasLimit: null,
    gasPrice: null,
    to: null,
    value: null,
    approvalAddress: null,
    transactionId: null,

    estimateSeconds: null,
    hopsCount: null,
    router: null,
    bridge: null,
    fee: null,
    exChangeService: null,
    calcFee: null,
    // amountFromUSD:null,
  });

  const [formData, setFormData] = useState({
    amountFrom: "",
    routeFrom: null,
    cryptoFrom: null,
    quoteTo: 0,
    quoteFrom: 0,
    amountTo: "",
    amountToUSD: 0,
    amountFromUSD: 0,
    routeTo: null,
    cryptoTo: null,
  });

  // const [tokenBalance, setTokenBalance] = useState(0.0);
  // const [tokenOutBalance, setTokenOutBalance] = useState(0.0);

  const clearFormAmount = (clearAmountFrom = true) => {
    setFormData((prevState) => ({
      ...prevState,
      ...{
        amountFrom: clearAmountFrom ? 0 : prevState.amountFrom,
        amountFromUSD: 0,
        amountTo: "",
        amountToUSD: 0,
      },
    }));
  };
  const resetForm = (clearAmountFrom) => {
    clearFormAmount(clearAmountFrom);
    setTransactionStep(STEP_FORM_FILL);
  };
  const fieldChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function openTokenSelector(type) {
    setTokenDiretType(type);
    let route =
      type === TOKEN_DIRECTION_FROM ? formData.routeFrom : formData.routeTo;
    dispatch(
      showChooseToken({
        routes: routes,
        directType: type,
        routeFrom: formData.routeFrom,
        cryptoFrom: formData.cryptoFrom,
        curRoute: route,
      })
    );
  }

  const dispatch = useDispatch();

  const storePrevRouteToken = () => {
    setPrevRoute({
      routeFrom: formData.routeFrom,
      cryptoFrom: formData.cryptoFrom,
      routeTo: formData.routeTo,
      cryptoTo: formData.cryptoTo,
    });
  };

  useEffect(() => {
    if (!chooseTokenState.show && chooseTokenState.selectedItem) {
      let selectedItem = chooseTokenState.selectedItem;
      closeTokenPopup(selectedItem);
      dispatch(clearChooseToken());
    }
  }, [chooseTokenState.show]);

  function closeTokenPopup(selectedItem) {
    // toggleTokenPopup(false);
    console.log(selectedItem, "selectedItem");
    setErrorMsg(null);
    if (selectedItem) {
      storePrevRouteToken();
      let routeTo =
        directType !== TOKEN_DIRECTION_FROM
          ? selectedItem.route
          : formData.routeTo;
      let cryptoTo =
        directType !== TOKEN_DIRECTION_FROM
          ? selectedItem.token
          : formData.cryptoTo;
      if (
        directType === TOKEN_DIRECTION_FROM &&
        selectedItem.route.chainId === BSC_CHAIN_ID &&
        selectedItem.token.symbol == TCPT_TOKEN_SYMBOL
      ) {
        routeTo = routes.find((v) => v.chainId == VENOM_CHAIN_ID && isTvm(v));
        cryptoTo = routeTo.cryptos.find((v) => v.symbol == TCPT_TOKEN_SYMBOL);
      }
      setFormData((prevState) => ({
        ...prevState,
        ...{
          routeTo: routeTo,
          cryptoTo: cryptoTo,

          amountFrom:
            directType === TOKEN_DIRECTION_FROM ? "" : formData.amountFrom,
          amountFromUSD:
            directType === TOKEN_DIRECTION_FROM ? 0 : formData.amountFromUSD,
          amountTo: "",
          amountToUSD: 0,

          routeFrom:
            directType === TOKEN_DIRECTION_FROM
              ? selectedItem.route
              : formData.routeFrom,
          cryptoFrom:
            directType === TOKEN_DIRECTION_FROM
              ? selectedItem.token
              : formData.cryptoFrom,
        },
      }));
    }
  }

  const closeSubmitPopup = () => {
    toggleSubmitPopup(false);
  };

  const reverseToPrevData = () => {
    setFormData((prevState) => ({
      ...prevState,
      ...{
        cryptoTo: prevRoute.cryptoTo,
        routeTo: prevRoute.routeTo,
        cryptoFrom: prevRoute.cryptoFrom,
        routeFrom: prevRoute.routeFrom,
      },
    }));
  };

  const loadRoutes = async () => {
    setLoading(true);
    let res = await getDataByUrl("router/networks-list");
    if (!res.hasError && res.data.success == undefined) {
      setRoutes(res.data);
    } else {
      setErrorMsg(res.errorText || res.data.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(error, "error");
    setErrorMsg(error);
  }, [error]);

  const setErrorMsg = (errorText) => {
    console.log(errorText, "errorText");
    dispatch(closeNotification());
    showStepPopup(null, false);
    if (errorText !== null && typeof errorText != "string") {
      errorText = JSON.stringify(errorText);
    }
    if (errorText != null && errorText.startsWith(amountErrorText)) {
      dispatch(
        showNotifaction({ alertType: ALERT_WARNING, caption: errorText })
      );
      // setWarningError(errorText);
      // setWarningError(amountWarning);
    } else {
      if (errorText != error) {
        dispatch(
          showNotifaction({ alertType: ALERT_WARNING, caption: errorText })
        );
      }
      // setShowErrorPopup(errorText != null);
    }
  };

  useEffect(() => {
    // onCryptoSelect();
    if (formData.cryptoFrom) setCryptoQuote(formData.cryptoFrom.id,formData.cryptoFrom.symbol);
  }, [formData.routeFrom, formData.cryptoFrom]);

  useEffect(() => {
    resetForm(false);
    if (walletInfo.isConnected) {
      startLoadingQuota();
    }
    // else{
    //   if(formData.amountFrom && formData.amountFrom > 0){
    //     loadQuotaWithoutWallet();
    //   }
    // }
  }, [
    formData.routeFrom,
    formData.cryptoFrom,
    formData.routeTo,
    formData.cryptoTo,
    formData.amountFrom,
    walletInfo.isConnected,
  ]);

  useEffect(() => {
    if (formData.cryptoTo && formData.amountTo > 0) {
      setCryptoQuote(formData.cryptoTo.id,formData.cryptoTo.symbol, false);
    }
  }, [formData.amountTo, formData.cryptoTo]);

  const startLoadingQuota = async () => {
    endTimer();
    if (!formData.routeFrom) {
      return;
    }
    cancelLoadingQuota();
    setUsdQuota();
    setErrorMsg(null);

    if (walletInfo.networkChainId != formData.routeFrom.chainId) {
      setTransactionStep(STEP_SWITCH_NETWORK);
      return;
    }
    if (isAmountLess(formData.amountFrom * (formData.quoteFrom || 0))) {
      setTransactionStep(STEP_FORM_FILL);
      setErrorMsg(amountErrorText);
      return;
    }

    if (formData.amountFrom == null || formData.amountFrom == 0) {
      setTransactionStep(STEP_FORM_FILL);
      return;
    }

    if (isVenomRoute(formData) && !tvmWallet) {
      setTransactionStep(STEP_FORM_FILL);
      return;
    }

    // if (parseFloat(tokenBalance) >= parseFloat(formData.amountFrom)) {
    setTransactionStep(STEP_LOAD_QUOTA);
    if (isVenomRoute(formData)) {
      loadVenomQuota();
    } else {
      loadQuota();
    }
    // }
  };

  const setUsdQuota = () => {
    setFormData((prevState) => ({
      ...prevState,
      ...{
        amountFromUSD: (
          (formData.amountFrom || 0) * formData.quoteFrom
        ).toFixed(4),
      },
    }));
  };

  const cancelLoadingQuota = () => {
    if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    if (curQuotaId.current) curQuotaId.current = new Date().getTime();
  };

  useEffect(() => {
    loadRoutes();
  }, []);

  useEffect(() => {
    if (walletInfo.networkChainId && formData.routeFrom) {
      if (walletInfo.networkChainId != formData.routeFrom.chainId) {
        setTransactionStep(STEP_SWITCH_NETWORK);
      } else {
        setTransactionStep(STEP_FORM_FILL);
        startLoadingQuota();
      }
    }
    if (!walletInfo.networkChainId) {
      setTransactionProccessStep(PROCESS_FIND_ROUTE);
      setTransactionStep(STEP_FORM_FILL);
      clearFormAmount();
    }
  }, [walletInfo.networkChainId]);

  useEffect(() => {
    onRouteLoad();
  }, [routes]);

  const getDefaultRoute = (chainId) => {
    let defaultRoute = null;
    if (routes.some((v) => v.chainId == chainId)) {
      defaultRoute = routes.find((v) => v.chainId == chainId);
    }
    if (!defaultRoute) {
      defaultRoute = routes.length > 0 ? routes[0] : null;
    }
    return defaultRoute;
  };

  const onRouteLoad = () => {
    if (routes.length == 0) return;

    let routeFrom;
    let routeTo;

    let defRouteFromChainId = BSC_CHAIN_ID ;
    let defRouteToChainId = window.location.pathname == VENOM_TRANSFER_URL ? VENOM_CHAIN_ID : ETH_CHAIN_ID;

    let defTokenSymbol = window.location.pathname == VENOM_TRANSFER_URL ? TCPT_TOKEN_SYMBOL : DEFAULT_TOKEN_SYMBOL;
    if (walletInfo.networkChainId) {
      routeFrom = routes.find(
        (v) => parseInt(v.chainId) === parseInt(walletInfo.networkChainId)
      );
      if (!routeFrom) {
        routeFrom = getDefaultRoute(defRouteFromChainId);
      }
      const hasDefaultRoute = routes.find((v) =>
        [defRouteFromChainId, defRouteToChainId].includes(v.chainId)
      );
      if (!formData.routeTo) {
        if (hasDefaultRoute) {
          routeTo = routes.find(
            (v) =>
              parseInt(v.chainId) ==
              (walletInfo.networkChainId == defRouteFromChainId
                ? defRouteToChainId
                : defRouteFromChainId)
          );
        }
        if (!routeTo) {
          routeTo = routes[0];
        }
      }
    } else {
      // routeFrom = getDefaultRoute(ETH_CHAIN_ID);
      // routeTo = getDefaultRoute(BSC_CHAIN_ID);
      routeFrom = getDefaultRoute(defRouteFromChainId);
      routeTo = getDefaultRoute(defRouteToChainId);
    }
    if (routeFrom && routeTo) {
      let cryptoFrom =
        prevRoute.routeTo && prevRoute.routeTo.id == routeFrom.id
          ? prevRoute.cryptoTo
          : routeFrom.cryptos.find((v) => v.symbol === defTokenSymbol);
      if (!cryptoFrom && routeFrom.cryptos.length > 0) {
        cryptoFrom = routeFrom.cryptos[0];
      }
      let cryptoTo = routeTo.cryptos.find(
        (v) => v.symbol === defTokenSymbol
      );
      if (!cryptoTo && routeTo.cryptos.length > 0) {
        cryptoTo = routeTo.cryptos[0];
      }
      if (cryptoFrom && cryptoTo && cryptoFrom.id == cryptoTo.id) {
        cryptoTo = routeTo.cryptos.find(
          (v) => v.symbol === DEFAULT_TOKEN_SYMBOL2
        );
      }

      setFormData((prevState) => ({
        ...prevState,
        ...{
          cryptoFrom: cryptoFrom,
          routeFrom: routeFrom,
          cryptoTo: formData.cryptoTo == null ? cryptoTo : formData.cryptoTo,
          routeTo: formData.routeTo == null ? routeTo : formData.routeTo,
          amountFrom: "",
        },
      }));
    }
  };

  const replaceNetwork = () => {
    storePrevRouteToken();

    setFormData((prevState) => ({
      ...prevState,
      ...{
        cryptoFrom: formData.cryptoTo,
        routeFrom: formData.routeTo,
        cryptoTo: formData.cryptoFrom,
        routeTo: formData.routeFrom,
        amountFrom: formData.amountTo,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const calcGasFee = async (gasLimit) => {
    let amount = 0;
    let amountInUsd = 0;
    try {
      let gasPrice = await MetamaskWebProvider.getGasPrice();
      const nativeCrypto = formData.routeFrom.cryptos.find((v) =>
        isZeroAddress(v.contractAddress)
      );
      const roundDecimal = 4;
      amount = gasPrice
        .mul(BigNumber.from(gasLimit))
        .div(BigNumber.from(10).pow(nativeCrypto.decimals - roundDecimal));

      amount = parseFloat(ethers.utils.formatUnits(amount, roundDecimal));
      const usdCourse = await getPiceByCryptoSymbol(nativeCrypto.symbol);
      amountInUsd = usdCourse.hasError ? 0 : amount * usdCourse.data;
    } catch (error) {
      console.error(error, "error calcGasFee");
    }
    return { amountInNative: amount, amountInUsd: amountInUsd };
  };

  const setTransactionInfo = async (transferInfo) => {
    // return;
    if (walletInfo.networkChainId == formData.routeFrom.chainId) {
      setTransactionStep(STEP_READY_APPROVE);
    } else {
      setTransactionStep(STEP_SWITCH_NETWORK);
    }
    setDataTransaction((prevState) => ({
      ...prevState,
      ...{
        approvalAddress: transferInfo.approvalAddress,
        transactionId: transferInfo.id,
        chainId: transferInfo.transferChainId,
        data: transferInfo.transferData,
        from: transferInfo.transferFromAddress,
        gasLimit: transferInfo.transferGasLimit,
        gasPrice: transferInfo.transferGasPrice,
        to: transferInfo.transferToAddress,
        value: transferInfo.transferAmount,

        estimateSeconds: transferInfo.estimateTransferSeconds,
        hopsCount: transferInfo.hopsCount,
        router: transferInfo.router,
        bridge: transferInfo.bridge ? transferInfo.bridge : null,
        fee: transferInfo.fee,
        calcFee: { amountInNative: 0, amountInUsd: 0 },
        feeInUsd: transferInfo.feeInUsd,
      },
    }));

    setFormData((prevState) => ({
      ...prevState,
      amountTo: clearAmount(transferInfo.estimateToAmount), // parseFloat(utils.formatUnits(transferInfo.estimate.toAmount,transferInfo.action.toToken.decimals)).toFixed(4),
      // amountToUSD: transferInfo.amountInUsd,
      // amountFromUSD:transferInfo.amountFromUsd
    }));
  };

  const cancelTokenSource = useRef();

  const calcMetamaskVal = (incomeData) => {
    const amount2 = calcTransferAmountOut();
    const amount3 = formatAmountToUint(
      amount2,
      formData.cryptoFrom.decimals
    ).toString();
    const resultAmount = isZeroAddress(formData.cryptoFrom.contractAddress)
      ? numberToHex(amount3)
      : [DEBRIDGE, DESWAP].includes(incomeData.router)
      ? numberToHex(incomeData.transferAmount)
      : "0x00";
    return resultAmount;
  };

  const curQuotaId = useRef(null);

  const loadVenomQuota = async () => {
    setTransactionStep(STEP_LOAD_QUOTA);
    curQuotaId.current = new Date().getTime();
    let curId = curQuotaId.current;
    if (!isValidFormData()) {
      setErrorMsg("Form Data invalid.Please fill amount");
      setTransactionStep(STEP_FORM_FILL);
      return;
    }
    let tvmWallet = getWallet(connectedWallets, CHAIN_TYPE_TVM);

    if (!tvmWallet) {
      setErrorMsg("Please connect Venom wallet");
      setTransactionStep(STEP_FORM_FILL);
      return;
    }

    let transferData = await transferEvmAlienToVenomData(
      walletInfo.accountAddress,
      formData.amountFrom + "",
      formData.routeFrom.chainId,
      formData.cryptoFrom.contractAddress,
      formData.cryptoFrom.decimals,
      tvmWallet.accountAddress,
      venomProvider,
      false
    );

    if (!transferData) {
      setErrorMsg("Has no connection to venom network");
      setTransactionStep(STEP_FORM_FILL);
      return;
    }
    if (curId != curQuotaId.current) {
      return;
    }

    let provider = await MetamaskWebProvider.getProvider();
    provider = new ethers.providers.Web3Provider(provider);

    transferData = await getProxyCallData(provider, transferData);
    let minValue = ethers.utils.formatUnits(transferData.value, 18).toString();
    let data = {
      id: "id" + Math.random().toString(16).slice(2),
      approvalAddress: PROXY_CONTRACT_ADDRESS,
      transferChainId: formData.routeFrom.chainId,
      transferData: transferData.data,
      transferFromAddress: walletInfo.accountAddress,
      transferGasLimit: null,
      transferGasPrice: null,
      transferToAddress: PROXY_CONTRACT_ADDRESS,
      transferAmount: transferData.value,
      estimateToAmount: formData.amountFrom,

      estimateTransferSeconds: 180,
      hopsCount: 3,
      router: "venom",
      bridge: VENOM_BRIDGE,
      fee: minValue,
      calcFee: { amountInNative: 0, amountInUsd: 0 },
      feeInUsd: 0.9,
    };
    setTransactionInfo(data);
    // startTimer();
  };

  const loadQuota = async () => {
    // event.preventDefault();
    setErrorMsg(null);
    setTransactionStep(STEP_LOAD_QUOTA);
    if (!isValidFormData()) {
      setErrorMsg("Form Data invalid.Please fill amount");
      setTransactionStep(STEP_FORM_FILL);
      return;
    }

    cancelTokenSource.current = axios.CancelToken.source();
    try {
      //minus amount from native tokens
      const amount = calcTransferAmountOut();
      if (amount < 0) {
        setErrorMsg("Amount is to small to transfer");
        setTransactionStep(STEP_FORM_FILL);
        return;
      }
      let resData = await transferStart(
        amount,
        formData.routeFrom,
        formData.routeTo,
        formData.cryptoFrom,
        formData.cryptoTo,
        walletInfo.accountAddress,
        cancelTokenSource.current.token
      );
      if (resData.hasError) {
        if (!resData.isCanceled) {
          setTransactionStep(STEP_FORM_NO_ROUTE_FOUND);
          setErrorMsg(resData.errorText);
        }
      } else {
        const data = resData.data;
        let amount = resData.data.transferAmount;
        data.transferAmount = calcMetamaskVal(data);
        setTransactionInfo(data);
        setErrorMsg(null);
        startTimer();
      }
    } catch (error) {
      const res = error.response;
      console.error(error, res, "res");
      if (res && res.status == 400) {
        setErrorMsg(res.data.message);
      } else {
        setErrorMsg("ERROR ON RESPONSE OF SERVER");
      }
      setTransactionStep(STEP_FORM_FILL);
    }
  };

  const setMaxAmount = async () => {
    setFormData((prevState) => ({
      ...prevState,
      amountFrom: tokenBalance,
    }));
  };

  const setCryptoQuote = async (cryptoId, symbol,isFrom = true) => {
    const usdCourseInfo =
      isVenomRoute(formData) &&
      [
        "f2e064dc-651f-4ca0-8de9-1574d2f89d45",
        "4262b044-7ac0-4f41-b3f2-1783051b3d93",
      ].includes(cryptoId)
        ? {
            hasError: false,
            data: 0,
          }
        : await getPiceByCryptoSymbol(symbol);
    if (!usdCourseInfo.hasError)
      setFormData((prevState) => ({
        ...prevState,
        ...{
          quoteFrom: isFrom ? usdCourseInfo.data : formData.quoteFrom,
          quoteTo: !isFrom ? usdCourseInfo.data : formData.quoteTo,
          amountToUSD: !isFrom
            ? (formData.amountTo * usdCourseInfo.data).toFixed(4)
            : formData.amountToUSD,
        },
      }));
  };

  const isValidFormData = () => {
    return (
      parseFloat(formData.amountFrom) > 0 &&
      formData.cryptoFrom != null &&
      formData.cryptoTo != null
    );
  };

  const handleTransactionProcess = (step, txHash = null) => {
    setTransactionList((prevState) => {
      const newState = prevState.map((item) => {
        if (item.id == curTransId.current) {
          item.state = step;
          if (step == STEP_APPROVE_REJECTED) {
            item.isApproved = false;
          }
          if (step == STEP_APPROVE_APPROVED) {
            item.approveTx = txHash;
            item.isApproved = true;
          }
          if (step == STEP_TRANSFER_APPROVED) {
            item.transfetTx = txHash;
            item.isTransferApproved = true;
          }
          if (step == STEP_IN_TRANSFER_APPROVED) {
            item.isInNetworkApproved = true;
          }
          if (
            [
              STEP_IN_TRANSFER_APPROVED,
              STEP_TRANSFER_FINISHED,
              STEP_IN_TRANSFER_REJECTED_BY_CONTRACT,
            ].includes(step) &&
            txHash
          ) {
            item.inNetworkTx = txHash;
          }

          if (step == STEP_TRANSFER_FINISHED) {
            item.isFinished = true;
          }
          return item;
        }
        return item;
      });

      return newState;
    });
  };

  useEffect(() => {
    if (
      agreeBtnCliked &&
      walletInfo.providerType == PROVIDER_METAMASK &&
      !MetamaskWebProvider.isMetaSigned(walletInfo.accountAddress)
    ) {
      callMakeMetaSign();
    }
  }, [agreeBtnCliked]);

  const callMakeMetaSign = async () => {
    let isSigned = true;
    if (walletInfo.providerType == PROVIDER_METAMASK) {
      isSigned = await MetamaskWebProvider.makeSign(walletInfo.accountAddress);
    }
    dispatch(changeAgreeBtnClicked(isSigned));
    if (isSigned) {
      approveTransfer();
    }
  };
  const approveTransfer = async (isRetry = false) => {
    if (
      !MetamaskWebProvider.isMetaSigned(walletInfo.accountAddress) &&
      walletInfo.providerType == PROVIDER_METAMASK
    ) {
      dispatch(showAgreementDialog(PROVIDER_METAMASK));
      return;
    }
    beforApprove(isRetry);

    setTransactionProccessStep(PROCESS_SWAP_ROUTE);
    showStepPopup(TYPE_APPROVE);
    setErrorMsg(null);
  };

  const showStepPopup = (type, isShow = true) => {
    setPopupInfo({
      show: isShow,
      type: type,
    });
  };

  const { onApproveRejected, beforApprove, afterApprove, sendTransaction } =
    useTokenApprove(
      process,
      formData,
      setTransactionStep,
      handleTransactionProcess,
      transactionData,
      setTransactionList,
      curTransId
    );

  const { afterTransferApproved, callInNetworkApprove } = useTransferApprove(
    process,
    formData,
    setTransactionStep,
    handleTransactionProcess,
    tvmWallet,
    venomProvider,
    setErrorMsg
  );

  const onFocusAmountFrom = () => {
    if (formData.amountFrom == 0) {
      setFormData((prevState) => ({
        ...prevState,
        amountFrom: "",
      }));
    }
  };

  const returnSwapForm = () => {
    setTransactionProccessStep(PROCESS_FIND_ROUTE);
    setTransactionStep(STEP_READY_APPROVE);
  };

  const returnSwapWithAmount = () => {
    setTransactionProccessStep(PROCESS_FIND_ROUTE);
    startLoadingQuota();
  };

  const updateRateAndQuota = async () => {
    setTransactionStep(STEP_FORM_FILL);
    setFormData((prevState) => ({
      ...prevState,
      amountTo: "",
      amountToUSD: 0,
    }));
    await setCryptoQuote(formData.cryptoFrom.id,formData.cryptoFrom.symbol,);
    await setCryptoQuote(formData.cryptoTo.id, formData.cryptoFrom.symbol,false);
    startLoadingQuota();
  };

  const btnHandler = (key) => {
    switch (key) {
      case BTN_KEY_CHAIN_CHANGE:
        handleChainChange();
        break;
      case BTN_KEY_APPROVE:
        ymSwapClick();
        approveTransfer();
        break;
      case BTN_KEY_UPDATE_RATE:
        updateRateAndQuota();
        break;
      case BTN_KEY_APPROVE_RETRY:
        approveTransfer(true);
        break;
      case BTN_KEY_TRANSFER:
        sendTransaction();
        break;
      case BTN_KEY_TRANSFER_RETRY:
        retryTransfer();
        break;
      case BTN_KEY_RETURN_SWAP_FORM:
        returnSwapForm();
        break;
      case BTN_START_FROM_ROUTE:
        returnSwapWithAmount();
        break;
      case BTN_KEY_IN_TRANSFER_RETRY:
        callInNetworkApprove();
        break;
      default:
        break;
    }
  };

  const retryTransfer = async () => {
    setFormData((prevState) => ({
      ...prevState,
      ...{
        quoteTo: 0,
        amountTo: "",
        amountToUSD: "",
      },
    }));
    setTransactionProccessStep(PROCESS_FIND_ROUTE);
    setTransactionStep(STEP_FORM_FILL);
  };

  // const [timeLeft, setTimeLeft] = useState(0);
  const callbackAfterTimerEnd = () => {
    setTransactionStep(STEP_UPDATE_RATES);
  };

  const { startTimer, endTimer, timeLeft } = useTransferRouteRefresh(
    transactionStep,
    callbackAfterTimerEnd
  );

  const getTransferBlockClass = (step, process) => {
    let className = "";
    if (step == PROCESS_SWAP_ROUTE) {
      className = "transfers_proccess";
      if (
        [STEP_APPROVE_REJECTED, STEP_TRANSFER_REJECTED].includes(process.state)
      ) {
        className = "transfers_warning";
      }
      if (
        [STEP_TRANSFER_APPROVED, STEP_TRANSFER_FINISHED].includes(process.state)
      ) {
        className = "transfers_success";
      }
    }
    return className;
  };

  const transferClass = getTransferBlockClass(transactionProccessStep, process);

  const approveCallback = (approvalState) => {
    if (approvalState.isApproved) {
      afterApprove(approvalState.transaction);
    } else {
      onApproveRejected();
    }
  };

  const networkChangeCallback = (networkChangeState) => {
    // startNetworkChange(false);
    if (networkChangeState.isSuccess) {
    } else {
      if (networkChangeState.error) {
        setErrorMsg(networkChangeState.error);
      }
    }
  };

  const tokenBalance = useGatewayBalance(
    formData.routeFrom,
    formData.cryptoFrom,
    venomProvider
  );

  const tokenOutBalance = useGatewayBalance(
    formData.routeTo,
    formData.cryptoTo,
    venomProvider
  );

  const calcTransferAmountOut = () => {
    if (!isZeroAddress(formData.cryptoFrom.contractAddress)) {
      return formData.amountFrom;
    }
    const gasAmountInUsd =
      formData.routeFrom.chainId == ETH_CHAIN_ID
        ? GAS_AMOUNT_USD_ETHER
        : GAS_AMOUNT_USD;

    const balanceInUsd = tokenBalance * formData.quoteFrom;
    let amountFromUSD = formData.amountFrom * formData.quoteFrom;

    let amount = formData.amountFrom;
    if (
      amountFromUSD > balanceInUsd - gasAmountInUsd &&
      balanceInUsd > gasAmountInUsd
    ) {
      amount -=
        (amountFromUSD - (balanceInUsd - gasAmountInUsd)) / formData.quoteFrom;
    }

    return amount;
  };

  const calcApproveAmount = () => {
    if (!formData.cryptoFrom || !formData.amountFrom) {
      return 0;
    }

    return formatAmountToUint(
      calcTransferAmountOut(),
      formData.cryptoFrom.decimals
    );
  };
  const approveAmount = calcApproveAmount();

  const transferApproveCallback = (transferState) => {
    if (transferState.isApproved) {
      afterTransferApproved(
        transactionData.transactionId,
        transferState.transaction,
        transferState.wait
      );
    } else {
      if (transferState.error == null) {
        showStepPopup(TYPE_REJECTED);
      }
      handleTransactionProcess(STEP_TRANSFER_REJECTED);
      if (transferState.error) {
        setErrorMsg(transferState.error);
      }
    }
  };

  const successClaimCallback = () => {
    const bnbNetwork = routes.find((v) => v.chainId == BSC_CHAIN_ID);
    const cryptoFrom = bnbNetwork.cryptos.find(
      (v) => v.contractAddress == TCPT_TOKEN_ADDRESS
    );
    const venomNetwork = routes.find(
      (v) => v.chainId == VENOM_CHAIN_ID && isTvm(v)
    );

    const cryptoTo = venomNetwork.cryptos.find(
      (v) => v.symbol == TCPT_TOKEN_SYMBOL
    );

    setFormData((prevState) => ({
      ...prevState,
      ...{
        cryptoFrom: cryptoFrom,
        routeFrom: bnbNetwork,
        cryptoTo: cryptoTo,
        routeTo: venomNetwork,
        amountFrom: "",
      },
    }));
  };

  useGatewayCalcFee(
    transactionData.gasLimit,
    formData.routeFrom,
    setDataTransaction
  );
  useGatewayApprove(
    approveAmount,
    formData.cryptoFrom?.contractAddress,
    transactionData.approvalAddress,
    transactionStep,
    approveCallback
  );

  useGatewayTransfer(
    transactionData,
    transactionStep,
    transferApproveCallback,
    formData
  );

  console.log(transactionStep, "transactionStep");

  const { handleChainChange } = useGatewayNetworkChange(
    formData.routeFrom,
    networkChangeCallback
  );

  const venomTransferCallback = (data) => {
    if (data.hasError) {
      setErrorMsg(data.errorText);
    } else {
      const state = isVenomConfirmed(data.status)
        ? STEP_TRANSFER_FINISHED
        : STEP_IN_TRANSFER_REJECTED_BY_CONTRACT;

      setTransactionStep(state);
      handleTransactionProcess(state, data.eventAddress);
    }
  };
  useVenomEventUpdater(
    transactionStep,
    process,
    formData,
    tvmWallet,
    venomProvider,
    venomTransferCallback
  );

  const loadrate = () => {
    let data = isVenomRoute(formData);
    console.log(data, "isVenomRoute");
    getVenomRate("BNB");
  };

  console.log(connectedWallets, "connectedWallets");
  return (
    <>
      <main className="main">
        <section className="page-container">
          <div className="wrapper">
            <div className="transfers-wrapper">
              <div className="transfers-container">
                <ClaimToken
                  formData={formData}
                  successClaimCallback={successClaimCallback}
                />
                <TransferHeader />
                <div className={`transfers ${transferClass}`}>
                  <div className="transfers__content">
                    {transactionProccessStep == PROCESS_FIND_ROUTE && (
                      <SwapFormBock
                        isLoading={isLoading}
                        handleSubmit={handleSubmit}
                        formData={formData}
                        replaceNetwork={replaceNetwork}
                        fieldChange={fieldChange}
                        tokenBalance={tokenBalance}
                        openTokenSelector={openTokenSelector}
                        setMaxAmount={setMaxAmount}
                        tokenOutBalance={tokenOutBalance}
                        transactionData={transactionData}
                        transactionStep={transactionStep}
                        btnHandler={btnHandler}
                        timeLeft={timeLeft}
                        startLoadingQuota={startLoadingQuota}
                      />
                    )}
                    {walletInfo.isConnected &&
                      formData.routeFrom &&
                      transactionProccessStep == PROCESS_SWAP_ROUTE && (
                        <TransferProcess
                          formData={formData}
                          transactionStep={transactionStep}
                          transactionData={transactionData}
                          process={process}
                          curTransId={curTransId}
                          btnHandler={btnHandler}
                        />
                      )}
                  </div>
                  {transactionProccessStep == PROCESS_SWAP_ROUTE && (
                    <SwapTelegramLink />
                  )}
                </div>
                <SwapDisclaimer />
                <SwapDisclaimerNew />
                <button onClick={() => loadrate()}>GetPrice</button>
              </div>
            </div>
          </div>
        </section>
        <TransactionSubmitPopup
          show={showSubmitPopup}
          data={formData}
          closePopup={closeSubmitPopup}
        />
        <TransactionStepAlert
          popupInfo={popupInfo}
          formData={formData}
          process={process}
          closePopup={() => {
            showStepPopup(null, false);
          }}
        />
      </main>
    </>
  );
}
