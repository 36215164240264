import { BigNumber, ethers, utils } from "ethers";
import moment from "moment";
import axios from "../app/axios";
import { CHAINSPOT, DEBRIDGE, DEEBRIDGE, DESWAP, LIFI, XYFINANCE } from "../constants/ServiceConstants";
import {
  CHECK_MIN_AMOUNT,
  MIN_USD_AMOUNT,
  STATUS_APPROVED,
  STATUS_FINISHED,
  STATUS_IN_PROGRESS,
  STATUS_REJECTED,
} from "../constants/TransferConstants";
const DEFAULT_ADDRESS = "0x889298a2ba786fEa75Daa9CfB6c9864097D5d9E2";

export async function transferStart(
  amountFrom,
  routeFrom,
  routeTo,
  cryptoFrom,
  cryptoTo,
  walletAddress = null,
  cancelToken = null
) {
  let amount = formatAmountToUint(amountFrom, cryptoFrom.decimals).toString();
  if (!walletAddress) {
    walletAddress = DEFAULT_ADDRESS;
  }

  let trnInfo = {
    hasError: false,
    errorText: "",
    data: null,
    isCanceled: false,
  };
  try {
    let res = await axios.post(
      "router/create-transaction",
      {
        fromNetworkId: routeFrom.id,
        toNetworkId: routeTo.id,
        fromCryptoId: cryptoFrom.id,
        toCryptoId: cryptoTo.id,
        amount: amount,
        address: walletAddress,
      },
      {
        cancelToken: cancelToken,
      }
    );
    if (
      res.data &&
      !res.data.hasOwnProperty("success") &&
      res.data.hasOwnProperty("id")
    ) {
      trnInfo.data = res.data;
    } else {
      trnInfo.hasError = true;
      let errorMessage = res.data?.message || res.data;
      if (errorMessage == "Connection not found") {
        errorMessage = `${routeFrom.name}  ( ${cryptoFrom.name} ) to ${routeTo.name} ( ${cryptoTo.name} ) route wasn't found`;
      }
      trnInfo.errorText = errorMessage;
    }
  } catch (error) {
    trnInfo.hasError = true;
    let errorMessage = "";
    if (error && error.code == "ERR_CANCELED") {
      trnInfo.isCanceled = true;
    } else {
      errorMessage = error.response.data?.message || error.response.data;
      if (errorMessage == "Connection not found") {
        errorMessage = `${routeFrom.name}  ( ${cryptoFrom.name} ) to ${routeTo.name} ( ${cryptoTo.name} ) route wasn't found`;
      }
    }
    trnInfo.errorText = errorMessage;
  }
  return trnInfo;
}

export async function setHash(transactionId, transactionHash) {
  let res = await axios.post("router/set-hash", {
    transactionId: transactionId,
    hash: transactionHash,
  });

  let setInfo = {
    hasError: false,
    errorText: "",
    data: null,
  };

  if (res && res.status == 200 && res.data.id) {
    setInfo.data = res.data;
  } else {
    setInfo.hasError = true;
    setInfo.errorText = res.data?.message || res.data;
  }
  return setInfo;
}

export async function setApprove(transactionId, hash) {
  let res = await axios.post("router/approve", {
    transactionId: transactionId,
    hash: hash,
  });

  let setInfo = {
    hasError: false,
    errorText: "",
    data: null,
  };

  if (res && res.status == 200 && res.data.id) {
    setInfo.data = res.data;
  } else {
    setInfo.hasError = true;
    setInfo.errorText = res.data?.message || res.data;
  }
  return setInfo;
}

export async function getStatus(transactionId) {
  let res = await axios.get("router/transaction-status", {
    params: { transactionId: transactionId },
  });

  let statusInfo = {
    hasError: false,
    errorText: "",
    data: null,
  };

  if (res && res.status == 200 && res.data) {
    const resp = res.data;
    statusInfo.data = {
      status: resp.data.status,
      txHash: resp.data.sending.txHash,
      txLink: resp.data.sending.txLink,
    };
  } else {
    statusInfo.hasError = true;
    statusInfo.errorText = res.data?.message || res.data;
  }
  return statusInfo;
}

export async function getConnections(
  networkFromId,
  cryptoFromId,
  networkToId,
  cancelToken = null
) {
  let res;

  let statusInfo = {
    hasError: false,
    errorText: "",
    data: null,
  };

  try {
    res = await axios.get(
      "router/connections-list",
      {
        params: {
          networkFromId: networkFromId,
          cryptoFromId: cryptoFromId,
          networkToId: networkToId,
        },
      },
      {
        cancelToken: cancelToken,
      }
    );
  } catch (error) {
    statusInfo.hasError = true;
    if (error.response.data.message) {
      statusInfo.errorText = error.response.data.message;
    }
    return statusInfo;
  }

  if (res && res.status == 200 && res.data) {
    if (res.data.hasOwnProperty("success") && !res.data.success) {
      statusInfo.hasError = true;
      statusInfo.errorText = res.data.message;
    } else {
      statusInfo.data = convertConnectionArray(res.data);
    }
    // statusInfo.data = res.data;
  } else {
    statusInfo.hasError = true;
    statusInfo.errorText = res.data?.message || res.data;
  }
  return statusInfo;
}

const convertConnectionArray = (connections) => {
  return connections.map((v) => {
    let list = v.split(":");
    return { key: list[0], isLiqvid: parseInt(list[1]) == 0 };
  });
};

export async function getAvialableNetworks(networkFromId, cryptoFromId) {
  let res;

  let statusInfo = {
    hasError: false,
    errorText: "",
    data: null,
  };

  try {
    res = await axios.get("router/get-available-networks", {
      params: {
        networkId: networkFromId,
        cryptoId: cryptoFromId,
      },
    });
  } catch (error) {
    statusInfo.hasError = true;
    if (error.response.data.message) {
      statusInfo.errorText = error.response.data.message;
    }
    return statusInfo;
  }

  if (res && res.status == 200 && res.data) {
    if (res.data.hasOwnProperty("success") && !res.data.success) {
      statusInfo.hasError = true;
      statusInfo.errorText = res.data.message;
    } else {
      statusInfo.data = res.data;
    }
    statusInfo.data = res.data.data;
  } else {
    statusInfo.hasError = true;
    statusInfo.errorText = res.data?.message || res.data;
  }

  return statusInfo;
}

export async function getTransactionHistory(
  address,
  status,
  page = 1,
  perPage = 20,
  networkFromId = null,
  networkToId = null,
  cryptoFromId = null,
  cryptoToId = null
) {
  let res = await axios.get("router/transactions-list", {
    params: {
      address: address,
      status: status,
      page: page,
      perPage: perPage,
      networkFromId: networkFromId,
      networkToId: networkToId,
      cryptoFromId: cryptoFromId,
      cryptoToId: cryptoToId,
    },
  });

  let statusInfo = {
    hasError: true,
    errorText: "",
    data: null,
  };
  if (res && res.status == 200 && res.data && res.data.success) {
    statusInfo.hasError = false;
    statusInfo.data = res.data;
  } else {
    statusInfo.errorText = res.data?.message || res.data;
  }

  return statusInfo;
}

export async function getCryptoInUSD(cryptoId) {
  let statusInfo = {
    hasError: true,
    errorText: "",
    data: null,
  };

  try {
    let res = await axios.get("router/get-price", {
      params: { cryptoId: cryptoId },
    });
    if (res && res.status == 200 && res.data) {
      statusInfo.data = res.data.price;
      statusInfo.hasError = false;
    } else {
      statusInfo.errorText = res.data?.message || res.data;
    }
  } catch (error) {
    statusInfo.errorText = error;
  }
  return statusInfo;
}

export async function getPiceByCryptoSymbol(symbol) {
  let statusInfo = {
    hasError: true,
    errorText: "",
    data: null,
  };

  try {
    let res = await axios.get("router/get-price-by-symbol", {
      params: { symbol: symbol },
    });
    if (res && res.status == 200 && res.data) {
      statusInfo.data = res.data.price;
      statusInfo.hasError = false;
    } else {
      statusInfo.errorText = res.data?.message || res.data;
    }
  } catch (error) {
    statusInfo.errorText = error;
  }
  return statusInfo;
}

export async function getStatusOfBridges() {
  let statusInfo = {
    hasError: true,
    errorText: "",
    data: null,
  };

  try {
    let res = await axios.get("router/get-items");
    if (res && res.status == 200 && res.data) {
      statusInfo.data = res.data;
      statusInfo.hasError = false;
    } else {
      statusInfo.errorText = res.data?.message || res.data;
    }
  } catch (error) {
    statusInfo.errorText = error;
  }
  return statusInfo;
}

export async function getDataByUrl(url) {
  let statusInfo = {
    hasError: true,
    errorText: "",
    data: null,
  };

  try {
    let res = await axios.get(url);
    if (res && res.status == 200 && res.data) {
      statusInfo.data = res.data;
      statusInfo.hasError = false;
    } else {
      statusInfo.errorText = res.data?.message || res.data;
    }
  } catch (error) {
    statusInfo.errorText = error.response ? error.message : error;
  }
  return statusInfo;
}

export const formatAmountToUint = (amount, cryptoDecimals) => {
  return ethers.utils.parseUnits(amount.toString(), cryptoDecimals);
};

export const historyStatus = () => {
  return [STATUS_IN_PROGRESS, STATUS_FINISHED, STATUS_REJECTED];
};

export const pausedStatus = () => {
  return [STATUS_APPROVED];
};
export const isAmountEmpty = (amount) => {
  return amount == null || amount == "";
};
export const isAmountLess = (amount) => {
  if (!CHECK_MIN_AMOUNT) {
    return false;
  }
  return !isAmountEmpty(amount) && amount < MIN_USD_AMOUNT;
};

export function isHex(num) {
  return Boolean(num.match(/^0x[0-9a-f]+$/i));
}

export function numberToHex(value) {
  return utils.hexlify(BigNumber.from(value));
}

export const serviceImages = [
  {
    key: "lifi",
    img: "/images/routes/lifi.svg",
  },
  {
    key: "symbiosis",
    img: "/images/routes/symbyosis.svg",
  },
  {
    key: "multichain",
    img: "/images/routes/multichain.svg",
  },
];

export const bridgesData = [
  {
    key: DEBRIDGE,
    img: "/images/routes/debridge.svg",
    label: "Debridge",
  },
];

export const routesData = [
  {
    key: LIFI,
    img: "/images/routes/lifi.svg",
    label:'LI.FI',
    showOnTop:true,
  },
  {
    key: CHAINSPOT,
    img: "/images/routes/chainspot.svg",
    label:'Chainspot',
    showOnTop:true,
  },
  {
    key: XYFINANCE,
    img: "/images/routes/xyfinance.svg",
    label:'XYFinance',
    showOnTop:true,
  },
  {
    key: DESWAP,
    img: "/images/routes/debridge.svg",
    label:'Deswap',
    showOnTop:false,
  },
];

export function getRouteImage(imageKey) {
  const item = routesData.find((v) => v.key == imageKey);
  return item ? item.img : "";
}

export function getServiceImage(serviceKey) {
  const item = serviceImages.find((v) => v.key == serviceKey);
  return item ? item.img : "";
}

export function convertTransactionTime(list) {
  list.map((v) => {
    v.time = moment.parseZone(v.createdAt).format("DD MMM, HH:mm");
    let crypto = v.cryptoFrom;

    v.amountFromFormated = parseFloat(
      crypto.amount / Math.pow(10, crypto.decimals)
    ).toFixed(2);
    v.amountFromUSD = crypto.amountInUsd;

    crypto = v.cryptoTo;
    v.amountToFormated = parseFloat(
      crypto.amount / Math.pow(10, crypto.decimals)
    ).toFixed(2);
    v.amountToUSD = crypto.amountInUsd;
    switch (v.status) {
      case STATUS_APPROVED:
        v.sClass = "";
        v.iconClass = "pending-label";
        v.showStatus = "Approved";
        break;
      case STATUS_FINISHED:
        v.sClass = "history__item_completed";
        v.iconClass = "completed-label";
        v.showStatus = "Completed";
        break;
      case STATUS_REJECTED:
        v.sClass = "history__item_rejected";
        v.iconClass = "cancelled-label";
        v.showStatus = "Rejected";
        break;
      case STATUS_IN_PROGRESS:
        v.sClass = "history__item_pending";
        v.iconClass = "pending-label";
        v.showStatus = "In Progress";
        break;
      default:
        break;
    }

    v.formatedHash = convertTransactionHash(v.hash);
    return v;
  });
  return list;
}

export const convertTransactionHash = (hash) => {
  return hash ? hash.substring(0, 7) : "";
};

export const copyToBuffer = (text) => {
  navigator.clipboard.writeText(text);
};
