import { getNetworkImageByChainId, getTokenImg } from "../../utils/NetworkList";

export default function TransactionSubmitPopup(props) {
  return (
    <div
      className={`is-submited-popup popup ${props.show ? "active" : ""}`}
      id="isSubmitedPopup"
    >
      <div className="popup__container">
        <a className="popup__close" onClick={() => props.closePopup()} />
        <img
          className="is-submited-popup__success-icon"
          src="/images/success-icon.svg"
          alt=""
        />
        <h2 className="popup__title">Transaction is submited</h2>
        <span className="is-submited-popup__subtitle">Transaction 1 of 2</span>
        <div className="is-submited-popup__transaction-path transaction-path">
          <div className="transaction-path__item transaction-path__item_left">
            <div className="transaction-path__item-container">
              {props.data.cryptoFrom && (
                <>
                  <div className="transaction-path__item-logo">
                    <img
                      className="transaction-path__item-logo-main"
                      src={getTokenImg(props.data.cryptoFrom)}
                      alt=""
                    />
                    <img
                      className="transaction-path__item-logo-network"
                      src={getNetworkImageByChainId(
                        props.data.routeFrom.chainId
                      )}
                      alt=""
                    />
                  </div>
                  <h3 className="transaction-path__item-title">{`${props.data.amountFrom} ${props.data.cryptoFrom.name}`}</h3>
                  <span className="transaction-path__item-subtitle">
                    {props.data.routeFrom.name}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="transaction-path__item transaction-path__item_right">
            <div className="transaction-path__item-container">
              {props.data.cryptoTo && (
                <>
                  <div className="transaction-path__item-logo">
                    <img
                      className="transaction-path__item-logo-main"
                      src={getTokenImg(props.data.cryptoTo)}
                      alt=""
                    />
                    <img
                      className="transaction-path__item-logo-network"
                      src={getNetworkImageByChainId(props.data.routeTo.chainId)}
                      alt=""
                    />
                  </div>
                  <h3 className="transaction-path__item-title">{`${props.data.amountTo} ${props.data.cryptoTo.name}`}</h3>
                  <span className="transaction-path__item-subtitle">
                    {props.data.routeTo.name}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
