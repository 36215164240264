import { useDispatch, useSelector } from "react-redux";
import { showDisclaimerDialogCall } from "../../features/walletService/walletService";

export default function SwapDisclaimerNew() {
  const isDisclaimerPopupShow = useSelector(
    (state) => state.walletServiceProvider.isDisclaimerPopupShow
  );
  const dispatch = useDispatch();

  const closePopup = () => {
    dispatch(showDisclaimerDialogCall(false));
  };
  if (!isDisclaimerPopupShow) {
    return;
  }
  return (
    <div className="disclaimer-popup popup active">
      <div className="popup__container">
        <h3 className="popup__title">Terms & Disclaimer agreement</h3>
        <p className="disclaimer-popup__subtitle">
          By using Chainspot router, I agree to the{" "}
          <a className="text-link" href="">
            Terms & Disclaimer
          </a>
          .
        </p>
        <div className="disclaimer-popup__cnt">
          <div className="disclaimer-popup__content">
            <ul>
              <li>
                I hereby acknowledge that I am not a person or entity who
                resides, is a citizen of, is incorporated in, or has a
                registered office in the United States of America or any
                Prohibited Locations as defined in the Terms of Use. I will not
                access or use the Chainspot Router (App) in the future while in
                the United States and/or Prohibited Locations as defined in the
                Terms of Use.
              </li>
              <li>
                I have legal permission to access this site and use the
                Chainspot Router (App) under the laws of the jurisdiction in
                which I reside and am located. I do not and will not use VPNs to
                mask my physical location from prohibited areas.
              </li>
              <li>
                Chainspot Router is an App that operates and executes
                cross-chain swaps through third-party blockchain bridges, DEXes
                and Routers. As a high-level solution, it doesn't have its own
                liquidity and can’t take responsibility for third-party
                services' stability and security. Please use it at your own
                risk. I understand the potential risks that may arise when using
                Chainspot Router (App) network protocols.
              </li>
            </ul>
          </div>
        </div>
        <button
          className="disclaimer-popup__btn btn btn_primary"
          onClick={() => closePopup()}
        >
          I agree
        </button>
      </div>
    </div>
  );
}
