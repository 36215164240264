import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { Address } from "everscale-inpage-provider";
import { EverWalletWebProvider } from "../../services/everWallet/everWalletWebProvider";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import { TronLinkWebProvider } from "../../services/tronLink/TronLinkWebProvider";
import { localStorageObj } from "../localstorage";
import { TrustWalletWebProvider } from "../../services/trustWallet/TrustWalletWebProvider";
import { ymWalletClick } from "../../utils/BrowserUtil";
import {
  CONNECTED_PROVIDERS,
  PROVIDER_EVERWALLET,
  PROVIDER_METAMASK,
  PROVIDER_TRON,
  PROVIDER_TRUST_BROWSER,
  PROVIDER_VENOM,
} from "../../constants/ProviderTypes";
import { getWalletType } from "../../utils/WalletUtil";
import { getNetworkByChainIdAndWallet, getNetworks } from "../../utils/NetworkList";
import notificationPopupSlice from "../dialogs/notificationPopupSlice";
import { ALERT_WARNING } from "../../constants/AlertTypes";


export const getWalletAmount = async (walletInfo, formData) => {
  let balance = 0;
  try {
    switch (walletInfo.providerType) {
      case PROVIDER_METAMASK:
        balance = await MetamaskWebProvider.getBalance(
          formData.cryptoFrom,
          walletInfo.accountAddress
        );
        break;
      case PROVIDER_TRUST_BROWSER:
        balance = await TrustWalletWebProvider.getBalance(
          formData.cryptoFrom,
          walletInfo.accountAddress
        );
        break;
      case PROVIDER_EVERWALLET:
        balance = await EverWalletWebProvider.getBalance(
          new Address(walletInfo.accountAddress)
        );
        break;
    }
  } catch (error) {}
  return balance;
};

export const approveWalletTransaction = async (
  amount,
  walletInfo,
  formData,
  transactionData
) => {
  let proccessResponce = {
    isApproved: false,
    networkResp: null,
  };
  switch (walletInfo.providerType) {
    case PROVIDER_METAMASK:
      proccessResponce = await MetamaskWebProvider.approve(
        amount,
        formData.cryptoFrom.contractAddress,
        walletInfo.accountAddress,
        transactionData.approvalAddress
      );
      break;
    case PROVIDER_EVERWALLET:
      break;
  }
  return proccessResponce;
};

export const sendWalletTransaction = async (
  walletInfo,
  transactionData,
  dispatch
) => {
  let transData = null;
  switch (walletInfo.providerType) {
    case PROVIDER_METAMASK:
      transData = await MetamaskWebProvider.sendTransaction(
        transactionData,
        dispatch
      );
      break;
    case PROVIDER_EVERWALLET:
      break;
  }
  return transData;
};

export const autoConnectWallet = createAsyncThunk(
  "autoconnect/wallet",
  async (__, { dispatch }) => {
    let providerType = localStorageObj.get("providerType");
    let walletInfo = { ...initialState.walletInfo };
    if (providerType == null) {
      return walletInfo;
    }

    switch (providerType) {
      case PROVIDER_METAMASK:
        let isConnected = await window.ethereum._metamask.isUnlocked();
        if (isConnected)
          walletInfo = await MetamaskWebProvider.autoConnect(
            dispatch,
            walletInfo
          );
        break;
      case PROVIDER_EVERWALLET:
        walletInfo = await EverWalletWebProvider.connect(dispatch, walletInfo);
        break;
      case PROVIDER_TRON:
        walletInfo = await TronLinkWebProvider.connect(dispatch, walletInfo);
        break;
      case PROVIDER_TRUST_BROWSER:
        walletInfo = await TrustWalletWebProvider.connect(dispatch, walletInfo);
        break;

      default:
        break;
    }

    walletInfo.providerType = providerType;
    if (walletInfo && walletInfo.isConnected) {
      dispatch(setWallet(walletInfo));
    }
  }
);

export const connectWallet = createAsyncThunk(
  "connect/wallet",
  async (providerType = null, { dispatch, getState }) => {
    dispatch(setConnectingWallet(true));
    if (!providerType) {
      providerType = localStorageObj.get("providerType");
      let isConnected = true;
      if (providerType) {
        switch (providerType) {
          case PROVIDER_METAMASK:
            isConnected = await MetamaskWebProvider.isConnected();
            break;
          case PROVIDER_EVERWALLET:
            isConnected = await EverWalletWebProvider.isConnected();
            break;
          case PROVIDER_TRON:
            isConnected = await TronLinkWebProvider.isConnected();
            break;
          case PROVIDER_TRUST_BROWSER:
            isConnected = await TrustWalletWebProvider.isConnected();
            break;
          default:
            break;
        }
      }
      if (!providerType || !isConnected) {
        localStorageObj.remove("providerType");
        return null;
      }
    }
    const state = getState();
    let walletInfo = {
      accountAddress: null,
      balance: null,
      isConnected: false,
      providerType: providerType,
      transportType: null,
    };
    switch (providerType) {
      case PROVIDER_METAMASK:
        walletInfo = await MetamaskWebProvider.connect(dispatch, walletInfo);
        break;
      case PROVIDER_EVERWALLET:
        walletInfo = await EverWalletWebProvider.connect(dispatch, walletInfo);
        break;
      case PROVIDER_TRON:
        walletInfo = await TronLinkWebProvider.connect(dispatch, walletInfo);
        break;
      case PROVIDER_TRUST_BROWSER:
        walletInfo = await TrustWalletWebProvider.connect(dispatch, walletInfo);
        break;
      default:
        break;
    }
    if (walletInfo && walletInfo.isConnected) {
      localStorageObj.set("providerType", providerType);
      localStorageObj.set(
        "transportType",
        walletInfo.transportType ? walletInfo.transportType : ""
      );
      dispatch(setWallet(walletInfo));
    }
    dispatch(setConnectingWallet(false));
    // return walletInfo;
  }
);

export const getTokenBalance = async (
  provider,
  accountAddress,
  routeFrom,
  cryptoFrom,
  curChainId
) => {
  let balance = 0;
  switch (provider) {
    case PROVIDER_METAMASK:
      balance = await MetamaskWebProvider.getTokenBalanceByContractAddress(
        routeFrom,
        cryptoFrom,
        accountAddress,
        curChainId
      );
      break;
  }
  return balance;
};

export const emptyWalletInfo = {
  networkId: null,
  accountAddress: null,
  balance: null,
  isConnected: false,
  providerType: null,
  networkChainId: null,
  transportType: null,
};

const initialState = {
  walletInfo: { ...emptyWalletInfo },
  allWallets: [],
  showWalletTypePopup: false,
  showAgreementPopup: false,
  agreeBtnCliked: false,
  connectingWallet: false,
  showWalletInfoPopup: false,
  showWalletDisconnectPopup: false,
  showConnectWalletPopup: false,
  isDisclaimerPopupShow: false,
  status: "idle",
  error: null,
  preselectedWalletKey: null,
};

export const walletServiceProvider = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    accountChanged: (state, action) => {
      const newWallets = state.allWallets.map( wallet=> {
        if(wallet.providerType ==  PROVIDER_METAMASK){
          wallet.accountAddress = action.payload
        }
        return wallet;
      })
      state.allWallets = newWallets;
    },
    networkChanged: (state, action) => {
      const walletTypes =  getWalletType(action.payload.networkType);
      const newWallets = state.allWallets.map( wallet=> {
        if(walletTypes.includes(wallet.providerType)){
          const network = getNetworkByChainIdAndWallet(action.payload.networkChainId,wallet.providerType,action.payload.connection);
          wallet.networkChainId = action.payload.networkChainId;
          wallet.networkId = network ? network.id : 0;
        }
        return wallet;
      })
      state.allWallets = newWallets;
      // state.walletInfo.networkChainId = parseInt(action.payload);
    },
    walletTypeDialogToggle: (state) => {
      state.showWalletTypePopup = !state.showWalletTypePopup;
      if (state.showWalletTypePopup) {
        ymWalletClick();
      }
    },
    connectWalletPopupToggle: (state) => {
      state.showConnectWalletPopup = !state.showConnectWalletPopup;
      // if (state.showConnectWalletPopup) {
      //   ymWalletClick();
      // }
    },
    showAgreementDialog: (state, action) => {
      state.showWalletTypePopup = false;
      state.showAgreementPopup = true;
      state.preselectedWalletKey = action.payload;
    },
    showDisclaimerDialogCall: (state, action) => {
      state.isDisclaimerPopupShow = action.payload;
    },
    agreementDialogToggle: (state, action) => {
      state.showAgreementPopup = !state.showAgreementPopup;
      if (action.payload) {
        state.agreeBtnCliked = action.payload;
      }
    },
    changeAgreeBtnClicked: (state, action) => {
      state.agreeBtnCliked = action.payload;
    },
    providerSelected: (state, provider) => {
      state.providerType = provider;
    },
    walletDisconnectDialogToggle: (state) => {
      state.showWalletDisconnectPopup = !state.showWalletDisconnectPopup;
    },
    walletInfoDialogToggle: (state) => {
      state.showWalletInfoPopup = !state.showWalletInfoPopup;
    },
    disconnect: (state, action) => {
      if (state.allWallets.length == 1) {
        localStorageObj.remove(CONNECTED_PROVIDERS);
        state.walletInfo = { ...initialState };
      } else {
        let providers = localStorageObj.get(CONNECTED_PROVIDERS);
        let connected = providers ? JSON.parse(providers) : [];
        let findIndex = connected.findIndex((v) => v == action.payload);
        if (findIndex != -1) {
          connected.splice(findIndex, 1);
          localStorageObj.set(CONNECTED_PROVIDERS, JSON.stringify(connected));
        }
      }
      state.allWallets = state.allWallets.filter(
        (v) => v.providerType != action.payload
      );
      state.walletInfo =
        state.allWallets.length > 0
          ? state.allWallets[0]
          : initialState.walletInfo;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    // setConnectingWallet: (state, action) => {
    //   state.connectingWallet = action.payload;
    // },
    setConnectingWallet: (state, action) => {
      state.connectingWallet = action.payload.status;
      state.connectingWalletkey = action.payload.providerType;
      if (!state.connectingWallet && state.showWalletConnect) {
        state.showWalletConnect = false;
      }
    },
    setWallet: (state, action) => {
      if (action.payload.providerType == PROVIDER_METAMASK) {
        state.walletInfo = { ...action.payload };
      }

      let isHas = state.allWallets.findIndex(
        (v) => v.providerType == action.payload.providerType
      );
      console.log(state.allWallets.length,'state.allWallets.length')
      if(state.allWallets.length == 2 && isHas == -1){
        state.error = 'You have already connected 2 wallets';
        return; 
      }
      storeConnectedWallets(action.payload.providerType);
      if (isHas == -1) {
        let wallets = [...state.allWallets];
        let networkId = action.payload.networkId;
        if(!networkId){
          networkId = getNetworks().find( v=> v.chainId == action.payload.networkChainId)?.id;
        } 
        wallets.push({
          networkId: networkId,
          providerType: action.payload.providerType,
          accountAddress: action.payload.accountAddress,
          networkChainId: action.payload.networkChainId,
          balance: action.payload.balance,
          isConnected: true,
        });
        state.allWallets = wallets;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(connectWallet.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(connectWallet.fulfilled, (state, action) => {
        state.status = "succeeded";
        // if (action.payload) state.walletInfo = action.payload;
        state.showWalletTypePopup = false;
        state.showAgreementPopup = false;
      })
      .addCase(connectWallet.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.showWalletTypePopup = false;
      });
  },
});

const storeConnectedWallets = (providerType) => {
  let text = localStorageObj.get(CONNECTED_PROVIDERS);
  let connectedProviders = text ? JSON.parse(text) : [];
  if (!connectedProviders.some((v) => v == providerType)) {
    connectedProviders.push(providerType);
  }
  localStorageObj.set(CONNECTED_PROVIDERS, JSON.stringify(connectedProviders));
};

// Action creators are generated for each case reducer function
export const {
  accountChanged,
  walletTypeDialogToggle,
  walletInfoDialogToggle,
  walletDisconnectDialogToggle,
  providerSelected,
  disconnect,
  setError,
  networkChanged,
  setWallet,
  setConnectingWallet,
  agreementDialogToggle,
  showAgreementDialog,
  changeAgreeBtnClicked,
  connectWalletPopupToggle,
  showDisclaimerDialogCall
} = walletServiceProvider.actions;

export default walletServiceProvider.reducer;
