import Reports from "../pages/Reports/Reports";
import Faq from "../pages/FAQ/Faq";
import SwapHistory from "../pages/SwapHistory/SwapHistory";
import TransferForm from "../pages/Transfer/TransferForm";
import TestUtil from "../pages/Test/TestUtil";
import { VENOM_TRANSFER_URL } from "../constants/RoutePaths";

const routes = [
  { name: "Home", path: "/", element: TransferForm },
  { name: "Home", path: VENOM_TRANSFER_URL, element: TransferForm },
  { name: "Reports", path: "/reports", element: Reports },
  { name: "Transfer", path: "/transfer", element: TransferForm },
  { name: "Transfer List", path: "/transfer-list", element: SwapHistory },
  { name: "FAQ", path: "/faq", element: Faq },
  { name: "Test", path: "/test", element: TestUtil },
];
export default routes;
