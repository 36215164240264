import React, { useRef } from "react";
import {
  connectWallet,
  connectWalletPopupToggle,
  disconnect,
  showDisclaimerDialogCall,
} from "../../../../features/walletService/walletService";
import { useDispatch, useSelector } from "react-redux";
import { WalletList } from "../../../../constants/WalletList";
import useWagmiConnect from "../../../../hooks/wagmi/useWagmiConnect";
import {
  PROVIDER_METAMASK,
  PROVIDER_VENOM,
  TRANSPORT_WAGMI,
} from "../../../../constants/ProviderTypes";
import { venomConnectInit } from "../../../../features/walletService/venomwalletService";
import { useMouseClickOutside } from "../../../../hooks/common/useMouseClickOutside";
import { isMobileDevice } from "../../../../utils/BrowserUtil";
import { cutAddressFormat } from "../../../../utils/WalletUtil";

export const ConnectWalletPopup = () => {
  const walletServiceList = WalletList;
  let showConnectWalletPopup = useSelector(
    (state) => state.walletServiceProvider.showConnectWalletPopup
  );
  const wrapperRef = useRef(null);

  let dispatch = useDispatch();
  const { connectToWallet, disconnectWallet } = useWagmiConnect();

  const metamaskAppDeepLink =
    "https://metamask.app.link/dapp/" + window.location.host;

  const isMobile = isMobileDevice();

  const onWalletSelect = (walletKey) => {
    let wallet = walletServiceList.find((v) => v.key === walletKey);
    console.log(walletKey,'walletKey')
    if (isMobile && wallet.key == PROVIDER_METAMASK && !window.ethereum) {
      window.open(metamaskAppDeepLink, "_blank");
    } else if (wallet.transport == TRANSPORT_WAGMI) {
      connectToWallet(wallet.connector);
    } else if (wallet.key == PROVIDER_VENOM) {
      dispatch(venomConnectInit());
    } else {
      dispatch(connectWallet(walletKey));
    }
  };

  function disconnectWalletByKey(walletKey) {
    let wallet = walletServiceList.find((v) => v.key === walletKey);
    dispatch(disconnect(walletKey));
    if (wallet.transportType == TRANSPORT_WAGMI) {
      disconnectWallet();
    }
  }

  const onClickOutsideHandle = () => {
    dispatch(connectWalletPopupToggle());
  };

  // useMouseClickOutside(
  //   wrapperRef,
  //   showConnectWalletPopup,
  //   onClickOutsideHandle
  // );

  // if (!showConnectWalletPopup) {
  //   return;
  // }

  return (
    <div
      className={
        "connect-wallets-popup " + (showConnectWalletPopup ? "active" : "")
      }
      ref={wrapperRef}
    >
      <div className="connect-wallets-popup__cnt">
        <button className="connect-wallets-popup__close">
          <img src="/img/close-icon_black.svg" alt="" />
        </button>

        <h3 className="connect-wallets-popup__title">Select wallets</h3>
        <span className="connect-wallets-popup__subtitle">
          By connecting your wallet, you agree to our{" "}
          <a
            className="text-link"
            href="#"
            onClick={() => dispatch(showDisclaimerDialogCall(true))}
          >
            Terms & Disclaimer
          </a>
        </span>

        <div className="connect-wallets-popup__list">
          {walletServiceList.map((walletService) => (
            <ConnectWalletItem
              key={walletService.key}
              walletService={walletService}
              onSelect={onWalletSelect}
              disconnectWallet={disconnectWalletByKey}
            ></ConnectWalletItem>
          ))}
        </div>

        <div className="connect-wallets-popup__info">
          <img src="/images/info-icon_blue.svg" alt="" />
          <span>You can only connect two wallets at a time</span>
        </div>
      </div>
    </div>
  );
};

export const ConnectWalletItem = ({
  walletService,
  onSelect,
  disconnectWallet,
}) => {
  let connectingWallet = useSelector(
    (state) => state.walletServiceProvider.connectingWallet
  );
  let connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );
  const isConnected = connectedWallets.some(
    (connectedWallet) => connectedWallet.providerType == walletService.key
  );

  const walletInfo = connectedWallets.find(
    (connectedWallet) => connectedWallet.providerType == walletService.key
  );

  return (
    <div
      className={
        "connect-wallets-popup__list-item " + (isConnected ? "connected" : "")
      }
    >
      <img
        className="connect-wallets-popup__list-item-icon"
        src={walletService.img}
        alt={walletService.title}
      />
      <div className="connect-wallets-popup__list-item-title">
        <span className="connect-wallets-popup__list-item-name">
          {walletService.title}
        </span>
        {isConnected && (
          <>
            <span className="connect-wallets-popup__list-item-num tooltip-cnt">
              {cutAddressFormat(walletInfo.accountAddress)}
              <span className="tooltip-popup tooltip-popup_num">
                {walletInfo.accountAddress}
              </span>
            </span>
          </>
        )}
      </div>
      {!isConnected && (
        <button
          className="connect-wallets-popup__connect-btn"
          onClick={() => onSelect(walletService.key)}
        >
          Connect wallet
        </button>
      )}
      {isConnected && (
        <>
          <button
            className="connect-wallets-popup__connect-btn connect-wallets-popup__connect-btn_active"
            onClick={() => disconnectWallet(walletService.key)}
          >
            Disconnect
          </button>
        </>
      )}
    </div>
  );
};
