import { ONE_INCH, SYMBIOISES, VENOM_BRIDGE } from "./ServiceConstants";

export const Bridges = [
  {
    name: "Across",
    img: "Across.svg",
  },
  {
    name: "Arbitrum Bridge",
    img: "Arbitrum Bridge.svg",
  },
  {
    name: "Avalanche Bridge",
    img: "Avalanche Bridge.svg",
  },
  {
    name: "Biconomy",
    img: "Biconomy.svg",
  },
  {
    name: "cBridge",
    img: "cBridge.svg",
  },
  {
    name: "Connext",
    img: "Connext.svg",
  },
  {
    name: "Hop",
    img: "Hop.svg",
  },
  {
    name: "Moonbeam",
    img: "Moonbeam-24x24.svg",
  },
  {
    name: "Multichain",
    img: "Multichain.svg",
  },
  {
    name: "Optimism Gateway",
    img: "Optimism Gateway.svg",
  },
  {
    name: "Polygon",
    img: "Polygon.svg",
  },
  {
    name: "Stargate",
    img: "Stargate.svg",
  },
  {
    name: SYMBIOISES,
    img: "Symbiosis.svg",
  },
  {
    name: ONE_INCH,
    img: "1inch.svg",
  },
  {
    name: VENOM_BRIDGE,
    img: "venom-bridge.svg",
  },
];
