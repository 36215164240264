import { Bridges } from "../constants/BridgeList";
import { CHAIN_TYPE_EVM, CHAIN_TYPE_TVM } from "../constants/ChainTypes";
import { networkListDat } from "../constants/NetworkListAll";
import { BSC_CHAIN_ID, IS_TVM_NEED_GAS_PAYMENT, TCPT_TOKEN_SYMBOL, VENOM_CHAIN_ID } from "../constants/TransferConstants";
import { WalletList } from "../constants/WalletList";

export function getNetworkNameByChainId(networkChainId, name) {
  return (
    (networkChainId &&
      networkListDat.find((v) => v.chainId === networkChainId)?.name) ||
    name
  );
}

export function getTxScanUrl(networkChainId, tx) {
  let network = networkListDat.find((v) => v.chainId === networkChainId);
  return network ? network.txScanUrl + tx : null;
}

const networkImageFolder = "/images/network-logos/";

export function getNetworkImage(networkId) {
  const item = networkListDat.find((v) => v.key === networkId + "");
  return item ? networkImageFolder + item.img : "";
}

export function getNetworkImageByChainId(networkChainId) {
  let item = networkListDat.find((v) => v.chainId === networkChainId + "");
  return networkImageFolder + (item ? item.img : "unknown-logo.png");
}

export function getNetworkImageByName(name) {
  let item = networkListDat.find((v) => v.name === name);
  return item ? networkImageFolder + item.img : "";
}

export const getNetworks = () => {
  return networkListDat;
};

export const getNetworkId = (networkChainId, chainType = CHAIN_TYPE_EVM) => {
  const loadedNetwork = getNetworks().find(
    (v) => v.chainType == chainType && v.chainId == networkChainId
  );
  return loadedNetwork ? loadedNetwork.id : null;
};

export function getBridgeImageByName(name) {
  let item = Bridges.find(
    (v) => v.name.toLowerCase() == (name || "").toLowerCase()
  );
  return "/images/bridge-logos/" + (item ? item.img : "defaultBridge.svg");
}

export function getWalletServiceImg(key) {
  let item = WalletList.find((v) => v.key == key);
  return item ? item.imgLarge : "";
}

export function getTokenImg(token) {
  return token && token.logoURI
    ? token.logoURI
    : token.symbol == "USDT"
    ? "/images/tokens-logos/token.png"
    : token.symbol == TCPT_TOKEN_SYMBOL
    ? "/images/tokens-logos/tcpt.svg"
    : "/images/tokens-list-logos/omg.png";
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isTvm(route) {
  return route?.network_type == CHAIN_TYPE_TVM.toLowerCase();
}

export function isVenomNetwork(route) {
  return isTvm(route) && [VENOM_CHAIN_ID].includes(route?.chainId);
}

export const isVenomRoute = (formData) => {
  return (
    !isTvm(formData.routeFrom) &&
    formData.routeFrom?.chainId == BSC_CHAIN_ID &&
    isTvm(formData.routeTo)
  );
};

export const IsTvmApproveRequired = (formData) => {
  return isVenomRoute(formData) && IS_TVM_NEED_GAS_PAYMENT;
};

export function getNetworkByChainIdAndWallet(
  chainId,
  walletType,
  connection = null
) {
  let networks = getNetworks();
  return networks.find(
    (v) =>
      v.wallets.includes(walletType) &&
      v.chainId == chainId &&
      (connection == null || v.connection == connection)
  );
}
