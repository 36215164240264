import React from "react";
import MobileMenu from "./MobileMenu/MobileMenu"
class Header extends React.Component {
  render() {
    return(
      <>
      <MobileMenu/>
      </>
    );
  }
}
export default Header;