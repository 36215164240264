import { useEffect, useRef, useState } from "react";
import {
  MAX_ROUTE_REFRESH_TIME,
  STEP_READY_APPROVE,
} from "../../constants/TransferConstants";

export function useTimer(callbackAfterTimerEnd) {
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (!timeLeft) {
      callbackAfterTimerEnd();
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const startTimer = (time = null) => {
    setTimeLeft(time ? time : MAX_ROUTE_REFRESH_TIME);
  };
  const endTimer = () => {
    setTimeLeft(0);
  };
  return { startTimer, endTimer, timeLeft };
}
