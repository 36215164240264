import { useMemo } from "react";
import { useSelector } from "react-redux";
import { amountErrorText } from "../../constants/TransferConstants";
import { getNetworkImageByChainId, getTokenImg } from "../../utils/NetworkList";
import { isAmountLess } from "../../utils/TransferApiUtil";
import { isWalletConnected } from "../../utils/WalletUtil";

export default function SwapForm({
  formData,
  replaceNetwork,
  fieldChange,
  tokenBalance,
  openTokenSelector,
  setMaxAmount,
  tokenOutBalance,
}) {
  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const isEvmWalletConnected = isWalletConnected(connectedWallets);

  const notificationState = useSelector(
    (state) => state.notificationPopupManager
  );

  const onlyNumber = (e) => {
    if(e.key == '.' && formData.amountFrom && formData.amountFrom.includes('.')){
      e.preventDefault();
    }
    if (!/[0-9]|\./.test(e.key)) {
      e.preventDefault();
    }
  };

  const isBadAmount = useMemo(() => {
    if (
      notificationState.show &&
      notificationState.caption.startsWith(amountErrorText)
    ) {
      return true;
    }
    if (!isEvmWalletConnected) return false;

    if (formData.amountFrom == null) return false;

    if (isAmountLess(formData.amountFromUSD)) return true;

    return parseFloat(formData.amountFrom) > parseFloat(tokenBalance);
  }, [
    notificationState,
    isEvmWalletConnected,
    formData.amountFrom,
    formData.amountFromUSD,
    tokenBalance,
    amountErrorText,
  ]);

  return (
    <div className="transfers-form__main-inputs">
      <button
        className="transfers-form__change-fields-button"
        onClick={replaceNetwork}
      >
        <img src="/images/change-fields-icon.svg" alt="" />
      </button>

      <div className="transfers-form__main-input transfers-form__main-input_from">
        <div className="transfers-form__main-input-head">
          <span className="transfers-form__main-input-name">From</span>
        </div>
        <div className="transfers-form__main-input-content">
          <input
            type="text"
            onKeyPress={(e) => onlyNumber(e)}
            placeholder="Enter amount"
            size="1"
            name="amountFrom"
            className={isBadAmount ? "overbalance" : ""}
            value={formData.amountFrom}
            onChange={fieldChange}
          />
          <button
            className="choose-token-button"
            onClick={() => openTokenSelector("From")}
          >
            {formData.cryptoFrom && (
              <>
                <div className="choose-token-button__icons">
                  <img
                    className="choose-token-button__icon choose-token-button__icon_token"
                    src={getTokenImg(formData.cryptoFrom)}
                    alt=""
                  />
                  <img
                    className="choose-token-button__icon choose-token-button__icon_network"
                    src={getNetworkImageByChainId(formData.routeFrom.chainId)}
                    alt=""
                  />
                </div>
                <div className="choose-token-button__value">
                  <span className="choose-token-button__token">
                    {formData.cryptoFrom.symbol}
                  </span>
                  <span className="choose-token-button__network">
                    {formData.routeFrom.name}
                  </span>
                </div>
              </>
            )}
          </button>
        </div>
        <div className="transfers-form__main-input-bottom">
          <span className="transfers-form__equivalent">
            ${formData.amountFromUSD}
          </span>
          <div className="transfers-form__main-input-balance">
            <button
              className="max-button"
              disabled={!isEvmWalletConnected}
              onClick={() => setMaxAmount()}
            >
              MAX
            </button>
            <span className="transfers-form__balance-title">Balance: </span>
            <span className="transfers-form__balance-value">
              {tokenBalance}
            </span>
          </div>
        </div>
        {notificationState.show &&
          notificationState.caption.startsWith(amountErrorText) && (
            <div className="error_block">
              {/* <img src="./images/error-icon.svg" alt="" /> */}
              <span>{notificationState.caption}</span>
            </div>
          )}
      </div>
      <div className="transfers-form__main-input transfers-form__main-input_to">
        <div className="transfers-form__main-input-head">
          <span className="transfers-form__main-input-name">
            To (Estimated)
          </span>
        </div>
        <div className="transfers-form__main-input-content">
          <input
            type="text"
            onKeyPress={(e) => onlyNumber(e)}
            readOnly
            placeholder="You'll receive"
            size="1"
            name="amountTo"
            value={formData.amountTo}
            onChange={fieldChange}
          />
          <button
            className="choose-token-button"
            onClick={() => openTokenSelector("To")}
          >
            {formData.cryptoTo && (
              <>
                <div className="choose-token-button__icons">
                  <img
                    className="choose-token-button__icon choose-token-button__icon_token"
                    src={getTokenImg(formData.cryptoTo)}
                    alt=""
                  />
                  <img
                    className="choose-token-button__icon choose-token-button__icon_network"
                    src={getNetworkImageByChainId(formData.routeTo.chainId)}
                    alt=""
                  />
                </div>
                <div className="choose-token-button__value">
                  <span className="choose-token-button__token">
                    {formData.cryptoTo.symbol}
                  </span>
                  <span className="choose-token-button__network">
                    {formData.routeTo.name}
                  </span>
                </div>
              </>
            )}
          </button>
        </div>
        <div className="transfers-form__main-input-bottom">
          <span className="transfers-form__equivalent">
            ${formData.amountToUSD}
          </span>
          <div className="transfers-form__main-input-balance">
            <span className="transfers-form__balance-title">Balance: </span>
            <span className="transfers-form__balance-value">
              {tokenOutBalance}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
