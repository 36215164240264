import { useEffect, useState } from "react";
import { BSC_CHAIN_ID, STEP_CLAIM_APPROVED, STEP_CLAIM_FINISHED, STEP_CLAIM_INACTIVE, STEP_CLAIM_START, TCPT_TOKEN_SYMBOL } from "../../constants/TransferConstants";
import useGatewayClaim from "../../hooks/walletGateway/useGatewayClaim";
import { ethers } from "ethers";
import { claimTokenEncodeData } from "../../utils/ClaimTokenUtil";
import { CLAIM_CONTRACT_ADDRESS } from "../../constants/CustomContracts";
import { useDispatch, useSelector } from "react-redux";
import { showNotifaction } from "../../features/dialogs/notificationPopupSlice";
import { ALERT_SUCCESS, ALERT_WARNING } from "../../constants/AlertTypes";
import { getWallet } from "../../utils/WalletUtil";
import { getNetworks } from "../../utils/NetworkList";
import { PROVIDER_METAMASK, PROVIDER_TRUST_BROWSER } from "../../constants/ProviderTypes";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import { TrustWalletWebProvider } from "../../services/trustWallet/TrustWalletWebProvider";
import { ClaimAbi } from "../../abis/ClaimAbi";
import { CHANGE_NETWORK_TO_BSC, CLAIM_IN_PROCCESS, META_WALLET_REQUIRED, YOU_ALREADY_CLAIMED } from "../../constants/ClaimMessages";

export default function ClaimToken({ formData, successClaimCallback }) {

  const FEE_AMOUNT_USD = '0.4';
  const dispatch = useDispatch();
  const connectedWallets = useSelector(
    (state) => (state as any).walletServiceProvider.allWallets
  );

  const walletInfo = getWallet(connectedWallets);
  const [claimAmount, setClaimAmount] = useState('10');
  const [isClaimed, setIsClaimed] = useState(false);

  let [claimState, setClaimState] = useState({
    step: STEP_CLAIM_INACTIVE,
    data: '',
    value: '',
    rpcUrl: '',
    to: '',
    from: ''
  });

  const startClaim = async () => {
    if (claimState.step == STEP_CLAIM_INACTIVE) {
      const provider = await getProvider();
      if (!provider) {
        dispatch(
          showNotifaction({ alertType: ALERT_WARNING, caption: META_WALLET_REQUIRED })
        );
        return;
      }
      if (walletInfo.networkChainId != BSC_CHAIN_ID) {
        dispatch(
          showNotifaction({ alertType: ALERT_WARNING, caption: CHANGE_NETWORK_TO_BSC })
        );
        return;
      }
      const isClaimed = await checkIsClaimed();
      if (isClaimed) {
        dispatch(
          showNotifaction({ alertType: ALERT_WARNING, caption: YOU_ALREADY_CLAIMED })
        );
        return;
      }
      let data = await encodeMethod(provider)
      let bnbNetwork = getNetworks().find(v => v.chainId == BSC_CHAIN_ID);
      setClaimState(claim => ({
        ...claim,
        ...{
          step: STEP_CLAIM_START,
          data: data.data,
          value: data.value,
          rpcUrl: (bnbNetwork && bnbNetwork.rpcUrls ? bnbNetwork.rpcUrls[0] : ''),
          to: CLAIM_CONTRACT_ADDRESS,
          from: walletInfo.accountAddress
        }
      }));
    }
  }

  const getProvider = async () => {
    let provider: any = null;
    if (walletInfo.providerType == PROVIDER_METAMASK) {
      provider = await MetamaskWebProvider.getProvider();
    }
    if (walletInfo.providerType == PROVIDER_TRUST_BROWSER) {
      provider = await TrustWalletWebProvider.getProvider();
    }
    if (provider)
      provider = new ethers.providers.Web3Provider(provider);
    return provider
  }

  const encodeMethod = async (provider: any) => {
    const data = await claimTokenEncodeData(provider, CLAIM_CONTRACT_ADDRESS)
    return data;
  }

  useEffect(() => {
    if (walletInfo.accountAddress) {
      checkIsClaimed()
    }
  }, [walletInfo.accountAddress])

  useEffect(() => {
    getClaimAmount();
  }, []);

  const getClaimAmount = async () => {
    let bnbNetwork = getNetworks().find(v => v.chainId == BSC_CHAIN_ID);
    if (bnbNetwork) {
      const Provider2 = new ethers.providers.JsonRpcProvider(bnbNetwork.rpcUrls ? bnbNetwork.rpcUrls[0] : '');
      let contract = await new ethers.Contract(CLAIM_CONTRACT_ADDRESS, ClaimAbi, Provider2);
      const claimAmount = await contract.claimAmount();
      let formatedAmount = ethers.utils.formatUnits(claimAmount, 18).toString();
      setClaimAmount(parseInt(formatedAmount) + '')
    }
  }

  const checkIsClaimed = async () => {
    let bnbNetwork = getNetworks().find(v => v.chainId == BSC_CHAIN_ID);
    if (bnbNetwork && walletInfo.accountAddress) {
      const Provider2 = new ethers.providers.JsonRpcProvider(bnbNetwork.rpcUrls ? bnbNetwork.rpcUrls[0] : '');
      let contract = await new ethers.Contract(CLAIM_CONTRACT_ADDRESS, ClaimAbi, Provider2);
      const isCLaimed = await contract.getClaimerData(walletInfo.accountAddress);
      setIsClaimed(isCLaimed);
      return isCLaimed;
    }
    return false;
  }

  const transferApproveCallback = async (transferState) => {
    if (transferState.isApproved) {

      setClaimState(claim => ({
        ...claim,
        ...{ step: STEP_CLAIM_APPROVED }
      }));
      dispatch(
        showNotifaction({ alertType: ALERT_SUCCESS, caption: 'Claim processing by network' })
      );
      setIsClaimed(true);

      const Provider2 = new ethers.providers.JsonRpcProvider(claimState.rpcUrl);
      let mint = await Provider2.waitForTransaction(transferState.transaction);
      dispatch(
        showNotifaction({ alertType: ALERT_SUCCESS, caption: 'Claim finished' })
      );
      setClaimState(claim => ({
        ...claim,
        ...{ step: STEP_CLAIM_FINISHED }
      }));
      setTimeout(() => {
        setClaimState(claim => ({
          ...claim,
          ...{ step: STEP_CLAIM_INACTIVE }
        }));

      }, 3000);
      setTimeout(() => {
        successClaimCallback();
      }, 6000);
    } else {
      if (transferState.error == null) {
        dispatch(
          showNotifaction({ alertType: ALERT_WARNING, caption: 'Claim rejected' })
        );
      }

      setClaimState(claim => ({
        ...claim,
        ...{ step: STEP_CLAIM_INACTIVE }
      }));
    }
  };

  useGatewayClaim(claimState, transferApproveCallback);

  // if (!formData.routeFrom || formData.routeFrom.chainId != 56) {
  //   return;
  // }
  const isDisaibled = isClaimed || claimState.step != STEP_CLAIM_INACTIVE || !walletInfo.accountAddress || walletInfo.networkChainId != BSC_CHAIN_ID;

  let warningText = ''
  if (isDisaibled) {
    if (!walletInfo.accountAddress) {
      warningText = META_WALLET_REQUIRED;
    } else if (claimState.step != STEP_CLAIM_INACTIVE) {
      warningText = CLAIM_IN_PROCCESS;
    } else if (isClaimed) {
      warningText = YOU_ALREADY_CLAIMED;
    } else if (walletInfo.accountAddress && walletInfo.networkChainId != BSC_CHAIN_ID) {
      warningText = CHANGE_NETWORK_TO_BSC;
    }
  }
  return (
    <div className="claim-widget">
      <button className="claim-widget__info tooltip-cnt">
        <img src="/images/info-icon_blue.svg" alt="" />
        <div className="tooltip-popup">
          <ol>
            <li>Your web3 wallet should be connected to BNB Chain.</li>
            <li>{TCPT_TOKEN_SYMBOL} tokens are issued only for Venom testnet activities and have NO ANY MONETARY VALUE.</li>
          </ol>
        </div>
      </button>

      {claimState.step == STEP_CLAIM_FINISHED &&
        <img className="claim-widget__success-icon" src="/images/claim-success-icon.png" alt="" />}
      <span>
        Claim {claimAmount} {TCPT_TOKEN_SYMBOL} tokens in BSC to transfer to {" "}<img src="/images/venom-icon.svg" alt="" />&nbsp;Venom Network {" "}
      </span>
      <button className="claim-widget__btn claim-btn tooltip-cnt"
        onClick={() => startClaim()} disabled={isDisaibled}>
        <img src="/images/claim-btn-icon.svg" alt="" />
        Claim
        {isDisaibled &&
          <div className="tooltip-popup">
            {warningText}
          </div>}
      </button>
    </div>
  );
}
