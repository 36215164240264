import { Contract, ethers, utils } from "ethers";
import { ALERT_WARNING } from "../../constants/AlertTypes";
import { showNotifaction } from "../../features/dialogs/notificationPopupSlice";
import {
  isZeroAddress,
  truncateDecimals,
} from "../../features/walletService/utils";
import { SIGN_KEY } from "../metamask/MetamaskWebProvider";
import { signMeta } from "../metamask/MetaFunctions";
import ERC20Abi from "../../utils/ERC20Abi";
import { getBalanceByJsonRpc2 } from "../../features/walletService/balanceService";

const ERROR_TRUST_WALLET_IS_NOT_INSTALLED = "Trust wallet is not installed";
const USER_REJECTED_CHANGE_CHAIN = "User rejected switching chains.";
export const TrustWalletWebProvider = {
  getTrustWalletInjectedProvider: async function (
    { timeout } = { timeout: 3000 }
  ) {
    const provider = this.getTrustWalletFromWindow();

    if (provider) {
      return provider;
    }

    return this.listenForTrustWalletInitialized({ timeout });
  },
  listenForTrustWalletInitialized: async function (
    { timeout } = { timeout: 3000 }
  ) {
    return new Promise((resolve) => {
      const handleInitialization = () => {
        resolve(this.getTrustWalletFromWindow());
      };

      window.addEventListener("trustwallet#initialized", handleInitialization, {
        once: true,
      });

      setTimeout(() => {
        window.removeEventListener(
          "trustwallet#initialized",
          handleInitialization,
          { once: true }
        );
        resolve(null);
      }, timeout);
    });
  },
  getTrustWalletFromWindow: function () {
    const isTrustWallet = (ethereum) => {
      // Identify if Trust Wallet injected provider is present.
      const trustWallet = !!ethereum.isTrust;

      return trustWallet;
    };

    const injectedProviderExist =
      typeof window !== "undefined" && typeof window.ethereum !== "undefined";

    // No injected providers exist.
    if (!injectedProviderExist) {
      return null;
    }

    // Trust Wallet was injected into window.ethereum.
    if (isTrustWallet(window.ethereum)) {
      return window.ethereum;
    }

    // Trust Wallet provider might be replaced by another
    // injected provider, check the providers array.
    if (window.ethereum?.providers) {
      // ethereum.providers array is a non-standard way to
      // preserve multiple injected providers. Eventually, EIP-5749
      // will become a living standard and we will have to update this.
      return window.ethereum.providers.find(isTrustWallet) ?? null;
    }

    // Trust Wallet injected provider is available in the global scope.
    // There are cases that some cases injected providers can replace window.ethereum
    // without updating the ethereum.providers array. To prevent issues where
    // the TW connector does not recognize the provider when TW extension is installed,
    // we begin our checks by relying on TW's global object.
    return window["trustwallet"] ?? null;
  },
  autoConnect: async function (dispatch, walletInfo) {
    return this.connect(dispatch, walletInfo);
  },
  isConnected: async function () {
    let provider = await this.getTrustWalletInjectedProvider();
    return provider != null;
  },
  connect: async function (dispatch, walletInfo) {
    const provider = await this.getTrustWalletInjectedProvider();
    if (!provider) {
      dispatch(
        showNotifaction({
          alertType: ALERT_WARNING,
          caption: ERROR_TRUST_WALLET_IS_NOT_INSTALLED,
        })
      );
      return walletInfo;
    }

    try {
      let accounts = await provider.request({
        method: "eth_accounts",
      })
      if(accounts.length != 1){
        accounts = await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const chainId = await provider.request({ method: "eth_chainId" });

      walletInfo.accountAddress = accounts[0];
      walletInfo.networkChainId = parseInt(chainId);
      walletInfo.isConnected = true;
    } catch (e) {
      let errorMessage =
        e && e?.code == 4001 ? "User denied connection" : e?.message || e;
      dispatch(
        showNotifaction({ alertType: ALERT_WARNING, caption: errorMessage })
      );
    }
    return walletInfo;
  },
  getProvider : async function(){
    return await this.getTrustWalletInjectedProvider();
  },
  getNativeBalance: async function (address) {
    let injectedProvider = await this.getTrustWalletInjectedProvider();
    const provider = new ethers.providers.Web3Provider(injectedProvider);
    let bal = await provider.getBalance(address);
    return parseFloat(ethers.utils.formatEther(bal));
  },
  isMetaSigned: function (accountAddress) {
    let signedAccounts = localStorage.getItem(SIGN_KEY)
      ? JSON.parse(localStorage.getItem(SIGN_KEY))
      : null;
    if (signedAccounts && signedAccounts.includes(accountAddress)) {
      return true;
    }
    return false;
  },
  makeSign: async function (accountAddress) {
    if (this.isMetaSigned(accountAddress)) {
      return true;
    }
    let signedAccounts = [];
    let provider = await this.getTrustWalletInjectedProvider();
    let isSigned = await signMeta(accountAddress, provider);
    if (isSigned) {
      signedAccounts.push(accountAddress);
      localStorage.setItem(SIGN_KEY, JSON.stringify(signedAccounts));
    }
    return isSigned;
  },
  approve: async function (
    amount,
    contractAddress,
    accountAddress,
    approvalAddress
  ) {
    let proccessResponce = {
      isApproved: false,
      networkResp: null,
    };
    let isSigned = await this.makeSign(accountAddress);
    if (!isSigned) {
      return proccessResponce;
    }
    if (isZeroAddress(contractAddress)) {
      proccessResponce.isApproved = true;
      return proccessResponce;
    }

    // const provider = new ethers.providers.Web3Provider(
    //   await this.getTrustWalletInjectedProvider()
    // );
    const injectedProvider = await this.getTrustWalletInjectedProvider();
    // const ethersProvider = new ethers.providers.Web3Provider(injectedProvider);
    const provider = new ethers.providers.Web3Provider(injectedProvider);
    const signer = provider.getSigner();
    let contract = await new ethers.Contract(contractAddress, ERC20Abi,signer);

    try {
      const allowance = await contract.allowance(
        accountAddress,
        approvalAddress
      );

      if (allowance.lt(amount)) {
        const res = await contract.approve(approvalAddress, amount);
        proccessResponce.isApproved = true;
        proccessResponce.networkResp = res;
      } else {
        proccessResponce.isApproved = true;
      }
    } catch (error) {
      console.error(error, "error");
      proccessResponce.isApproved = false;
    }
    return proccessResponce;
  },
  getBalance: async function (token, accountAddress) {
    let balance = isZeroAddress(token.contractAddress)
      ? this.getNativeBalance(accountAddress)
      : this.getTokenBalance(token, accountAddress);
    return truncateDecimals(balance);
  },
  getTokenBalance: async function (token, accountAddress) {
    let injectedProvider = await this.getTrustWalletInjectedProvider();
    if (injectedProvider) {
      const provider = new ethers.providers.Web3Provider(injectedProvider);
      const contract = new Contract(token.contractAddress, ERC20Abi, provider);
      const balance = await contract.balanceOf(accountAddress);
      return utils.formatUnits(balance, token.decimals);
    } else {
      return 0;
    }
  },
  getTokenBalanceByContractAddress: async function (
    routeFrom,
    cryptoFrom,
    accountAddress,
    chainId
  ) {
    let balanceInfo = {
      hasError: false,
      error: null,
      balance: 0,
    };
    try {
      balanceInfo = await getBalanceByJsonRpc2(
        routeFrom.rpcUrls[0],
        cryptoFrom.contractAddress,
        cryptoFrom.decimals,
        accountAddress
      );
    } catch (error) {
      console.error(error, "Balance Error");
    }
    if (balanceInfo.hasError && chainId == routeFrom.chainId) {
      try {
        balanceInfo.hasError = false;
        balanceInfo.balance = await this.getBalance(
          cryptoFrom,
          accountAddress,
          routeFrom
        );
      } catch (error) {
        balanceInfo.hasError = true;
        balanceInfo.error = error;
      }
    }
    return balanceInfo;
  },
  addChain: async function (selectedNetwork) {
    let provider = await this.getTrustWalletInjectedProvider();
    let addInfo = {
      isSuccess: false,
      error: null,
    };
    try {
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [selectedNetwork],
      });
      addInfo.isSuccess = true;
    } catch (error) {
      addInfo.error = error?.message || error;
    }
    return addInfo;
  },
  changeChain: async function (network) {
    let provider = await this.getTrustWalletInjectedProvider();
    let changeInfo = {
      isSuccess: false,
      error: null,
    };
    let crypto = network.cryptos.find((v) => isZeroAddress(v.contractAddress));
    if (!crypto) {
      crypto = network.cryptos.find((v) => v.contractAddress === null);
    }
    const selectedNetwork = {
      chainId: ethers.utils.hexValue(parseInt(network.chainId)),
      chainName: network.name,
      nativeCurrency: {
        name: crypto.name,
        decimals: crypto.decimals,
        symbol: crypto.symbol,
      },
      rpcUrls: network.rpcUrls,
    };
    const chainId = await provider.request({ method: "eth_chainId" });

    if (parseInt(chainId) !== selectedNetwork.chainId) {
      try {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: selectedNetwork.chainId }],
          // params: [{ chainId: utils.toHex(chainId) }],
        });
        changeInfo.isSuccess = true;
      } catch (err) {
        if (err.code == 4902) {
          changeInfo = await this.addChain(selectedNetwork);
        } else {
          changeInfo.error = this.processChainError(err);
        }
      }
    } else {
      changeInfo.isSuccess = true;
    }
    return changeInfo;
  },
  processChainError: function (error) {
    let errMessage;
    // if (error.code && error.code == -32002) {
    //   errMessage = CHAIN_CHANGE_ALREADY_PROCCESS_ERROR;
    if (error.code && error.code === 4001) {
      errMessage = USER_REJECTED_CHANGE_CHAIN;
    } else {
      errMessage = error.message || error;
    }
    return errMessage;
  },
  getGasPrice: async function () {
    const injectedProvider = await this.getTrustWalletInjectedProvider();
    if (injectedProvider) {
      const provider = new ethers.providers.Web3Provider(injectedProvider);
      const price = await provider.getGasPrice();
      return price;
    } else {
      return "0";
    }
  },
  calcGas: async function () {
    const injectedProvider = await this.getTrustWalletInjectedProvider();
    const provider = new ethers.providers.Web3Provider(injectedProvider);
    const price = await provider.getGasPrice();
    const str = ethers.utils.formatEther(price);
    const eth = str * 2;
    const estimation = ethers.utils.parseEther(eth.toFixed(18));
    return estimation._hex;
  },
  sendTransaction: async function (
    senderAddress,
    reciverAddress,
    value,
    transactionData
  ) {
    let result = {
      isApproved: false,
      txHash: null,
      errorMessage: null,
    };

    // const provider = await this.getTrustWalletInjectedProvider();
    const injectedProvider = await this.getTrustWalletInjectedProvider();
    if(!injectedProvider){
      result.errorMessage = 'Trust wallet not found';
      return result;
    }

    const gasPrice = await this.calcGas();

    // const provider = new ethers.providers.Web3Provider(injectedProvider);
    // const trustWallet = await getTrustWalletInjectedProvider();

    const transactionParameters = {
      // nonce: "0x00", // ignored by MetaMask
      gasPrice: gasPrice, //transactionData.gasPrice ? transactionData.gasPrice : '0x09184e72a000', // customizable by user during MetaMask confirmation.
      gas: null, // ?  transactionData.gasLimit : '0x5208', // customizable by user during MetaMask confirmation.
      to: reciverAddress, //transactionData.to, // Required except during contract publications.
      from: senderAddress, //this.provider.selectedAddress, // must match user's active address.
      value: value, //transactionData.value, // Only required to send ether to the recipient from the initiating external account.
      data: transactionData, //transactionData.data, // Optional, but used for defining smart contract creation and interaction.
      // chainId: transactionData.chainId, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
    };
    try {
      let txHash = await injectedProvider.send({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
      result.isApproved = true;
      result.transaction = txHash;
    } catch (error) {
      result.errorMessage = error?.message || error;
    }
    return result;
  },
};
