import { useState } from "react";

export default function FaqItem({ item }) {
  const [isShow, toggleShow] = useState(false);

  return (
    <div
      className={`faq__item  ${isShow ? "active" : ""}`}
      onClick={() => toggleShow(!isShow)}
    >
      <div className="faq__item-header">
        <button className="faq__item-close"></button>
        <h2 className="faq__item-title">{item.question}</h2>
      </div>
      <p
        className="faq__item-content"
        dangerouslySetInnerHTML={{ __html: item.answer }}
      ></p>
    </div>
  );
}
