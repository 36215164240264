import { ethers, utils } from "ethers";
import { Contract, Provider } from "ethers-multicall";
import ERC20Abi from "../../utils/ERC20Abi";
import { cutNumber, isZeroAddress, truncateDecimals } from "./utils";
import { Contract as Contract2 } from "ethers";

const MAX_CHUNK_LIMIT = 100;
export const getWalletTokenAmounts = async (network, tokens, walletAddress) => {
  // const provider = new ethers.providers.Web3Provider(window.ethereum)
  const provider = new ethers.providers.JsonRpcProvider(network.rpcUrls[0]);
  const ethcallProvider = new Provider(provider);
  await ethcallProvider.init(); // Only required when `chainId` is not provided in the `Provider` constructor
  let methodsList = [];

  let chunks = [];
  let num = 0;

  let tokenlist = [...tokens];
  tokenlist.forEach((token, index) => {
    let tokenBalanceCall;
    if (isZeroAddress(token.contractAddress)) {
      tokenBalanceCall = ethcallProvider.getEthBalance(walletAddress);
    } else {
      const contract = new Contract(token.contractAddress, ERC20Abi);
      tokenBalanceCall = contract.balanceOf(walletAddress);
    }
    if (tokenBalanceCall) methodsList.push(tokenBalanceCall);
    num++;
    if (num == MAX_CHUNK_LIMIT) {
      chunks.push(methodsList);
      methodsList = [];
      num = 0;
      return;
    }
  });

  if (methodsList.length > 0) chunks.push(methodsList);

  let networkBalance = {
    walletAddress: walletAddress,
    networkChainId: network.chainId,
    tokens: [],
  };

  for (let index = 0; index < chunks.length; index++) {
    try {
      let res = await ethcallProvider.all(chunks[index]);
      if (res) {
        res.forEach((balance, sub_index) => {
          let formatedBalance = parseFloat(
            utils.formatUnits(
              balance,
              tokenlist[index * MAX_CHUNK_LIMIT + sub_index].decimals
            )
          );
          let tokenCopy = { ...tokens[index * MAX_CHUNK_LIMIT + sub_index] };
          tokenCopy.balance = formatedBalance.toFixed(
            formatedBalance > 1 ? 2 : 4
          );

          // if (formatedBalance != 0)
          tokenCopy.formatedBalance =
            formatedBalance != 0
              ? formatedBalance.toFixed(formatedBalance > 1 ? 2 : 4)
              : null;

          tokenCopy.isBalanceLoaded = true;

          tokenlist[index * MAX_CHUNK_LIMIT + sub_index] = tokenCopy;
          networkBalance.tokens.push({
            contractAddress: tokenCopy.contractAddress,
            balance: formatedBalance.toFixed(formatedBalance > 1 ? 2 : 4),
          });
        });
      }
    } catch (error) {
      for (let num = 0; num < chunks[index].length; num++) {
        let objInfo = {...tokenlist[index * MAX_CHUNK_LIMIT + num]};
        objInfo.isBalanceLoaded = true; 
        tokenlist[index * MAX_CHUNK_LIMIT + num] = objInfo;
        networkBalance.tokens.push({
          contractAddress: objInfo.contractAddress,
          balance: 0,
          isBalanceLoaded: true,
        });
      }
    }
    // const element = array[index]
  }
  return networkBalance;
};

export const getBalanceByJsonRpc = async (
  networkRpc,
  tokenContractAddress,
  tokenDecimals,
  walletAddress
) => {
  // const provider = new ethers.providers.Web3Provider(window.ethereum)
  const provider = new ethers.providers.JsonRpcProvider(networkRpc);
  const ethcallProvider = new Provider(provider);
  await ethcallProvider.init(); // Only required when `chainId` is not provided in the `Provider` constructor

  let tokenBalanceCall;
  if (isZeroAddress(tokenContractAddress)) {
    tokenBalanceCall = ethcallProvider.getEthBalance(walletAddress);
  } else {
    const contract = new Contract(tokenContractAddress, ERC20Abi);
    tokenBalanceCall = contract.balanceOf(walletAddress);
  }

  let result = {
    hasError: false,
    error: null,
    balance: 0,
  };
  try {
    let res = await ethcallProvider.all([tokenBalanceCall]);
    if (res) {
      let balance = res[0];
      balance = parseFloat(utils.formatUnits(balance, tokenDecimals));
      balance = truncateDecimals(balance); //.toFixed(4);
      result.balance = balance;
    }
  } catch (error) {
    result.hasError = true;
    result.error = error;
    console.error(error, tokenContractAddress, "error");
  }
  return result;
};

export const getBalanceByJsonRpc2 = async (
  networkRpc,
  tokenContractAddress,
  tokenDecimals,
  walletAddress
) => {
  // const provider = new ethers.providers.Web3Provider(window.ethereum)
  const provider = new ethers.providers.JsonRpcProvider(networkRpc);
  let tokenBalanceCall;
  if (isZeroAddress(tokenContractAddress)) {
    tokenBalanceCall = provider.getBalance(walletAddress);
  } else {
    const contract = new Contract2(tokenContractAddress, ERC20Abi, provider);
    tokenBalanceCall = contract.balanceOf(walletAddress);
  }

  let result = {
    hasError: false,
    error: null,
    balance: 0,
  };
  try {
    let balance = await tokenBalanceCall;
    balance = parseFloat(utils.formatUnits(balance, tokenDecimals));
    balance = truncateDecimals(balance); //.toFixed(4);
    result.balance = balance;
  } catch (error) {
    result.hasError = true;
    result.error = error;
    console.error(error, tokenContractAddress, "error");
  }
  return result;
};
