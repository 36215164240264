import { useState, useEffect, useRef } from "react";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import { BALANCE_STATE_GETED, BALANCE_STATE_START } from "../walletGateway/useGatewayBalance";
import { PROVIDER_METAMASK } from "../../constants/ProviderTypes";

function useMetasmaskBalance(balanceState, setBalanceState) {
  useEffect(() => {
    const getMetaBalance = async () => {
      const balance =
        await MetamaskWebProvider.getTokenBalanceByContractAddress(
          balanceState.network,
          balanceState.crypto,
          balanceState.accountAddress,
          balanceState.curChainId
        );
      setBalanceState((prevState) => ({
        ...prevState,
        error: balance.hasError ? balance.error : null,
        balance: balance.hasError ? 0 : balance.balance,
        state: BALANCE_STATE_GETED,
      }));
    };
    if (balanceState.providerType == PROVIDER_METAMASK && balanceState.state == BALANCE_STATE_START) {
      getMetaBalance();
    }
  }, [balanceState, setBalanceState]);
}

export default useMetasmaskBalance;
