export const PROVIDER_METAMASK = "metamask";
export const PROVIDER_EVERWALLET = "everWallet";
export const PROVIDER_TRON = "tronWallet";
export const PROVIDER_WALLET_CONNECT = "walletConnect";
export const PROVIDER_METAMASK_WAGMI = "metamaskWagmi";
export const PROVIDER_TRUST_BROWSER = "trustBrowserwallet";
export const PROVIDER_VENOM = "venom";


export const TRANSPORT_WAGMI = "wagmi";
export const CONNECTED_PROVIDERS = "chainsspotConnectedProviders";
