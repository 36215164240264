import { useEffect } from "react";
import {
  STEP_IN_TRANSFER_APPROVED,
  STEP_READY_APPROVE,
} from "../../constants/TransferConstants";
import { useTimer } from "../common/useTimer";
import { checkVenomTransaction, isVenomTransferFinished } from "../venom/VenomUtil";
import { VENOM_STATUS_EV_REJECTED } from "../../constants/VenomStatus";

export function useVenomEventUpdater(
  transactionStep,
  proccess,
  formData,
  tvmWallet,
  venomProvider,
  venomTransferCallback
) {
  useEffect(() => {
    if (transactionStep != STEP_IN_TRANSFER_APPROVED) {
      endTimer();
    }
    if (transactionStep == STEP_IN_TRANSFER_APPROVED) {
      startTimer(1);
    }
  }, [transactionStep]);

  const callback = async () => {
    if (transactionStep == STEP_IN_TRANSFER_APPROVED) {
      let data = await checkVenomTransaction(
        formData.routeFrom.chainId,
        proccess.transfetTx,
        tvmWallet.accountAddress,
        venomProvider
      );
      if(!isVenomTransferFinished(data.status)){
        startTimer(3);
      }else{
        venomTransferCallback(data);
      }
    }
  };
  const { startTimer, endTimer, timeLeft } = useTimer(callback);

  return { startTimer, endTimer, timeLeft };
}
