import React from "react";
import { useState } from "react";
import { TELEGRAM_CHAT } from "../../../constants/SocialLinks";
import { IsAsterizmPage } from "../../../utils/RouteUtils";
export function TelegramWidget() {
  const [isOpen, setOpenToggle] = useState(!IsAsterizmPage());

  const openTelegramGroup = () =>{
    window.open(TELEGRAM_CHAT,'_blank')
  }
  return (
    <div
      className="telegram-widget"
      style={{ display: isOpen ? "flex" : "none" }}
    >
      <div className="telegram-widget__icon">
        <img src="/images/telegram-icon-large.svg" alt="" />
      </div>
      <button
        className="telegram-widget__close"
        onClick={() => setOpenToggle(false)}
      ></button>
      <span className="telegram-widget__title">
        Join our Telegram Community
      </span>
      <button className="telegram-widget__btn" onClick={() => openTelegramGroup()}>Join</button>
    </div>
  );
}
