import { useState, useEffect, useRef } from "react";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import {
  FEE_STATE_GETED,
  FEE_STATE_START,
} from "../walletGateway/useGatewayCalcFee";
import { PROVIDER_METAMASK } from "../../constants/ProviderTypes";

function useMetasmaskCalcFee(feeState, setFeeState) {
  useEffect(() => {
    const calcMetaFee = async () => {
      let gasPrice = await MetamaskWebProvider.getGasPrice();
      setFeeState((prevState) => ({
        ...prevState,
        gasPrice: gasPrice,
        state: FEE_STATE_GETED,
      }));
    };
    if (
      feeState.providerType == PROVIDER_METAMASK &&
      feeState.state == FEE_STATE_START
    ) {
      calcMetaFee();
    }
  }, [feeState, setFeeState]);
}

export default useMetasmaskCalcFee;
