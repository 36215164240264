import { STATUS_APPROVED } from "../../constants/TransferConstants";
import {
  getNetworkImageByChainId,
  getTxScanUrl,
} from "../../utils/NetworkList";

export default function HistoryTransferItem({ transfer }) {
  return (
    <div className={`history__item ${transfer.sClass}`}>
      {transfer.status == STATUS_APPROVED && (
        <span className="history__item-alert">
          Transaction is not signed
          <img src="/images/alert-icon_yellow.svg" alt="" />
        </span>
      )}
      <div className="history__item-header">
        <span>{transfer.time}</span>
        {transfer.hash && (
          <span className="history__item-id">
            <span>
              Trx ID:
              <a
                href={getTxScanUrl(transfer.networkFrom.chainId, transfer.hash)}
                target="_blank"
              >
                {transfer.formatedHash}
              </a>
            </span>
          </span>
        )}
      </div>
      <div className="history__item-content">
        <div className="token-cnt">
          <div className="token-cnt__icon">
            <img
              className="token-cnt__icon-token"
              src={transfer.cryptoFrom.logoUrl}
              alt=""
            />
            <img
              className="token-cnt__icon-network"
              src={getNetworkImageByChainId(transfer.networkFrom.chainId)}
              alt=""
            />
          </div>
          <div className="token-cnt__info">
            <span className="token-cnt__value">
              {transfer.amountFromFormated} {transfer.cryptoFrom.symbol}
            </span>
            <div className="token-cnt__caption">
              <span>on {transfer.networkFrom.title}</span>
              <span>${transfer.amountFromUSD}</span>
            </div>
          </div>
        </div>
        <img
          className="history__item-transfer-icon"
          src="/images/transfer-arrow_history.svg"
          width="20"
          height="8"
          alt=""
        />
        <div className="token-cnt">
          <div className="token-cnt__icon">
            <img
              className="token-cnt__icon-token"
              src={transfer.cryptoTo.logoUrl}
              alt=""
            />
            <img
              className="token-cnt__icon-network"
              src={getNetworkImageByChainId(transfer.networkTo.chainId)}
              alt=""
            />
          </div>
          <div className="token-cnt__info">
            <span className="token-cnt__value">
              {transfer.amountToFormated} {transfer.cryptoTo.symbol}
            </span>
            <div className="token-cnt__caption">
              <span>on {transfer.networkTo.title}</span>
              <span>${transfer.amountToUSD}</span>
            </div>
          </div>
        </div>
      </div>
      {transfer.status != STATUS_APPROVED && (
        <div className={"history__label tooltip-cnt " + transfer.iconClass}>
          <div className="tooltip-popup">
            <span>{transfer.showStatus}</span>
          </div>
        </div>
      )}
    </div>
  );
}
