import React from "react";
import FaqList from "../../components/Faq/FaqList";
import SwapMenu from "../../components/SwapMenu/SwapMenu";
import { TELEGRAM_CHAT, TWITTER_LINK } from "../../constants/SocialLinks";
import { loadPrices } from "../../hooks/venom/VenomUtil";

export default function TestUtil() {
  const load = () =>{
    loadPrices();
  }
  return (
    <button onClick={() =>load()}>Load</button>
  );
}
