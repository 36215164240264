import { useState, useEffect, useRef } from "react";
import {
  APPROVAL_STATE_FINISHED,
  APPROVAL_STATE_STARTED,
} from "../walletGateway/useGatewayApprove";
import { TrustWalletWebProvider } from "../../services/trustWallet/TrustWalletWebProvider";
import { PROVIDER_TRUST_BROWSER } from "../../constants/ProviderTypes";

function useTrustAllowance(approvalState, setApprovalState) {
  useEffect(() => {
    if (
      approvalState.state === APPROVAL_STATE_STARTED &&
      approvalState.providerType === PROVIDER_TRUST_BROWSER
    ) {
      async function getAllowance() {
        const proccessResponce = await TrustWalletWebProvider.approve(
          approvalState.amount,
          approvalState.tokenContractAddress,
          approvalState.accountAddress,
          approvalState.approvalAddress
        );

        setApprovalState((prevState) => ({
          ...prevState,
          isApproved: proccessResponce.isApproved,
          transaction: proccessResponce.networkResp,
          state: APPROVAL_STATE_FINISHED,
        }));
      }
      getAllowance();
    }
  }, [approvalState, setApprovalState]);
}

export default useTrustAllowance;
