import { ProviderRpcClient } from "everscale-inpage-provider";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VenomConnect } from "venom-connect";
// import {
//   setVenomProvider,
//   venomConnected,
// } from "../features/walletService/venomwalletService";
// import { setConnectingWallet, setWallet } from "../features/walletService/walletService";
// import { PROVIDER_VENOM } from "../constants/ProviderTypes";
// import { toggleInstallingPopup } from "../features/walletService/walletService";
import {
  VENOM_DEFAULT_NETWORK,
  VenomWalletWebProvider,
} from "../../services/venom/venomWalletWebProvider";
import { venomConnected } from "../../features/walletService/venomwalletService";
import { PROVIDER_VENOM } from "../../constants/ProviderTypes";
import {
  CONNECTED_PROVIDERS,
  setConnectingWallet,
  setWallet,
} from "../../features/walletService/walletService";
import { localStorageObj } from "../../features/localstorage";
import { hasOldConnectionWallet } from "../../utils/WalletUtil";

export function useVenomAccount() {
  const [venomConnect, setVenomConnect] = useState();
  const [venomProvider, setProvider] = useState();

  const makeConnectRequested = useSelector(
    (state) => state.venomWalletManager.makeConnectRequested
  );

  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const wallet =
    connectedWallets.length &&
    connectedWallets.find((v) => v.providerType == PROVIDER_VENOM);

  const currentNetworkId = VENOM_DEFAULT_NETWORK;
  const connectCounter = useRef(0);

  const onInitButtonClick = async () => {
    // let initVenomConnect = ();
    const initedVenomConnect = await VenomWalletWebProvider.getConnectProvider(
      currentNetworkId
    );
    setVenomConnect(initedVenomConnect);
    await checkAuth(initedVenomConnect);
  };

  useEffect(() => {
    setTimeout(() => {
      if (hasOldConnectionWallet(PROVIDER_VENOM)) {
        onInitButtonClick();
      }
    }, 1000);
  }, []);
  useEffect(() => {
    if (makeConnectRequested) {
      onInitButtonClick();
    }
  }, [makeConnectRequested]);

  useEffect(() => {
    if (!wallet) {
      venomProvider?.disconnect();
      connectCounter.current = 0;
    }
  }, [wallet]);

  let dispatch = useDispatch();

  const checkAuth = async (_venomConnect) => {
    try {
      const auth = await _venomConnect?.checkAuth(["venomwallet"]);
      if (auth) {
        let address = await VenomWalletWebProvider.getAddress(_venomConnect);
      } else {
        // dispatch(toggleInstallingPopup(PROVIDER_VENOM));
      }
    } catch (error) {
    }
  };

  const makeLogin = async () => {
    if (venomConnect) {
      let address = await VenomWalletWebProvider.getAddress(venomConnect);
      if (!address) {
        await venomConnect.connect();
      }
    }
  };

  const checkStandalon = async () => {
    if (venomConnect) {
      const standalone = await venomConnect?.getStandalone("venomwallet");
    }
  };
  useEffect(() => {
    const off = venomConnect?.on("connect", onConnect);
    // checkStandalon()
    return () => {
      off?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venomConnect]);

  const onConnect = async (provider) => {
    setProvider(provider);
    const providerState = await provider?.getProviderState?.();
    if (provider) {
      const walletInfo = await VenomWalletWebProvider.getWalletInfo(provider);
      if (walletInfo.accountAddress) {
        dispatch(setWallet(walletInfo));
      }
      if (connectCounter.current == 0 && !walletInfo.accountAddress) {
        connectCounter.current += 1;
        makeLogin();
      }
    }
    dispatch(venomConnected());
    dispatch(
      setConnectingWallet({
        status: false,
        providerType: PROVIDER_VENOM,
      })
    );
  };

  return venomProvider;
}
