import { useEffect, useRef } from "react";
import { BRIDGE_LIST } from "../../constants/Bridges";
import {
  bridgesData,
  getStatusOfBridges,
  routesData,
  transferStart,
} from "../../utils/TransferApiUtil";

export default function RouterInfo() {
  const bridgeList = useRef(BRIDGE_LIST);

  useEffect(() => {
    loadStatusList();
  }, []);

  const loadStatusList = async () => {
    let res = await getStatusOfBridges();
    if (!res.hasError) {
      const serverBridgeList = res.data.bridge;
      bridgeList.current = [
        ...bridgeList.current.map((item) => {
          let serverItem = serverBridgeList.find((v) => v.title == item.name);
          item.isConnnected = true;
          if (serverItem) {
            item.isConnnected = serverItem.isActive;
          }
          return item;
        }),
      ];
    } else {
      console.error(res.errorText);
    }
  };

  const routerList = routesData.filter((v) => v.showOnTop);

  return (
    <>
      <div className="transfers__supported">
        <span className="transfers__supported-title">
          Supported:{" "}
          <span className="tooltip-cnt">
            {bridgesData.length + bridgeList.current.length}{" "}
            <div className="tooltip-popup tooltip-popup_white">
              {bridgeList.current.map((bridge) => (
                <div className="tooltip-popup__item" key={bridge.name}>
                  <img src={bridge.img} width="24" height="24" alt="" />
                  <span>{bridge.name}</span>
                  {/* <span className={'dot '+ (bridge.isConnnected ? 'dot-success' : 'dot-warning')}></span> */}
                </div>
              ))}
              {bridgesData.map((bridge) => (
                <div className="tooltip-popup__item" key={bridge.label}>
                  <img src={bridge.img} width="24" height="24" alt="" />
                  <span>{bridge.label}</span>
                  {/* <span className={'dot '+ (bridge.isConnnected ? 'dot-success' : 'dot-warning')}></span> */}
                </div>
              ))}
            </div>
          </span>{" "}
          bridges,{" "}
          <span className="tooltip-cnt">
            19
            <div className="tooltip-popup tooltip-popup_white">
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/ethereum-24x24.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Ethereum</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/Polygon.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Polygon</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/bsc-24x24.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>BSC</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/Arbitrum One-24x24.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Arbitrum One</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/avalanche-avax-logo 1.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Avalanche</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/Celo-24x24.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Fantom</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/telos-24x24.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Telos</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/boba-24x24.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Boba</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/Optimism-24x24.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Optimism</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/harmony-24x24.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Harmony</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/moonriver-24x24.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Moonriver</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/okxchain.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>OKXChain</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/Moonbeam-24x24.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Moonbeam</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/aurora-24x24.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Aurora</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/evmos-24x24.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Evmos</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/fuse-24x24.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Fuse</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/cronos-24x24.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Cronos</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/Celo-24x24-1.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Gnosis</span>
              </div>
              <div className="tooltip-popup__item">
                <img
                  src="/images/networks/Celo-24x24-2.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span>Celo</span>
              </div>
            </div>
          </span>{" "}
          networks and{" "}
          <span className="tooltip-cnt">
            {routerList.length}
            <div
              className="tooltip-popup tooltip-popup_white"
              style={{ width: "fit-content", flexWrap: "nowrap" }}
            >
              {routerList.map((item, index) => (
                <div className="tooltip-popup__item" key={index}>
                  <img src={item.img} width="24" height="24" alt="" />
                  <span>{item.label}</span>
                </div>
              ))}
            </div>
          </span>{" "}
          routers
        </span>
      </div>
    </>
  );
}
