export const isNull = (item: any) => {
  return item === null || item === undefined;
};

export const clearAmount = (n: string) => {
  n = n + '';
  let num = +n;
  if(Math.floor(num) === num){
    return n;
  }
  let bigPart = n.split(".")[0];
  let decimalPart = n.split(".")[1];
  return bigPart+'.' + decimalPart.substring(0,4);
  // return (+n).toFixed(4).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");
};
