const mobileMenuItems = [
  {
    url: "/",
    title: "Finding a bridge",
  },
  {
    url: "/reports",
    title: "Analytics",
  },
  {
    url: "/",
    title: "Networks",
  },
  {
    url: "/",
    title: "Cashback",
    liClass: "main-menu__item_marked",
  },
  {
    url: "/",
    title: "Reports",
    liClass: "main-menu__item_disabled main-menu__item_soon",
  },
  {
    url: "/",
    title: "More",
  },
];

const desctopMenuItems = [
  {
    url: "/",
    title: "Transfers",
    isDisabled: false,
  },
  {
    url: "https://chainspot.io/",
    title: "Bridges",
    isDisabled: false,
    isExternal: true,
  },
  {
    url: "https://gas.chainspot.io/",
    title: "Gas Charger",
    isDisabled: false,
    isExternal: true,
    // liClass: "main-menu__item_soon",
  },
  {
    url: "/",
    title: "Networks",
    isDisabled: true,
    liClass: "main-menu__item_soon",
  },
  {
    url: "/",
    title: "Reports",
    isDisabled: true,
    liClass: "main-menu__item_soon", //main-menu__item_new
  },
];
export function getMenuItems(isMobile = false) {
  return isMobile ? mobileMenuItems : desctopMenuItems;
}
