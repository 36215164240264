import { CHAIN_TYPE_EVM, CHAIN_TYPE_TVM } from "./ChainTypes";
import {
  PROVIDER_EVERWALLET,
  PROVIDER_METAMASK,
  PROVIDER_TRON,
  PROVIDER_TRUST_BROWSER,
  PROVIDER_VENOM,
  PROVIDER_WALLET_CONNECT,
  TRANSPORT_WAGMI,
} from "./ProviderTypes";

export const WalletList = [
  {
    title: "Metamask",
    key: PROVIDER_METAMASK,
    img: "/images/wallets-icons/metamask.png",
    imgForMenu: "/images/wallets-icons/small/metamask.png",
    imgLarge: "/images/metamask-logo-large.png",
    transport: null,
    supportNetworks: [CHAIN_TYPE_EVM],
  },
  {
    title: "Venom",
    key: PROVIDER_VENOM,
    img: "/images/wallets-icons/venom-wallet.png",
    imgForMenu: "/images/wallets-icons/small/venom-wallet.png",
    imgLarge: "/images/venom-wallet.png",
    transport: null,
    supportNetworks: [CHAIN_TYPE_TVM],
  },
  {
    title: "Ever Wallet",
    key: PROVIDER_EVERWALLET,
    img: "/images/wallets-icons/everwallet.png",
    imgForMenu: "/images/wallets-icons/small/ever-wallet.png",
    imgLarge: "",
    transport: null,
    supportNetworks: [CHAIN_TYPE_TVM],
  },
  {
    title: "TronLink",
    key: PROVIDER_TRON,
    img: "/images/wallets-icons/tron-wallet.png",
    imgForMenu: "/images/wallets-icons/small/tron-wallet.png",
    imgLarge: "https://tron.network/static/images/header/logo-red.svg",
    transport: null,
    supportNetworks: [CHAIN_TYPE_TVM],
  },
  {
    title: "WalletConnect",
    key: PROVIDER_WALLET_CONNECT,
    connector: "walletConnect",
    img: "/images/wallets-icons/walletConnect.png",
    imgForMenu: "/images/wallets-icons/small/walletConnect.png",
    imgLarge: "/images/walletConnect.svg",
    transport: TRANSPORT_WAGMI,
    supportNetworks: [CHAIN_TYPE_EVM],
  },
  {
    title: "Trust Wallet",
    key: PROVIDER_TRUST_BROWSER,
    img: "/images/wallets-icons/trust-wallet.png",
    imgForMenu: "/images/wallets-icons/small/trust-wallet.png",
    imgLarge: "/images/trustWallet.png",
    transport: null,
    supportNetworks: [CHAIN_TYPE_EVM],
  },
  // {
  //   title: "Metamask",
  //   key: PROVIDER_METAMASK_WAGMI,
  //   connector: 'metaMask',
  //   img: "./images/metamask-logo.png",
  //   imgLarge: "./images/metamask-logo-large.png",
  //   transport: TRANSPORT_WAGMI,
  // },
];
