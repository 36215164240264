import axios from 'axios'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

export default axios.create({
  // baseURL: makeUrl(), //`https://dbr.debridges.com/api/1.0/`,
  baseURL: 'https://app.chainspot.io/api/1.0/',
  //   crossDomain: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
  },

  //   mode: 'no-cors',
  //   headers: {
  //     'Access-Control-Allow-Origin': '*',
  //     'Content-Type': 'application/json',
  //   },
  //   withCredentials: true,
  //   credentials: 'same-origin',
})

export function makeUrl(){
  return window.location.origin + '/api/1.0/';
}