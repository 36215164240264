import { BigNumber, ethers } from "ethers";
import { MultiVaultAbi } from "../abis/MultiVaultContractAbi";
import { ERC20Abi } from "../abis/ERC20abi";
import {
  addABI,
  decodeLogs,
  keepNonDecodedLogs,
  decodeMethod,
} from "abi-decoder";
import { EthEventConfig } from "../abis/EthEventConfig";
import init, { mapEthBytesIntoTonCell } from "eth-ton-abi-converter";
import { DexPair } from "../abis/DexPair";
import axios from "axios";
import { TokenTransferEthEvent } from "../abis/TokenTransferEthEvent";
import { Address } from "everscale-inpage-provider";
import {
  VENOM_STATUS_EV_CONFIRMED,
  VENOM_STATUS_EV_PENDING,
  VENOM_STATUS_EV_REJECTED,
  VENOM_STATUS_TR_CONFIRMED,
  VENOM_STATUS_TR_PENDING,
  VENOM_STATUS_TR_REJECTED,
} from "../constants/VenomStatus";
/*
Used docs
https://octus-bridge-integration-demo.vercel.app/src/codeSamples/md/EvmToEver/transfers/transferEvmAlienToken.html

https://docs.google.com/document/d/1QXG0vXlboqkxL2hHtAmCnJAK9V4rx9O8oWJVWYMvnw8/edit
*/
export const GAS_LIMIT = 1000000;

export const venomTEvmAlienTokenToTvmData = async (
  walletAddress: string,
  transferAmount: string,
  MultiVaultAddr: string,
  tokenAddress: string,
  tokenDecimals: string,
  everAddress: string,
  payWithGasToken: boolean = true,
  deposit_expected_evers: string = "0",
  deposit_value: string = "0"
) => {
  // const provider = new ethers.providers.Web3Provider(<any>window.ethereum);
  // const signer = provider.getSigner();

  // const MultiVault = new ethers.Contract(
  //   MultiVaultAddr,
  //   MultiVaultAbi.abi,
  //   signer
  // );

  
  const recipient: string[] = [
    everAddress.split(":")[0],
    `0x${everAddress.split(":")[1]}`,
  ];

  // Operational payload
  const deposit_payload: string = "0x";
  // let ERC20Token = new ethers.Contract(tokenAddress, ERC20Abi.abi, signer);

  // const allowance = await ERC20Token.allowance(walletAddress, MultiVaultAddr);

  // if (allowance.lt(transferAmount)) {
  //   // Approving the MultiVault contract
  //   await ERC20Token.approve(
  //     MultiVaultAddr,
  //     ethers.utils.parseUnits(transferAmount, tokenDecimals).toString()
  //   );
  // }

  let iface = new ethers.utils.Interface(
    // [
    //   "function deposit(tuple(int8 wid, uint256 address) recipient,address token,uint256 amount,uint256 expected_evers,bytes payload) payable",
    // ]
    MultiVaultAbi.abi
  );
  let data = iface.encodeFunctionData("deposit", [
    [
      recipient,
      tokenAddress,
      ethers.utils.parseUnits(transferAmount, tokenDecimals).toString(),
      payWithGasToken ? deposit_expected_evers : "0",
      deposit_payload,
    ],
  ]);

  addABI(MultiVaultAbi.abi);
  const decodedData = decodeMethod(data);
  return { data: data, value: payWithGasToken ? deposit_value : "0" };
};

export const venomTEvmAlienTokenToTvm = async (
  walletAddress: string,
  transferAmount: string,
  MultiVaultAddr: string,
  tokenAddress: string,
  tokenDecimals: string,
  everAddress: string,
  payWithGasToken: boolean = true,
  deposit_expected_evers: string = "0",
  deposit_value: string = "0"
) => {
  const provider = new ethers.providers.Web3Provider(<any>window.ethereum);
  const signer = provider.getSigner();

  const MultiVault = new ethers.Contract(
    MultiVaultAddr,
    MultiVaultAbi.abi,
    signer
  );

  const recipient: string[] = [
    everAddress.split(":")[0],
    `0x${everAddress.split(":")[1]}`,
  ];

  // Operational payload
  const deposit_payload: string = "0x";
  let ERC20Token = new ethers.Contract(tokenAddress, ERC20Abi.abi, signer);

  const allowance = await ERC20Token.allowance(walletAddress, MultiVaultAddr);

  if (allowance.lt(transferAmount)) {
    // Approving the MultiVault contract
    await ERC20Token.approve(
      MultiVaultAddr,
      ethers.utils.parseUnits(transferAmount, tokenDecimals).toString()
    );
  }

  const gas = await getVenomGas(
    ethers.utils.parseUnits(transferAmount, tokenDecimals).toString(),
    everAddress
  );
  /**
   * @param recipient {Address} Recipient Everscale address
   * @param token {string} Target Token address
   * @param amount {string} Token amount to transfer
   * @param deposit_expected_evers {string} Event initial balance
   * @param deposit_payload {string} Operational payload
   * @param deposit_value Amount of gas tokens attached to tx
   */
  const res = await MultiVault.deposit(
    [
      recipient,
      tokenAddress,
      ethers.utils.parseUnits(transferAmount, tokenDecimals).toString(),
      payWithGasToken ? deposit_expected_evers : "0",
      deposit_payload,
    ],
    {
      value: payWithGasToken ? deposit_value : "0",
      // gas:gas,
      // gasLimit:  GAS_LIMIT,
    }
  );
};

const getVenomGas = async (amount: string, everAddress: string) => {
  const deployWalletValue = "200000000";
  const venomTokenAddress =
    "0:d9e271496543b3155e936eb2ffb7de23a4b184214d442cf2605a32d53657a765";
  const whiteListCurrencies = [
    "0:2c3a2ff6443af741ce653ae4ef2c85c2d52a9df84944bbe14d702c3131da3f14",
    "0:2c3a2ff6443af741ce653ae4ef2c85c2d52a9df84944bbe14d702c3131da3f14",
    "0:20470e6a6e33aa696263b5702608d69e3317c23bf20c2f921b38d6588c555603",
    "0:f29097815716feb8a759db9a770d6285ae0ce1be55efda141d789815086ab6c7",
    "0:d290ac8872cc91b020cba8a366b949d0e6dd965e2e4d3a28858cc2ce775e7079",
    "0:3c5a9c683da497031b896daf590bed981e2169d1d8f120b7f9536500ebf01d37",
    "0:f911b5916f10d679e5aeb167458c1bc811721a604d83ccc91ab5983e277f4f56",
    "0:7047d15375536ea46d40237e73748af9c6ae976d71a4f21c4080dbc1b6869c94",
    "0:3ce66b6da4e49e99938fae2a892ebc269eb0b6604fdc66d4b0e66effae29da00",
    "0:d0bf01f4611f644434f3895b30ef082ef4724481200f9fb5d3a1d689eb12027c",
    "0:b4ef80b491fe5cbf0dc3201973eea5995ea94faea586053876258f1a1cba43a9",
    "0:47422506e3897f43ab337666c1458d597153eb1137949cef4876c95651f22e45",
    "0:d9e271496543b3155e936eb2ffb7de23a4b184214d442cf2605a32d53657a765",
  ];
  const deep = 3;
  const minTvl = "0.1";
  const slippage = "0.1";
  let params = {
    input: {
      swapAndUnwrapAll: {
        amount: amount,
        destination: everAddress,
        remainingGasTo: everAddress,
        cancelPayload: {
          tokenReceiver: everAddress,
          deployWalletValue: deployWalletValue,
          valueForFinalTransfer: deployWalletValue,
        },
        fromCurrencyAddress: venomTokenAddress,
        whiteListCurrencies: whiteListCurrencies,
        deep: deep,
        minTvl: minTvl,
        successPayload: {
          tokenReceiver: everAddress,
        },
        slippage: slippage,
      },
    },
  };
  const { data } = await axios.post(
    "https://testnetapi.web3.world/v2/middleware",
    params,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data ? data?.output?.swapAndUnwrapAll?.everAmount : null;
};

export const venomTEvmGasTokenToTvm = async (
  transferAmount: string,
  MultiVaultAddr: string,
  everAddress: string,
  payWithGasToken: boolean = true,
  deposit_expected_evers: string = "0",
  deposit_value: string = "0"
) => {
  const provider = new ethers.providers.Web3Provider(<any>window.ethereum);
  const signer = provider.getSigner();

  const MultiVault = new ethers.Contract(
    MultiVaultAddr,
    MultiVaultAbi.abi,
    signer
  );

  const recipient: string[] = [
    everAddress.split(":")[0],
    `0x${everAddress.split(":")[1]}`,
  ];

  // Operational payload
  const deposit_payload: string = "0x";


  /**
   * @param recipient {Address} Recipient Everscale address
   * @param amount {string} Gas token amount to transfer
   * @param deposit_expected_evers {string} Event initial balance
   * @param deposit_payload {string} Operational payload
   * @param value {BigInt} Amount of gas tokens attached to tx
   */
  const res = await MultiVault.depositByNativeToken(
    [
      recipient,
      ethers.utils.parseEther(transferAmount.toString()),
      payWithGasToken ? deposit_expected_evers : "0",
      deposit_payload,
    ],
    {
      value: BigNumber.from(payWithGasToken ? deposit_value : "0").add(
        ethers.utils.parseEther(transferAmount)
      ),
      gasLimit: GAS_LIMIT,
    }
  );
};

export const runTransferUpdater = async (
  txHash: string,
  venomProvider: any,
  provider: any,
  ethereumConfiguration: string,
  everscaleTokenAddress: string | undefined = undefined
) => {
  const txReceipt = await provider.getTransactionReceipt(txHash);

  let transferState: ITransferState = {
    confirmedBlocksCount: 0,
    eventBlocksToConfirm: 0,
    status: VENOM_STATUS_TR_PENDING,
    eventAddress: "",
  };
  const networkBlockNumber = await provider.getBlockNumber();

  if (txReceipt?.blockNumber == null || networkBlockNumber == null) {
    return transferState;
  }

  transferState.confirmedBlocksCount =
    networkBlockNumber - txReceipt.blockNumber;

  if (
    transferState.confirmedBlocksCount >= transferState.eventBlocksToConfirm
  ) {
    transferState.status = VENOM_STATUS_TR_CONFIRMED;
    if (!txReceipt.status) {
      transferState.status = VENOM_STATUS_TR_REJECTED;
      return transferState;
    }
    keepNonDecodedLogs();

    addABI(MultiVaultAbi.abi);
    const decodedLogs = decodeLogs(txReceipt?.logs || []);
    const depositLog =
      txReceipt.logs[
        decodedLogs.findIndex(
          (log) => log !== undefined && log.name === "Deposit"
        )
      ];
    const alienTransferLog =
      txReceipt.logs[
        decodedLogs.findIndex(
          (log) => log !== undefined && log.name === "AlienTransfer"
        )
      ];
    const nativeTransferLog =
      txReceipt.logs[
        decodedLogs.findIndex(
          (log) => log !== undefined && log.name === "NativeTransfer"
        )
      ];
    if (depositLog?.data == null || ethereumConfiguration === undefined) {
      return transferState;
    }
    const ethereumEventConfigurationContractState =
      await ethereumEventConfigurationContract(
        venomProvider,
        ethereumConfiguration
      ).getFullState(ethereumConfiguration);

    const [ethConfigDetails, flags] = await Promise.all([
      ethereumEventConfigurationContract(venomProvider, ethereumConfiguration)
        .methods.getDetails({ answerId: 0 })
        .call(),
      (
        await ethereumEventConfigurationContract(
          venomProvider,
          ethereumConfiguration
        )
          .methods.getFlags({ answerId: 0 })
          .call()
          .catch(() => ({ _flags: "0" }))
      )._flags,
    ]);

    const { eventInitialBalance } = ethConfigDetails._basicConfiguration;
    let eventData: string | undefined;
    const eventVoteData: IEvmEventVoteData = {
      eventBlock: txReceipt.blockHash,
      eventBlockNumber: txReceipt.blockNumber.toString(),
      eventTransaction: txReceipt.transactionHash,
    } as IEvmEventVoteData;

    await init();
    if (
      alienTransferLog != null
      // && everscaleTokenAddress !== undefined
    ) {
      eventData = mapEthBytesIntoTonCell(
        Buffer.from(
          ethConfigDetails._basicConfiguration.eventABI,
          "base64"
        ).toString(),
        alienTransferLog.data,
        flags
      );
      eventVoteData.eventIndex = alienTransferLog.logIndex.toString();
    } else if (nativeTransferLog != null) {
      eventData = mapEthBytesIntoTonCell(
        Buffer.from(
          ethConfigDetails._basicConfiguration.eventABI,
          "base64"
        ).toString(),
        nativeTransferLog.data,
        flags
      );
      eventVoteData.eventIndex = nativeTransferLog.logIndex.toString();
    } else {
      eventData = mapEthBytesIntoTonCell(
        Buffer.from(
          ethConfigDetails._basicConfiguration.eventABI,
          "base64"
        ).toString(),
        depositLog.data,
        flags
      );
      eventVoteData.eventIndex = depositLog.logIndex.toString();
    }


    if (eventData == null) {
      return transferState;
    }

    eventVoteData.eventData = eventData;

    const eventAddress = (
      await ethereumEventConfigurationContract(
        venomProvider,
        ethereumConfiguration
      )
        .methods.deriveEventAddress({
          answerId: 0,
          eventVoteData,
        })
        .call()
    ).eventContract;

    let eventState = await runPrepareUpdater(
      venomProvider,
      eventAddress,
      provider,
      txHash,
      venomProvider
    );

    transferState.status = eventState;
    transferState.eventAddress = eventAddress.address;
    return transferState;
    // const decodedLogs = decodeLogs(txReceipt.logs ?? [])
  }
};

const runPrepareUpdater = async (
  provider,
  deriveEventAddress: string,
  evmProvider,
  txHash: string,
  venomProvider
) => {
  const eventContract = new provider.Contract(DexPair, deriveEventAddress);
  const cachedState = await eventContract.getFullState();
  if (cachedState === undefined || !cachedState.state?.isDeployed) {
    try {
      const { blockNumber } = await evmProvider.getTransactionReceipt(txHash);

      const ts = parseInt(
        (await evmProvider.getBlock(blockNumber)).timestamp.toString(),
        10
      );
    } catch (e) {}
  }

  if (cachedState && cachedState.state?.isDeployed) {
    const ethTransferContract = new venomProvider.Contract(
      TokenTransferEthEvent,
      deriveEventAddress
    );

    const eventDetails = await ethTransferContract.methods
      .getDetails({ answerId: 0 })
      .call();

    if (eventDetails._status === "2") {
      return VENOM_STATUS_EV_CONFIRMED;
    } else if (eventDetails._status === "3") {
      return VENOM_STATUS_EV_REJECTED;
    }
  }
  return VENOM_STATUS_EV_PENDING;
};

export const ethereumEventConfigurationContract = (
  provider,
  TokenRootContractAddress
) => {
  const tokenRootContract = new provider.Contract(
    EthEventConfig,
    TokenRootContractAddress
  );
  return tokenRootContract;
};
export interface ITransferState {
  confirmedBlocksCount: number;
  errorMessage?: string;
  eventBlocksToConfirm: number;
  status: number; //TransferStateStatus
  eventAddress: string;
}

export interface IEvmEventVoteData {
  eventBlock: string;
  eventBlockNumber: string;
  eventTransaction: string;
  eventIndex?: string;
  eventData?: string;
}

/*
 * Make vote Data
 */
export const makeAlientNativeVoteData = async (txHash: string) => {
  let abi = new ethers.utils.Interface([
    `event AlienTransfer(
          uint256 base_chainId,
          uint160 base_token,
          string name,
          string symbol,
          uint8 decimals,
          uint128 amount,
          int8 recipient_wid,
          uint256 recipient_addr,
          uint value,
          uint expected_evers,
          bytes payload
      )`,
  ]);

  /**
   * Fetches the transaction receipt from a tx hash to extract the logs and use them to build event vote data.
   * @param txHash {string} The initializer function call transaction hash
   */
  const provider = new ethers.providers.Web3Provider(<any>window.ethereum);
  const txReceipt: any = await provider.getTransactionReceipt(txHash);
  if (!txReceipt) {
    throw new Error("Transaction receipt not found");
  }
  // Fetching the logs from the receipt
  const logs = txReceipt.logs
    .map((log: any) => {
      try {
        let abiArgs = { topics: [log.topics[0]], data: log.data };
        return {
          index: log.logIndex,
          data: log.data,
          parsedLog: abi.parseLog(abiArgs),
        };
      } catch (e) {
        return null;
      }
    })
    .filter((log) => log?.parsedLog !== null) as {
    index: number;
    data: string;
    parsedLog: any;
  }[];

  // Finding the AlienTransfer event from fetched logs
  const log = logs.find((log) => log && log.parsedLog.name === "AlienTransfer");

  // Building the event vote data
  const eventLog = {
    eventTransaction: txReceipt.transactionHash,
    eventIndex: log?.index!,
    eventData: log?.data!,
    eventBlockNumber: txReceipt.blockNumber,
    eventBlock: txReceipt.blockHash,
  };

  return eventLog;
};

export const deployAlientEvent = async (
  everRecepientAddress,
  venomProvider,
  everConfigAddress,
  eventLog
) => {
  const everSender: Address = new Address(everRecepientAddress);

  const provider = new ethers.providers.Web3Provider(<any>window.ethereum);
  /**
   * @param EthereumEverscaleEventConfAbi {JSON} The event config contract ABI
   * @param EthereumEverscaleAlienEventConfigurationAddr {Address} The alien event config contract address.
   */
  const EvmEverEventConf = new venomProvider.Contract(
    EthEventConfig,
    everConfigAddress
  );

  // Fetching the details from config contract to extract the event contract ABI and use it when encoding event data
  const ethConfigDetails = await EvmEverEventConf.methods
    .getDetails({ answerId: 0 })
    .call({});

  // Fetching the flags from the config contract to use when encoding the event data
  const flags = (
    await EvmEverEventConf.methods.getFlags({ answerId: 0 }).call({})
  )._flags;

  // Encoding the event data
  await init(); // initializing the wasm
  const eventData: string = await mapEthBytesIntoTonCell(
    Buffer.from(
      ethConfigDetails._basicConfiguration.eventABI,
      "base64"
    ).toString(),
    eventLog.eventData,
    flags
  );

  // Event vote data interface
  interface EventVoteData {
    eventTransaction: string;
    eventIndex: number;
    eventData: string;
    eventBlockNumber: number;
    eventBlock: string;
  }

  // Preparing the parameters
  const eventVoteData: EventVoteData = {
    eventTransaction: eventLog.eventTransaction,
    eventIndex: eventLog.eventIndex,
    eventData: eventData,
    eventBlockNumber: eventLog.eventBlockNumber,
    eventBlock: eventLog.eventBlock,
  };


  /**
   * @param eventVoteData {EventVoteData} Prepared event vote data
   * @param from {Address} User Everscale address
   * @param amount {string} Event initial value
   * @param bounce {boolean} Should return remained gas ?
   */
  let data = await EvmEverEventConf.methods
    .deployEvent({ eventVoteData: eventVoteData })
    .send({
      from: everSender,
      amount: ethers.utils.parseUnits("6", 9).toString(),
      bounce: true,
    });
  return data;
};

/*
 * get linked tokens
 */
export const getEvmMultiVaultExternalNativeToken = async (
  vaultAddress: string,
  tokenAddress: string
) => {
  type Result = { addr: string; wid: string };
  const provider = new ethers.providers.Web3Provider(<any>window.ethereum);

  const MultiVault = new ethers.Contract(
    vaultAddress,
    MultiVaultAbi.abi,
    provider
  );

  // const tokenRootContract = new provider.con(
  //   MultiVaultAbi,
  //   vaultAddress
  // );

  const result: Result = await MultiVault.natives(tokenAddress);
  // return result !== undefined
  //     ? ${result.wid}:${new BigNumber(result.addr).toString(16).padStart(64, '0')}
  //     : undefined
};
