import { useState, useEffect, useRef } from "react";
import { useContract, useSigner, erc20ABI } from "wagmi";
import { PROVIDER_METAMASK } from "../../features/walletService/walletService";
import { useSelector } from "react-redux";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import {
  STEP_APPROVE_STARTED,
  STEP_TRANSFER_START,
} from "../../constants/TransferConstants";
import useWagmiAlowance from "../wagmi/useWagmiAlowance";
import useMetasmaskAllowance from "../metamask/useMetasmaskAllowance";
import useMetasmaskTransfer from "../metamask/useMetasmaskTransfer";
import { useWagmiTransfer } from "../wagmi/useWagmiTransfer";
import useTrustTransfer from "../trust/useTrustTransfer";
import { getWallet } from "../../utils/WalletUtil";
import { isTvm } from "../../utils/NetworkList";
// import useGatewayVenomTransfer from "../venom/useGatewayVenomTransfer";

export const TRANSFER_STATE_INACTIVE = 0;
export const TRANSFER_STATE_STARTED = 1;
export const TRANSFER_STATE_FINISHED = 2;
export const TRANSFER_STATE_EVM_TVM_STARTED = 3;

const initTransferState = {
  senderAddress: null,
  reciverAddress: null,
  value: null,
  transactionData: null,
  gasLimit: null,
  gasPrice: null,
  providerType: null,
  transportType: null,
  isApproved: false,
  transaction: null,
  wait: null,
  error: null,
  state: TRANSFER_STATE_INACTIVE,
};

function useGatewayTransfer(
  transactionData,
  transactionStep,
  callback,
  formData
) {
  let [transferState, setTransferState] = useState(initTransferState);

  const callbackRef = useRef();
  useEffect(() => {
    callbackRef.current = callback; // Update ref to the latest callback.
  }, [callback]);

  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const walletInfo = getWallet(connectedWallets);

  useEffect(() => {
    if (
      transferState.state === TRANSFER_STATE_FINISHED &&
      transactionStep === STEP_TRANSFER_START
    ) {
      callback({
        isApproved: transferState.isApproved,
        transaction: transferState.transaction,
        wait: transferState.wait,
        error: transferState.error,
      });

      setTransferState((prevState) => ({
        ...prevState,
        state: TRANSFER_STATE_INACTIVE,
      }));
    }
  }, [transferState, transactionStep]);

  useEffect(() => {
    if (
      transactionStep === STEP_TRANSFER_START &&
      transferState.state === TRANSFER_STATE_INACTIVE
    ) {
      setTransferState({
        senderAddress: walletInfo.accountAddress,
        reciverAddress: transactionData.to,
        value: transactionData.value,
        transactionData: transactionData.data,
        gasLimit: transactionData.gasLimit,
        gasPrice: transactionData.gasPrice,
        providerType: walletInfo.providerType,
        transportType: walletInfo.transportType,
        isApproved: false,
        transaction: null,
        wait: null,
        error: null,
        state: TRANSFER_STATE_STARTED,
        formData: formData,
        tvmAddress: null,
      });
    }
  }, [
    transactionData,
    transactionStep,
    walletInfo.accountAddress,
    walletInfo.providerType,
    formData,
  ]);

  useMetasmaskTransfer(transferState, setTransferState);
  useWagmiTransfer(transferState, setTransferState);
  useTrustTransfer(transferState, setTransferState);
  // useGatewayVenomTransfer(transferState, setTransferState);
  return;
}

export default useGatewayTransfer;
