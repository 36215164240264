import { createSlice } from '@reduxjs/toolkit'

export const analyticSlice = createSlice({
  name: 'isMobileFilterShow',
  initialState: {
    value: 0,
  },
  reducers: {
    toggle: (state) => {
      state.value = !state.value
    },
  },
})

// Action creators are generated for each case reducer function
export const { toggle } = analyticSlice.actions

export default analyticSlice.reducer
