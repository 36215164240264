import React, { useEffect, useRef } from "react";
//import "./style.css";
import Layout from "./components/Layout/Layout";
import "./assets/scss/style.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import routes from "./routes/routes";
import { wagmiClient } from "./services/wagmi/config";
import { WagmiConfig } from "wagmi";
import useWagmiAccount from "./hooks/wagmi/useWagmiAccount";
import useMetasmaskAccount from "./hooks/metamask/useMetasmaskAccount";
import useTrustAccount from "./hooks/trust/useTrustAccount";
import { useVenomAccount } from "./hooks/venom/useVenomAccount";

export const VenomContext = React.createContext(null);

function App() {
  useMetasmaskAccount();

  useWagmiAccount();

  useTrustAccount();

  const venomProvider = useVenomAccount();
  return (
    <VenomContext.Provider value={venomProvider}>
      <WagmiConfig client={wagmiClient}>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              {routes.map((route, key) => (
                <Route
                  key={key}
                  path={route.path}
                  element={<route.element />}
                />
              ))}
            </Route>
          </Routes>
        </BrowserRouter>
      </WagmiConfig>
    </VenomContext.Provider>
  );
}
export default App;
