import { useEffect, useRef } from "react";
import {
  accountChanged,
  autoConnectWallet,
  connectWallet,
  disconnect,
  networkChanged,
  setWallet,
} from "../../features/walletService/walletService";
import { useDispatch, useSelector } from "react-redux";
import { localStorageObj } from "../../features/localstorage";
import { PROVIDER_METAMASK } from "../../constants/ProviderTypes";
import {
  getWalletByProviderType,
  hasOldConnectionWallet,
} from "../../utils/WalletUtil";
import { CHAIN_TYPE_EVM } from "../../constants/ChainTypes";

function useMetasmaskAccount() {
  let dispatch = useDispatch();
  // dispatch(connectWallet())
  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const walletInfo = getWalletByProviderType(
    connectedWallets,
    PROVIDER_METAMASK,
    true
  );

  const walletData = useRef();
  useEffect(() => {
    walletData.current = walletInfo;
  }, [walletInfo]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (_chainId) => {
        dispatch(
          networkChanged({
            networkType: CHAIN_TYPE_EVM,
            networkChainId: parseInt(_chainId,16),
          })
        );
      });
      window.ethereum.on("accountsChanged", (accounts) => {
        if (
          hasOldConnectionWallet(PROVIDER_METAMASK) &&
          accounts.length > 0 &&
          !walletInfo.isConnected
        ) {
          dispatch(connectWallet(PROVIDER_METAMASK));
        }
        if (
          walletData.current.providerType == PROVIDER_METAMASK &&
          walletData.current.isConnected
        ) {
          if (accounts.length == 0) {
            dispatch(disconnect());
          }
          if (typeof accounts == "object" && accounts.length > 0) {
            dispatch(accountChanged(accounts[0]));
          }
        }
        if (
          !walletData.current.isConnected &&
          accounts.length > 0 &&
          walletData.current.providerType == PROVIDER_METAMASK
        ) {
          // dispatch(connectWallet(PROVIDER_METAMASK));
        }
      });

      // window.ethereum.on('disconnect', () => {
      //   window.location.reload();
      // })
    }

    setTimeout(() => {
      // const transportType = localStorageObj.get("transportType");
      // const providerType = localStorageObj.get("providerType");
      // if (hasOldConnectionWallet(PROVIDER_METAMASK)) {
      //   // if((transportType === null || transportType === '') && providerType === PROVIDER_METAMASK){
      //   dispatch(autoConnectWallet());
      // }
      isConnected();
    }, 5000);
  }, []);

  window.onload = (event) => {
    console.log(event,'onload')
    isConnected();
  };

  async function isConnected() {
    const accounts = await window.ethereum.request({ method: "eth_accounts" });
    console.log(hasOldConnectionWallet(PROVIDER_METAMASK),accounts.length > 0,!walletInfo?.isConnected,'isConnected')
    if (
      hasOldConnectionWallet(PROVIDER_METAMASK) &&
      accounts.length > 0 &&
      !walletInfo?.isConnected
    ) {
      dispatch(connectWallet(PROVIDER_METAMASK));
    }
  }

  // useEffect(() => {
  // }, []);
}

export default useMetasmaskAccount;
