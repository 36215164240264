import { CHAINSPOT, DEEBRIDGE, LIFI } from "../../constants/ServiceConstants";
import { TWITTER_LINK } from "../../constants/SocialLinks";
import { BTN_KEY_TRANSFER_RETRY } from "../../constants/SwapButtonConstants";
import {
  capitalizeFirstLetter,
  getBridgeImageByName,
  getTxScanUrl,
  isVenomRoute,
} from "../../utils/NetworkList";
import { getRouteImage } from "../../utils/TransferApiUtil";

export default function SwapSuccessLinks({
  formData,
  process,
  btnHandler,
  transactionData,
}) {
  const difAmountUSD = transactionData?.calcFee?.amountInUsd.toFixed(2);
  const router =
    transactionData.router == LIFI ? transactionData.router : CHAINSPOT;

  const isVenomPath = isVenomRoute(formData);
  return (
    <>
      <div className="success-message__value">
        <span className="success-message__value-main">
          {formData.amountTo} {formData.cryptoTo.symbol}{" "}
          <span>on {formData.routeTo.name}</span>
        </span>
        <span className="success-message__value-secondary">
          ≈${formData.amountToUSD}
        </span>
      </div>
      <div className="success-message__links">
        <a
          href={getTxScanUrl(formData.routeFrom.chainId, process.transfetTx)}
          target="_blank"
          rel="noreferrer"
        >
          View transaction
        </a>
        <a href="#" onClick={() => btnHandler(BTN_KEY_TRANSFER_RETRY)}>
          Repeat the swap
        </a>
        <a href={TWITTER_LINK} target={"_blank"}>
          Share experience
        </a>
      </div>
      <div className="success-message__path">
        {/* <div className="success-message__path-item">
          <img
            className="success-message__path-icon"
            src="img/time-icon.svg"
            alt=""
          />
          <span className="success-message__path-caption">14:53</span>
        </div> */}
        <div className="success-message__path-item">
          <img
            className="success-message__path-icon"
            src="/images/gas-icon.svg"
            alt=""
          />
          <span className="success-message__path-caption">
            ${isVenomPath ? transactionData.feeInUsd : difAmountUSD}
          </span>
        </div>
        <div className="success-message__path-item">
          {transactionData.router && (
            <div className="success-message__path-network">
              <picture>
                <img src={getRouteImage(router)} alt="" />
              </picture>
              <span>{capitalizeFirstLetter(router)}</span>
            </div>
          )}
          {transactionData.bridge &&
            getBridgeImageByName(transactionData.bridge) && (
              <div className="success-message__path-network">
                <img
                  src={getBridgeImageByName(transactionData.bridge)}
                  alt=""
                />
                <span>{capitalizeFirstLetter(transactionData.bridge)}</span>
              </div>
            )}
        </div>
      </div>
    </>
  );
}
