import { useState } from "react";
import {
  bridgeList,
  exchangeList,
  priorities,
  slipageTypes,
} from "../../utils/FilterParams";

export default function SwapFilterBlock() {
  const [priority, changePriority] = useState(priorities[0].key);
  const [showAdvanced, toggleFormAdvanced] = useState(false);
  const [slippage, changeSlippage] = useState(slipageTypes[0].key);
  const [bridges, changeBridge] = useState(bridgeList.map((v) => v.key));
  const [exchanges, changeExchange] = useState(exchangeList.map((v) => v.key));

  return (
    <>
      <fieldset className="transfers-form__filters" style={{ display: "none" }}>
        <div className="transfers-form__filters-item">
          <div className="transfers-form__filters-title-block">
            <div className="tooltip" style={{ display: "none" }}>
              <button className="info-button"></button>
              <div className="tooltip-popup tooltip-popup_m">
                <span>
                  <h3 className="tooltip-popup__title">Заголовок</h3>
                  Чисто текстовый тултип размера M и блабла бла блабла блабла
                  бла и блабла бла блабла блабла бла блабла блаблаблаб блабла
                  бла. блаблаблаб Принять Закрыть
                </span>
              </div>
            </div>
            <span className="transfers-form__filters-title">
              Route priority:
            </span>
          </div>
          <div className="transfers-form__filters-container">
            {priorities.map((item) => (
              <label className="filter-radio" key={item.key}>
                <input
                  className="filter-radio__input"
                  type="radio"
                  name="routeFilter"
                  checked={priority === item.key}
                  onChange={() => changePriority(item.key)}
                />
                <span className="filter-radio__button">{item.val}</span>
              </label>
            ))}
          </div>
        </div>
        <div className="transfers-form__filters-item">
          <div className="transfers-form__filters-title-block">
            <div className="tooltip" style={{ display: "none" }}>
              <button className="info-button"></button>
              <div className="tooltip-popup tooltip-popup_m">
                <span>
                  <h3 className="tooltip-popup__title">Заголовок</h3>
                  Чисто текстовый тултип размера M и блабла бла блабла блабла
                  бла и блабла бла блабла блабла бла блабла блаблаблаб блабла
                  бла. блаблаблаб Принять Закрыть
                </span>
              </div>
            </div>
            <span className="transfers-form__filters-title">
              Infinite Approval
            </span>
          </div>
          <div className="transfers-form__filters-container">
            <label className="switch">
              <input className="switch__input" type="checkbox" />
              <span className="switch__block"></span>
            </label>
          </div>
        </div>
        <button
          className={`transfers-form__collapse-button collapse-button " + ${
            showAdvanced ? "active" : ""
          }`}
          onClick={() => toggleFormAdvanced(!showAdvanced)}
          id="advancedSettingsBtn"
        >
          Advanced settings
        </button>
      </fieldset>
      <fieldset
        className={
          "transfers-form__advanced-settings " + (showAdvanced ? "active" : "")
        }
      >
        <div className="transfers-form__filters-item">
          <div className="transfers-form__filters-title-block">
            <div className="tooltip" style={{ display: "none" }}>
              <button className="info-button"></button>
              <div className="tooltip-popup tooltip-popup_m">
                <span>
                  <h3 className="tooltip-popup__title">Заголовок</h3>
                  Чисто текстовый тултип размера M и блабла бла блабла блабла
                  бла и блабла бла блабла блабла бла блабла блаблаблаб блабла
                  бла. блаблаблаб Принять Закрыть
                </span>
              </div>
            </div>
            <span className="transfers-form__filters-title">
              Slippage tolerance:
            </span>
          </div>
          <div className="transfers-form__filters-container transfers-form__filters-container_wrap">
            {slipageTypes.map((item) => (
              <label className="filter-radio" key={item.key}>
                <input
                  className="filter-radio__input"
                  type="radio"
                  name="slippageFilter"
                  checked={slippage === item.key}
                  onChange={() => changeSlippage(item.key)}
                />
                <span className="filter-radio__button">{item.val}</span>
              </label>
            ))}
          </div>
        </div>
        <div className="transfers-form__filters-item">
          <div className="transfers-form__filters-title-block">
            <div className="tooltip" style={{ display: "none" }}>
              <button className="info-button"></button>
              <div className="tooltip-popup tooltip-popup_m">
                <span>
                  <h3 className="tooltip-popup__title">Заголовок</h3>
                  Чисто текстовый тултип размера M и блабла бла блабла блабла
                  бла и блабла бла блабла блабла бла блабла блаблаблаб блабла
                  бла. блаблаблаб Принять Закрыть
                </span>
              </div>
            </div>
            <span className="transfers-form__filters-title">Bridges:</span>
          </div>
          <div className="transfers-form__filters-container transfers-form__filters-container_wrap">
            {bridgeList.map((item) => (
              <label className="filter-checkbox" key={"bridge" + item.key}>
                <input
                  className="filter-checkbox__input"
                  type="checkbox"
                  name="bridgesFilter"
                  value={item.key}
                  defaultChecked={bridges.includes(item.key)}
                  onChange={() =>
                    changeBridge(
                      bridges.includes(item.key)
                        ? bridges.filter((v) => v !== item.key)
                        : [...bridges, item.key]
                    )
                  }
                />
                <span className="filter-checkbox__button">{item.val}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="transfers-form__filters-item">
          <div className="transfers-form__filters-title-block">
            <div className="tooltip" style={{ display: "none" }}>
              <button className="info-button"></button>
              <div className="tooltip-popup tooltip-popup_m">
                <span>
                  <h3 className="tooltip-popup__title">Заголовок</h3>
                  Чисто текстовый тултип размера M и блабла бла блабла блабла
                  бла и блабла бла блабла блабла бла блабла блаблаблаб блабла
                  бла. блаблаблаб Принять Закрыть
                </span>
              </div>
            </div>
            <span className="transfers-form__filters-title">Exchanges:</span>
          </div>
          <div className="transfers-form__filters-container transfers-form__filters-container_wrap">
            {exchangeList.map((item) => (
              <label className="filter-checkbox" key={"exchange" + item.key}>
                <input
                  className="filter-checkbox__input"
                  type="checkbox"
                  name="exchangesFilter"
                  checked={exchanges.includes(item.key)}
                  onChange={() =>
                    changeExchange(
                      exchanges.includes(item.key)
                        ? exchanges.filter((v) => v !== item.key)
                        : [...exchanges, item.key]
                    )
                  }
                />
                <span className="filter-checkbox__button">{item.val}</span>
              </label>
            ))}
          </div>
        </div>
      </fieldset>
    </>
  );
}
