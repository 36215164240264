import React from "react";
import {
  BUG_FORM_LINK,
  MAIL_ADDRESS,
  TELEGRAM_CHAT,
  TWITTER_LINK,
} from "../../../constants/SocialLinks";
import { TelegramWidget } from "./TelegramWidget";
import MenuList from "../MenuList/MenuList";
class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="wrapper">
          <div className="footer__container">
            <TelegramWidget />
            <div className="footer__left">
              <span className="footer__copyrights">
                © {new Date().getFullYear()} Chainspot. All rights reserved
              </span>
              <span className="footer__socials">
                <a
                  className="footer__socials-item"
                  target={"_blank"}
                  href={TELEGRAM_CHAT}
                >
                  <img src="/images/telegram_footer-icon.svg" alt="" />
                  Telegram
                </a>
                <a
                  className="footer__socials-item"
                  target={"_blank"}
                  href={TWITTER_LINK}
                >
                  <img src="/images/twitter_footer-icon.svg" alt="" />
                  Twitter
                </a>
              </span>
            </div>
            <div className="footer__right">
              <img
                className="footer__logo"
                src="/images/chainspot-logo_white.svg"
                alt=""
              />
              <MenuList subClass={'footer__menu'}/>
              <a
                className="report-us"
                href={BUG_FORM_LINK}
                target="_blank"
              >
                <div className="report-us__icon">
                  <img src="/images/whitelist-icon.svg" alt="" />
                </div>
                Report bugs and get $
              </a>

              <span className="footer__email">
                Contact us{" "}
                <a href={"mailto:mail" + MAIL_ADDRESS}>{MAIL_ADDRESS}</a>
              </span>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
