import {
  TYPE_ALOWANCE,
  TYPE_REFUSED,
  TYPE_REJECTED,
  TYPE_TRANSFER,
} from "../../constants/TransactionStepConstants";
import { getTxScanUrl } from "../../utils/NetworkList";

export default function TransactionStepAlert({
  popupInfo,
  formData,
  closePopup,
  process,
}) {
  return (
    <>
      {popupInfo.type === TYPE_ALOWANCE && (
        <div
          className={`alert alert_success ${popupInfo.show ? "active" : ""}`}
        >
          <h3 className="alert__title">Allowance</h3>
          <p className="alert__caption">
            Token {formData.cryptoFrom.symbol} was successfully approved. Now
            you can proceed to transfer.
          </p>
        </div>
      )}
      {popupInfo.type === TYPE_REFUSED && (
        <div className={`alert ${popupInfo.show ? "active" : ""}`}>
          <h3 className="alert__title">Declined</h3>
          <a className="alert__close" onClick={() => closePopup()} />
          <p className="alert__caption">
            You have rejected the token approve. Please try again and give
            permission to spend your token to proceed the transfer.
          </p>
        </div>
      )}
      {popupInfo.type === TYPE_REJECTED && (
        <div className={`alert ${popupInfo.show ? "active" : ""}`}>
          <h3 className="alert__title">Signature required</h3>
          <p className="alert__caption">
            You have rejected the transaction. Please try again.
          </p>
        </div>
      )}
      {popupInfo.type === TYPE_TRANSFER && (
        <div
          className={`alert alert_success ${popupInfo.show ? "active" : ""}`}
        >
          <h3 className="alert__title">Transfer Submitted</h3>
          <p className="alert__caption">
            Please allow 5-20 minutes for the funds to arrive
            <br />
            at your wallet
          </p>
          {process && formData.routeFrom && (
            <a
              className="external-link"
              target={"_blank"}
              href={getTxScanUrl(
                formData.routeFrom.chainId,
                process.transfetTx
              )}
            >
              View on {formData.routeFrom.name}
            </a>
          )}
        </div>
      )}
    </>
  );
}
