import { Address } from "everscale-inpage-provider";
import { VenomContracts } from "../../constants/contracts/VenomContracts";
import { EthereumEverscaleEventConfAbi } from "../../abis/EthereumEverscaleEventConfAbi";
import { useEffect } from "react";
import { EverscaleEthereumBaseEventABI } from "../../abis/EverscaleEthereumBaseEventABI";
import { MultiVaultAbi } from "../../abis/MultiVault";
import { EthEventConfig } from "../../abis/EthEventConfig";
import axios, { Axios, AxiosInstance } from "axios";
import { getNetworks } from "../../utils/NetworkList";
import { deployAlientEvent, getEvmMultiVaultExternalNativeToken, makeAlientNativeVoteData, runTransferUpdater, venomTEvmAlienTokenToTvm, venomTEvmAlienTokenToTvmData, venomTEvmGasTokenToTvm } from "../../utils/VenomTransferMethods";
import { BigNumber as BigNumberEther, ethers } from "ethers";
import BigNumber from "bignumber.js";
import { ERC20Abi } from "../../abis/ERC20abi";
import { proxyCall } from "../../utils/ProxyCall";
import { VENOM_STATUS_EV_CONFIRMED, VENOM_STATUS_EV_REJECTED, VENOM_STATUS_TR_PENDING } from "../../constants/VenomStatus";


export const transferEvmAlienToVenomData = async (
  fromEvmAddress: string,
  amount: string,
  chainId: string,
  tokenContractAdddress: string,
  tokenDecimals: string,
  everAddress: string,
  venomProvider: any,
  isCreditPay = true) => {
  const contract = VenomContracts.multitoken.evm_tvm.vaults.find(vault => vault.chainId == chainId);
  if (!contract) {
    return null;
  }
  const everConfigAddress: string = contract.ethereumConfiguration

  const everSender: Address = new Address(everAddress);

  const EvmEverEventConf = new venomProvider.Contract(
    EthEventConfig,
    everConfigAddress
  );

  const ethConfigDetails = await EvmEverEventConf.methods
    .getDetails({ answerId: 0 })
    .call({});

  const expectedEvers = isCreditPay ? ethConfigDetails._basicConfiguration.eventInitialBalance : '0';

  const value = (isCreditPay) ? await calcVal(expectedEvers, chainId) : '0';
  let data = await venomTEvmAlienTokenToTvmData(fromEvmAddress, amount, contract?.vault || '', tokenContractAdddress, tokenDecimals, everAddress, isCreditPay, expectedEvers, value + '')

  return { data: data.data, value: data.value, receiverAddress: contract.vault };
}

const calcVal = async (expectedEvers: string, chainId: string) => {
  const network = getNetworks().find(v => v.chainId == chainId);
  if (!network) {
    return;
  }
  const rate = await getVenomRate(network.nativeCurrency.symbol);


  let formated = ethers.utils.formatUnits(expectedEvers, 9)


  const expectedEversConvertedToNativEvm = new BigNumber(expectedEvers)
    .shiftedBy(-9)
    .div(rate)
    .times(1.2)
    .times(1e18)
    .dp(0, BigNumber.ROUND_DOWN);

  return expectedEversConvertedToNativEvm.toString();
}

export const loadPrices = async () => {
  console.log('start');
  const createAxios = (baseURL) =>
    axios.create({
      baseURL,
      withCredentials: false,
    });

  const httpClient = createAxios('https://testnetapi.venombridge.com/v1');

  const getCorePrice = (param) => {
    console.log(param,'param')
    return httpClient.post('/gate_prices', param, {
      headers: { 'Access-Control-Allow-Origin': '*' }
    }).then(({ data }) => data);
  };
  let data = await getCorePrice({ ticker: 'BNB' });
  console.log(data, 'data');
};

export const getVenomRate = async (symbol: string) => {
  const { data } = await axios.post('https://testnetapi.venombridge.com/v1/gate_prices', { "ticker": symbol }, {
    headers: {
      'Content-Type': 'application/json',
      // 'sec-fetch-mode': 'cors',
      // 'sec-fetch-site': 'cross-site'
    },
    // crossdomain: true,
  })
  return data && data.price ? data.price : null;
}

export const checkVenomTransaction = async (chainId: string, transactionHash: string, everAddress: string, venomProvider) => {
  const contract = VenomContracts.multitoken.evm_tvm.vaults.find(vault => vault.chainId == chainId);
  let resp = {
    hasError: false,
    errorTxt: '',
    status: VENOM_STATUS_TR_PENDING,
    eventAddress: '',
  }
  if (!contract) {
    resp.hasError = true;
    resp.errorTxt = 'Has not connection';
    return resp;
  }

  const everConfigAddress: string = contract.ethereumConfiguration
  try {
    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    let data = await runTransferUpdater(transactionHash, venomProvider, provider, everConfigAddress)
    resp.status = data?.status || VENOM_STATUS_TR_PENDING;
    resp.eventAddress = data?.eventAddress || '';
  } catch (error) {
    resp.hasError = true;
    resp.errorTxt = (error as any)?.text || error;
  }
  return resp;
}

export const isVenomTransferFinished = (status) => {
  return [VENOM_STATUS_EV_CONFIRMED, VENOM_STATUS_EV_REJECTED].includes(status);
}

export const isVenomConfirmed = (state) => {
  return state == VENOM_STATUS_EV_CONFIRMED;
}

export const makeVenomPay = async (txHash: string, everRecepientAddress: string, chainId: string, venomProvider: any) => {

  let respItem = {
    hasError: false,
    errorText: '',
    data: null
  };
  const contract = VenomContracts.multitoken.evm_tvm.vaults.find(vault => vault.chainId == chainId);
  if (!contract) {
    respItem.hasError = true;
    respItem.errorText = 'Connection to this network not found'
    return respItem;
  }
  try {
    const everConfigAddress: string = contract.ethereumConfiguration
    let eventData = await makeAlientNativeVoteData(txHash);
    respItem.data = await deployAlientEvent(everRecepientAddress, venomProvider,
      everConfigAddress,
      eventData
    )

  } catch (error) {
    console.error(error)
    respItem.hasError = true;
    respItem.errorText = ((error as any)?.message || error)
  }
  return respItem;
}

export const makeBrigeUrl = (txhash:string) =>{
  return 'https://testnet.venombridge.com/transfer/evm-56/tvm-1000/'+txhash+'/default';
}