import { TELEGRAM_CHAT } from "../../constants/SocialLinks";

export default function SwapTelegramLink() {
  return (
    <a className="transfers__telegram-link" href={TELEGRAM_CHAT} target={"_blank"}>
      <img
        src="/images/telegram-icon_small.svg"
        width="14"
        height="12"
        alt=""
      />
      Any questions — contact support
    </a>
  );
}
