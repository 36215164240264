import React from 'react'
import { useDispatch } from 'react-redux';
import BridgeListSelector from '../../components/BridgeListSelector/BridgeListSelector';
import BridgeSelector from '../../components/BridgeSelector/BridgeSelector';
import NetworkDropdown from '../../components/NetworkDropdown/NetworkDropdown';
import PeriodSelector from '../../components/PeriodSelector/PeriodSelector';
import { toggle } from '../../features/analytic/analyticSlice';

function ReportsOverview(props) {
  const dispatch = useDispatch()
    

    const handlePeriodChange = (val) => {
      props.onFilterChange('periodType',val);
    }

    const handleNetworkChanged = (val) => {
      props.onFilterChange('networks',val);
    }

    const handleBridgeChanged = (val) =>{
      props.onFilterChange('bridges',val)
    }
    const formatTvlInUsd = (tvlInUsd) => {
      let amount = parseFloat(tvlInUsd || '0').toFixed(2); 
      return parseFloat(amount).toLocaleString("en-US")
    }

    return          (<div className="overview">
    <div className="overview__header">
      <div className="overview__title-container">
        <h2 className="overview__title secondary-title">Overview</h2>
        <span className="overview__total">100+ Bridges total</span>
        <button className="overview__filter-button filter-button" onClick={()=> dispatch(toggle())}></button>
      </div> 
      <div className="overview__last-update" style={{display:'none'}}>
        <span className="overview__last-update-title">Last update:</span>
        <span className="overview__last-update-date">20.02.2022 at 8:03 p.m.</span>
      </div>           
    </div>
    <div className="overview__filters-panel">
      <NetworkDropdown wrapperClass="overview__filters-panel-item overview__filters-panel-item_no-stretch select" onNetworkSelected={handleNetworkChanged}></NetworkDropdown>
      <div className="overview__filters-panel-item overview__filters-panel-item_no-stretch filter-switch-block">
        <BridgeSelector/>
        <button className="tooltip-btn tooltip-btn_l">?</button>
      </div>  
      {/* <input className="overview__filters-panel-item search-input" placeholder="Search for bridges" type="text"/>    */}
      <BridgeListSelector wrapperClass="overview__filters-panel-item overview__filters-panel-item_no-stretch select" onBridgeSelected={handleBridgeChanged}/>
      <PeriodSelector  wrapperClass="overview__filters-panel-item overview__filters-panel-item_no-stretch select" onPeriodSelected={handlePeriodChange}/>       
    </div>
    <div className="overview__widgets-panel">
      <div className="widget-s widget">
        <div className="widget__icon">
          <img src="./images/total-value-icon.svg" alt=""/>
        </div>
        <div className="widget-s__content">
          <h3 className="widget-s__title">
            Total Value Locked
            <button className="tooltip-btn tooltip-btn_m">?</button>
          </h3>
          <div className="widget__value-block">
            <span className="widget-s__value widget__value">${formatTvlInUsd(props.totalInfo.tvlInUsd)}</span>
            <span className="widget__increment widget__increment_up">{formatTvlInUsd(props.totalInfo.tvlInUsdChange)}%</span>
          </div>
        </div>
      </div>
      <div className="widget-s widget">
        <div className="widget__icon">
          <img src="./images/tvl-change-icon.svg" alt=""/>
        </div>
        <div className="widget-s__content">                
          <h3 className="widget-s__title">
            Change in TVL
            <div className="tooltip">
                    <button className="tooltip-btn tooltip-btn_m">?</button>
                    <div className="tooltip__message-block">
                      <span className="tooltip__title">Header</span>
                      <span className="tooltip__message">
                        A pure text tooltip of size L and blabla blabla blabla blabla blablablab blabla blabla blabla blabla blabla blabla
                        blabla blabla blabla blabla blabla blabla blabla blabla blabla
                      </span>
                    </div>
                  </div>                  
          </h3>                                  
          <div className="widget__value-block">
            <span className="widget-s__value widget__value">{formatTvlInUsd(props.totalInfo.tvlChange)} %</span>
            <span className="widget__increment widget__increment_down">{formatTvlInUsd(props.totalInfo.prevTvlChange)}%</span>
          </div>
        </div>
      </div>
      <div className="widget-s widget disabledBlock">
        <div className="widget__icon">
          <img src="./images/uniq-depositors-icon.svg" alt=""/>
        </div>
        <div className="widget-s__content">
          <h3 className="widget-s__title">
            All bridges unique depositors
            <button className="tooltip-btn tooltip-btn_m">?</button>
          </h3>
          <div className="widget__value-block">
            <span className="widget-s__value widget__value">2,345,567</span>
            <span className="widget__increment widget__increment_up">557</span>
          </div>
        </div>
      </div>
    </div>          
  </div>)  
}
export default ReportsOverview
