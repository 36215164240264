import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useMetasmaskBalance from "../metamask/useMetasmaskBalance";
import useWagmiBalance from "../wagmi/useWagmiBalance";
import useTrustBalance from "../trust/useTrustBalance";
import { getWallet, getWalletByProviderType } from "../../utils/WalletUtil";
import { isTvm, isVenomNetwork } from "../../utils/NetworkList";
import { PROVIDER_VENOM } from "../../constants/ProviderTypes";
import useVenomBalance from "../venom/useVenomBalance";

export const BALANCE_STATE_INACTIVE = 0;
export const BALANCE_STATE_START = 1;
export const BALANCE_STATE_GETED = 2;

const initBalanceState = {
  network: null,
  crypto: null,
  accountAddress: null,
  curChainId: null,
  providerType: null,
  transportType: null,
  state: BALANCE_STATE_INACTIVE,
  error: null,
  balance: null,
};

function useGatewayBalance(network, crypto,venomProvider) {
  const [tokenBalance, setTokenBalance] = useState(0.0);
  const [balanceState, setBalanceState] = useState(initBalanceState);

  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const walletInfo = getWallet(connectedWallets);

  useEffect(() => {
    if (balanceState.state == BALANCE_STATE_GETED) {
      setBalanceState((prevState) => ({
        ...prevState,
        state: BALANCE_STATE_INACTIVE,
      }));

      setTokenBalance(balanceState.balance);
    }
  }, [balanceState]);

  useEffect(() => {
    let balance = 0;
    let isVenomRoute = isVenomNetwork(network);
    let venomWallet = getWalletByProviderType(connectedWallets,PROVIDER_VENOM,true);
    let wallet = isVenomRoute ? venomWallet : walletInfo;
    if(isTvm(network) && !isVenomRoute){
      setTokenBalance(balance);
      return;
    }
    if(isVenomRoute && !venomProvider){
      setTokenBalance(balance);
      return;
    }
    if (!wallet.isConnected || !network || !crypto) {
      setTokenBalance(balance);
      return;
    }
    setBalanceState({
      network: network,
      crypto: crypto,
      accountAddress: wallet.accountAddress,
      curChainId: wallet.networkChainId,
      providerType: wallet.providerType,
      transportType: wallet.transportType,
      state: BALANCE_STATE_START,
      error: null,
      balance: null,
      venomProvider:venomProvider
    });
  }, [network, crypto, connectedWallets,venomProvider]);

  useMetasmaskBalance(balanceState, setBalanceState);
  useWagmiBalance(balanceState, setBalanceState);
  useTrustBalance(balanceState, setBalanceState);
  useVenomBalance(balanceState, setBalanceState);
  return tokenBalance;
}

export default useGatewayBalance;
