import { useEffect } from "react";
import {
  TRANSFER_STATE_FINISHED,
  TRANSFER_STATE_STARTED,
} from "../walletGateway/useGatewayTransfer";
import { fetchFeeData,sendTransaction, prepareSendTransaction,getProvider } from "@wagmi/core";
import { BigNumber, ethers } from "ethers";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";
import { TRANSPORT_WAGMI } from "../../constants/ProviderTypes";

export function useWagmiTransfer(transferState, setTransferState) {
  
  const lengthInUtf8Bytes = (str) => {
    // Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
    var m = encodeURIComponent(str).match(/%[89ABab]/g);
    return str.length + (m ? m.length : 0);
  }

  useEffect(() => {
    if (
      transferState.state === TRANSFER_STATE_STARTED &&
      transferState.transportType === TRANSPORT_WAGMI
    ) {
      let makeTransfer = async () => {
        let transferHash = null;
        let transferWait = null;
        let errorObj = null;
        try {

          const feeData = await fetchFeeData()

          const gasPrice = feeData.gasPrice;
          // const str = ethers.utils.formatEther(price);
          // const eth = str * 2;
          // const estimation = ethers.utils.parseEther(eth.toFixed(18));


          const provider = getProvider();
          const gasPrice2 = await provider.getGasPrice()


          const config = await prepareSendTransaction({
            request: {
              to: transferState.reciverAddress,
              value: transferState.value,
              data: transferState.transactionData,
              // gasPrice: transferState.gasPrice != null ? transferState.gasPrice : gasPrice._hex,
              // gasLimit: transferState.gasLimit != null ? transferState.gasLimit :  BigNumber.from(21000 + 68*lengthInUtf8Bytes(transferState.transactionData))._hex
            },
          });
          // return;
          const { hash, wait } = await sendTransaction(config);
          // const { hash, wait } = await sendTransaction({
          //   mode: 'recklesslyUnprepared',
          //   request: {
          //     to: transferState.reciverAddress,
          //     value: transferState.value,
          //     data: transferState.transactionData,
          //     // gasPrice:gasPrice._hex,
          //     gasLimit: 21000 + 68*lengthInUtf8Bytes(transferState.transactionData)
          //   },
          // });

          transferHash = hash;
          transferWait = wait;
        } catch (error) {
          console.error(error,'error')
          errorObj = error.message && error.message.length < 50 ? error.message :  getParsedEthersError(error);
        }

        setTransferState((prevState) => ({
          ...prevState,
          isApproved: transferHash ? true : false,
          transaction: transferHash,
          wait: transferWait,
          error: errorObj?.context || errorObj,
          state: TRANSFER_STATE_FINISHED,
        }));
      };
      makeTransfer();
    }
  }, [transferState, setTransferState]);
}
