export const VenomContracts = {
    "name": "Broxus Bridge connected assets",
    "multitoken": {
      "evm_tvm": {
        "proxy": "0:65bacdeed9ba8b0bec6cee28b630af2fa4ba90fdf31cbbcee129ebe941f06b09",
        "vaults": [
          {
            "chainId": "1",
            "vault": "0x7dbfdc0a2d18f690d35f38e66dfab795c0175dac",
            "ethereumConfiguration": "0:f3dc8b4b2b6367317cd12757adedce2b43cb714b50da0fc73a7eaffc35f09389",
            "depositType": "default"
          },
          {
            "chainId": "250",
            "vault": "0x7dbfdc0a2d18f690d35f38e66dfab795c0175dac",
            "ethereumConfiguration": "0:910c6297fad35014755505332036dfbf7116b63b56593134162f5fd1a797ac72",
            "depositType": "default"
          },
          {
            "chainId": "56",
            "vault": "0x7dbfdc0a2d18f690d35f38e66dfab795c0175dac",
            "ethereumConfiguration": "0:7038460f07cf1f12b872271e9ca226380dfcd28dd84ed5cbbda6b68ed0e0859e",
            "depositType": "default"
          }
        ]
      },
      "tvm_evm": {
        "proxy": "0:9e74cdef3ae679d57285d580418cdd6fce7617551134191b5d354a22355a7764",
        "vaults": [
          {
            "chainId": "250",
            "vault": "0x7dbfdc0a2d18f690d35f38e66dfab795c0175dac",
            "ethereumConfiguration": "0:cdea79392ed7d357fc33884b065a81a9b7dc78dc5929b04bf63d5ba81df5202e",
            "depositType": "default"
          },
          {
            "chainId": "56",
            "vault": "0x7dbfdc0a2d18f690d35f38e66dfab795c0175dac",
            "ethereumConfiguration": "0:7420e26ec2f54c9df5d3b31d8ef234b661c3a6d2eeaf2abb1601c042224dd506",
            "depositType": "default"
          },
          {
            "chainId": "1",
            "vault": "0x7dbfdc0a2d18f690d35f38e66dfab795c0175dac",
            "ethereumConfiguration": "0:b52d22e83324edb9c5c0ffefe09d3054cce7ce2f379fbe5499245d71ebdab616",
            "depositType": "default"
          }
        ]
      }
    },
    "token": {}
  }