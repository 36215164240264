import { useEffect, useMemo } from "react";
import { CHAINSPOT, DEEBRIDGE, LIFI, VENOM_BRIDGE } from "../../constants/ServiceConstants";
import { isZeroAddress } from "../../features/walletService/utils";
import {
  capitalizeFirstLetter,
  getBridgeImageByName,
  getNetworkNameByChainId,
  getTokenImg,
  isVenomRoute,
} from "../../utils/NetworkList";
import {
  getRouteImage,
  getServiceImage,
  routesData,
} from "../../utils/TransferApiUtil";
import { clearAmount } from "../../utils/CommonUtil";

export default function SwapDetail({ formData, transactionData }) {
  const transferTime = useMemo(() => {
    let time = {
      minute: "00",
      second: "00",
    };
    if (transactionData.estimateSeconds) {
      if (transactionData.estimateSeconds > 60) {
        time.minute = parseInt(transactionData.estimateSeconds / 60)
          .toString()
          .padStart(2, "0");
      }
      time.second = (
        transactionData.estimateSeconds > 60
          ? transactionData.estimateSeconds % 60
          : transactionData.estimateSeconds
      )
        .toString()
        .padStart(2, "0");
      if (time.minute == "00") {
        time.minute = "01";
      }
    }
    return time;
  }, [transactionData.estimateSeconds]);

  const difAmountUSD = transactionData.calcFee.amountInUsd.toFixed(2);
  const nativeToken = formData.routeFrom.cryptos.find((v) =>
    isZeroAddress(v.contractAddress)
  );

  useEffect(() => {
    routeLogosContainerHandler();
  }, []);

  function routeLogosContainerHandler() {
    const containers = document.querySelectorAll(".route-logos");

    if (!containers) {
      return;
    }

    // containers.forEach((el) => {
    //   const routeLogos = el.querySelectorAll(".route-logo");

    //   routeLogos.forEach((el, i) => {
    //     el.style.transform = `translateX(${i * 100}%)`;
    //     el.style.left = `${i * -0.625}vw`;
    //   });

    //   el.style.width =
    //     routeLogos.length * 1.67 - (routeLogos.length - 1) * 0.625 + "vw";
    // });
    if (!containers) {
      return;
    }

    containers.forEach((el) => {
      const routeLogos = el.querySelectorAll(".route-logo");

      routeLogos.forEach((el, i) => {
        el.style.transform = `translateX(${i * 100}%)`;
        el.style.left = `${i * -12}px`;
      });

      el.style.width =
        routeLogos.length * 26 - (routeLogos.length - 1) * 12 + "px";
    });
  }

  const router = routesData.some((v) => v.key == transactionData.router)
    ? transactionData.router
    : CHAINSPOT;

    const isVenomPath = isVenomRoute(formData) 
  return (
    <>
      <div className="swap-details">
        <div className="swap-details__main">
          <div className="swap-details__target">
            <img
              className="swap-details__target-icon"
              src={getTokenImg(formData.cryptoTo)}
              alt=""
            />
            <div className="swap-details__target-caption">
              <span className="swap-details__target-title">
                {clearAmount(formData.amountTo)}{" "}
                {formData.cryptoTo.symbol}
              </span>
              <div className="swap-details__target-details">
                <span>${formData.amountToUSD}</span>
                <span>
                  on{" "}
                  {capitalizeFirstLetter(
                    getNetworkNameByChainId(formData.routeTo.chainId)
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="swap-details__info">
            <div className="swap-details__info-item tooltip-cnt">
              <span className="swap-details__info-icon">
                <img src="/images/time-icon.svg" alt="" />
              </span>
              <span className="swap-details__info-caption">
                {transferTime.minute}
              </span>
              <div className="tooltip-popup">
                <span>
                  Estimated swap execution <br></br>time in minutes
                </span>
              </div>
            </div>
            <div className="swap-details__info-item tooltip-cnt">
              <span className="swap-details__info-icon">
                <img src="/images/gas-icon.svg" alt="" />
              </span>
              <span className="swap-details__info-caption">
                ${isVenomPath ? transactionData.feeInUsd :difAmountUSD  }
              </span>
              <div className="tooltip-popup tooltip-popup_fee">
                <>
                  <span>Estimated network fee </span>
                  <span>
                    {!isVenomPath && transactionData.fee && (
                      <>{transactionData.calcFee.amountInNative.toFixed(4)}</>
                    )}
                    {isVenomPath && <>{transactionData.fee}</>}{" "}
                    {nativeToken && nativeToken.symbol} (${isVenomPath ? transactionData.feeInUsd :difAmountUSD })
                  </span>
                </>
              </div>
            </div>
            <div className="swap-details__info-item tooltip-cnt">
              <span className="swap-details__info-icon">
                <img src="/images/steps-icon.svg" alt="" />
              </span>
              <span className="swap-details__info-caption">
                {transactionData.hopsCount}
              </span>
              <div className="tooltip-popup" style={{ width: "max-content" }}>
                <span>
                  {transactionData.hopsCount}{" "}
                  {transactionData.hopsCount > 1
                    ? "transactions under the hood"
                    : "transaction"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="swap-details__path">
          {transactionData.router && (
            <div className="swap-details__path-item tooltip-cnt">
              <div className="estimated-token__route-logos route-logos">
                <picture>
                  <img
                    className="estimated-token__route-logo route-logo"
                    src={getRouteImage(router)}
                    alt=""
                    style={{ transform: "translateX(0%)", left: "0vw" }}
                  />
                </picture>
              </div>
              <span>Router</span>
              <div className="tooltip-popup">
                <span>Router via {capitalizeFirstLetter(router)}</span>
              </div>
            </div>
          )}
          {transactionData.bridge && (
            <div className="swap-details__path-item tooltip-cnt">
              <div className="estimated-token__route-logos route-logos">
                {/* {transactionData.router && (
                  <img
                    className="estimated-token__route-logo route-logo"
                    src={getServiceImage(transactionData.router)}
                    alt=""
                  />
                )} */}
                <img
                  className="estimated-token__route-logo route-logo"
                  src={getBridgeImageByName(transactionData.bridge)}
                  alt=""
                />
              </div>
              <span>{isVenomPath ? VENOM_BRIDGE : 'Bridge'} </span>
              <div className="tooltip-popup" style={{ left: "15px" }}>
                <span>
                  Bridge via {capitalizeFirstLetter(transactionData.bridge)}
                </span>
              </div>
            </div>
          )}
          {formData.cryptoTo.symbol != formData.cryptoFrom.symbol && (
            <div className="swap-details__path-item tooltip-cnt">
              <div className="estimated-token__route-logos route-logos">
                <img
                  className="estimated-token__route-logo route-logo"
                  src="/images/currency-services/1inch.svg"
                  alt=""
                />
              </div>
              <span>Dex</span>
              <div className="tooltip-popup" style={{ left: "15px" }}>
                <span>Transfer via 1nch</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
