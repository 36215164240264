import { useNavigate } from "react-router-dom";

export default function HistoryEmptyArea() {
  const navigate = useNavigate();
  return (
    <div className="history__empty-cnt">
      <img src="/images/empty-history-icon.svg" alt="" />
      <h2 className="history__empty-title">No transactions yet</h2>
      <p className="history__empty-description">
        The swap history will be displayed here. Make your swap now.
      </p>
      <button
        onClick={() => navigate("/")}
        className="btn btn_popup btn_popup-primary"
      >
        Swap
      </button>
    </div>
  );
}
