import { useState, useEffect, useRef } from "react";
import { TRANSFER_STATE_FINISHED, TRANSFER_STATE_STARTED } from "../walletGateway/useGatewayTransfer";
import { TrustWalletWebProvider } from "../../services/trustWallet/TrustWalletWebProvider";
import { PROVIDER_TRUST_BROWSER } from "../../constants/ProviderTypes";

function useTrustTransfer(transferState, setTransferState) {
  useEffect(() => {
    if (
      transferState.state === TRANSFER_STATE_STARTED &&
      transferState.providerType === PROVIDER_TRUST_BROWSER
    ) {
      async function getTransferApprove() {
        let transferResponce = await TrustWalletWebProvider.sendTransaction(
          transferState.senderAddress,
          transferState.reciverAddress,
          transferState.value,
          transferState.transactionData
        );
        setTransferState((prevState) => ({
          ...prevState,
          isApproved: transferResponce.isApproved,
          transaction: transferResponce.transaction,
          error: transferResponce.errorMessage,
          state: TRANSFER_STATE_FINISHED,
        }));
      }
      getTransferApprove();
    }
  }, [transferState, setTransferState]);
}

export default useTrustTransfer;
