import React from "react";
import SwapDisclaimer from "../../components/Swap/SwapDisclaimer";
import SwapHistoryList from "../../components/SwapHistory/SwapHistoryList";
import SwapMenu from "../../components/SwapMenu/SwapMenu";

export const PAGE_INIT = 1;
export const PAGE_COMPLETED = 2;
export const PAGE_PENDING = 3;

export default function SwapHistory() {
  return (
    <main className="main">
      <section className="page-container">
        <div className="wrapper">
          <div className="transfers-wrapper">
            <div className="history">
              <SwapMenu />
              <SwapHistoryList />
            </div>
            <SwapDisclaimer />
          </div>
        </div>
      </section>
    </main>
  );
}
