export const BRIDGE_LIST = [
    {
        name:'Connext',
        img:'/images/bridges/connext.svg'
    },
    {
        name:'Symbiosis',
        img:'/images/bridges/symbiosis.svg'
    },
    {
        name:'Hop',
        img:'/images/bridges/hop.svg'
    },
    {
        name:'cBridge',
        img:'/images/bridges/cBridge.svg'
    },
    {
        name:'Multichain',
        img:'/images/bridges/multichain.svg'
    },
    {
        name:'Polygon',
        img:'/images/bridges/polygon.svg'
    },
    {
        name:'Avalanche Bridge',
        img:'/images/bridges/avalanche.svg'
    },
    {
        name:'Optimism Gateway',
        img:'/images/bridges/optimism.svg'
    },
    {
        name:'Arbitrum Bridge',
        img:'/images/bridges/arbitrum.svg'
    },
    {
        name:'Across',
        img:'/images/bridges/across.svg'
    },
    {
        name:'Stargate',
        img:'/images/bridges/stargate.svg'
    },
    {
        name:'Biconomy',
        img:'/images/bridges/biconomy.svg'
    },
];