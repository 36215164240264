import { NavLink } from "react-router-dom";
import { VENOM_TRANSFER_URL } from "../../constants/RoutePaths";

export default function SwapMenu() {
  const isVenomRoute = window.location.pathname == VENOM_TRANSFER_URL;
  return (
    <div className="transfers__tabs">
      <NavLink
        to={isVenomRoute ? VENOM_TRANSFER_URL : "/"}
        end
        className="transfers__tab"
      >
        <span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#292D32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.9167 13.1263C12.7667 13.1263 12.6 13.118 12.4417 13.1096C12.1333 13.093 11.8833 12.843 11.8583 12.5346C11.675 10.2096 9.78333 8.32629 7.46666 8.14296C7.15833 8.11796 6.90832 7.86798 6.89166 7.55964C6.88332 7.40131 6.875 7.24297 6.875 7.08464C6.875 3.7513 9.58333 1.04297 12.9167 1.04297C16.25 1.04297 18.9583 3.7513 18.9583 7.08464C18.9583 10.418 16.25 13.1263 12.9167 13.1263ZM8.125 6.96796C10.6083 7.40129 12.6 9.39297 13.0333 11.8763C15.625 11.8096 17.7083 9.69297 17.7083 7.08464C17.7083 4.44297 15.5583 2.29297 12.9167 2.29297C10.3167 2.29297 8.19167 4.37629 8.125 6.96796Z" />
            <path d="M7.08268 18.9583C3.74935 18.9583 1.04102 16.25 1.04102 12.9167C1.04102 9.58333 3.74935 6.875 7.08268 6.875C7.23268 6.875 7.39936 6.88332 7.55769 6.89166C10.4994 7.12499 12.8827 9.50834 13.1077 12.4333C13.116 12.6 13.1243 12.7583 13.1243 12.9167C13.1243 16.25 10.416 18.9583 7.08268 18.9583ZM7.08268 8.125C4.44102 8.125 2.29102 10.275 2.29102 12.9167C2.29102 15.5583 4.44102 17.7083 7.08268 17.7083C9.72435 17.7083 11.8743 15.5583 11.8743 12.9167C11.8743 12.7833 11.866 12.65 11.8577 12.525C11.6744 10.2167 9.79101 8.32499 7.46601 8.14166C7.34934 8.13332 7.21602 8.125 7.08268 8.125Z" />
            <path d="M2.49935 6.11797C2.30768 6.11797 2.12434 6.08463 1.941 6.00963C1.391 5.78463 1.04102 5.2513 1.04102 4.65963V2.5013C1.04102 1.7013 1.69935 1.04297 2.49935 1.04297H4.65768C5.24935 1.04297 5.77434 1.39296 6.00768 1.94296C6.23268 2.49296 6.10767 3.11796 5.691 3.53463L3.53267 5.69296C3.24934 5.96796 2.88268 6.11797 2.49935 6.11797ZM2.49935 2.29297C2.38268 2.29297 2.29102 2.38464 2.29102 2.5013V4.65963C2.29102 4.78463 2.37434 4.83464 2.41601 4.85131C2.46601 4.86797 2.55769 4.89296 2.64103 4.80963L4.79936 2.6513C4.88269 2.56796 4.86601 2.47631 4.84101 2.42631C4.81601 2.37631 4.766 2.30131 4.64934 2.30131H2.49935V2.29297Z" />
            <path d="M17.4997 18.96H15.3414C14.7497 18.96 14.2247 18.61 13.9914 18.06C13.7664 17.51 13.8914 16.885 14.308 16.4683L16.4664 14.31C16.883 13.8933 17.508 13.7683 18.058 13.9933C18.608 14.2183 18.958 14.7516 18.958 15.3433V17.5016C18.958 18.3016 18.2997 18.96 17.4997 18.96ZM17.4913 15.135C17.4497 15.135 17.3997 15.1516 17.3497 15.2016L15.1914 17.36C15.108 17.4433 15.1247 17.535 15.1497 17.585C15.1747 17.635 15.2247 17.71 15.3414 17.71H17.4997C17.6164 17.71 17.708 17.6183 17.708 17.5016V15.3433C17.708 15.2183 17.6247 15.1683 17.583 15.1516C17.558 15.1433 17.5247 15.135 17.4913 15.135Z" />
          </svg>
          Transfer
        </span>
      </NavLink>
      <NavLink to={"/transfer-list"} exact="true" className="transfers__tab">
        <span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#292D32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.99935 18.9596C5.05768 18.9596 1.04102 14.943 1.04102 10.0013C1.04102 5.05964 5.05768 1.04297 9.99935 1.04297C14.941 1.04297 18.9577 5.05964 18.9577 10.0013C18.9577 14.943 14.941 18.9596 9.99935 18.9596ZM9.99935 2.29297C5.74935 2.29297 2.29102 5.7513 2.29102 10.0013C2.29102 14.2513 5.74935 17.7096 9.99935 17.7096C14.2494 17.7096 17.7077 14.2513 17.7077 10.0013C17.7077 5.7513 14.2494 2.29297 9.99935 2.29297Z" />
            <path d="M13.0909 13.2745C12.9826 13.2745 12.8742 13.2495 12.7742 13.1828L10.1909 11.6411C9.54922 11.2578 9.07422 10.4161 9.07422 9.67448V6.25781C9.07422 5.91615 9.35755 5.63281 9.69922 5.63281C10.0409 5.63281 10.3242 5.91615 10.3242 6.25781V9.67448C10.3242 9.97448 10.5742 10.4161 10.8326 10.5661L13.4159 12.1078C13.7159 12.2828 13.8076 12.6661 13.6326 12.9661C13.5076 13.1661 13.2992 13.2745 13.0909 13.2745Z" />
          </svg>
          History
        </span>
      </NavLink>
      <NavLink to={"/faq"} className="transfers__tab">
        <span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#292D32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.9167 13.1263C12.7667 13.1263 12.6 13.118 12.4417 13.1096C12.1333 13.093 11.8833 12.843 11.8583 12.5346C11.675 10.2096 9.78333 8.32629 7.46666 8.14296C7.15833 8.11796 6.90832 7.86798 6.89166 7.55964C6.88332 7.40131 6.875 7.24297 6.875 7.08464C6.875 3.7513 9.58333 1.04297 12.9167 1.04297C16.25 1.04297 18.9583 3.7513 18.9583 7.08464C18.9583 10.418 16.25 13.1263 12.9167 13.1263ZM8.125 6.96796C10.6083 7.40129 12.6 9.39297 13.0333 11.8763C15.625 11.8096 17.7083 9.69297 17.7083 7.08464C17.7083 4.44297 15.5583 2.29297 12.9167 2.29297C10.3167 2.29297 8.19167 4.37629 8.125 6.96796Z" />
            <path d="M7.08268 18.9583C3.74935 18.9583 1.04102 16.25 1.04102 12.9167C1.04102 9.58333 3.74935 6.875 7.08268 6.875C7.23268 6.875 7.39936 6.88332 7.55769 6.89166C10.4994 7.12499 12.8827 9.50834 13.1077 12.4333C13.116 12.6 13.1243 12.7583 13.1243 12.9167C13.1243 16.25 10.416 18.9583 7.08268 18.9583ZM7.08268 8.125C4.44102 8.125 2.29102 10.275 2.29102 12.9167C2.29102 15.5583 4.44102 17.7083 7.08268 17.7083C9.72435 17.7083 11.8743 15.5583 11.8743 12.9167C11.8743 12.7833 11.866 12.65 11.8577 12.525C11.6744 10.2167 9.79101 8.32499 7.46601 8.14166C7.34934 8.13332 7.21602 8.125 7.08268 8.125Z" />
            <path d="M2.49935 6.11797C2.30768 6.11797 2.12434 6.08463 1.941 6.00963C1.391 5.78463 1.04102 5.2513 1.04102 4.65963V2.5013C1.04102 1.7013 1.69935 1.04297 2.49935 1.04297H4.65768C5.24935 1.04297 5.77434 1.39296 6.00768 1.94296C6.23268 2.49296 6.10767 3.11796 5.691 3.53463L3.53267 5.69296C3.24934 5.96796 2.88268 6.11797 2.49935 6.11797ZM2.49935 2.29297C2.38268 2.29297 2.29102 2.38464 2.29102 2.5013V4.65963C2.29102 4.78463 2.37434 4.83464 2.41601 4.85131C2.46601 4.86797 2.55769 4.89296 2.64103 4.80963L4.79936 2.6513C4.88269 2.56796 4.86601 2.47631 4.84101 2.42631C4.81601 2.37631 4.766 2.30131 4.64934 2.30131H2.49935V2.29297Z" />
            <path d="M17.4997 18.96H15.3414C14.7497 18.96 14.2247 18.61 13.9914 18.06C13.7664 17.51 13.8914 16.885 14.308 16.4683L16.4664 14.31C16.883 13.8933 17.508 13.7683 18.058 13.9933C18.608 14.2183 18.958 14.7516 18.958 15.3433V17.5016C18.958 18.3016 18.2997 18.96 17.4997 18.96ZM17.4913 15.135C17.4497 15.135 17.3997 15.1516 17.3497 15.2016L15.1914 17.36C15.108 17.4433 15.1247 17.535 15.1497 17.585C15.1747 17.635 15.2247 17.71 15.3414 17.71H17.4997C17.6164 17.71 17.708 17.6183 17.708 17.5016V15.3433C17.708 15.2183 17.6247 15.1683 17.583 15.1516C17.558 15.1433 17.5247 15.135 17.4913 15.135Z" />
          </svg>
          FAQ
        </span>
      </NavLink>
    </div>
  );
}
