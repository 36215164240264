import { Contract, ethers, utils } from "ethers";
import { ALERT_WARNING } from "../../constants/AlertTypes";
import { CHAIN_CHANGE_ALREADY_PROCCESS_ERROR } from "../../constants/Errors";
import { showNotifaction } from "../../features/dialogs/notificationPopupSlice";
import {
  getBalanceByJsonRpc,
  getBalanceByJsonRpc2,
} from "../../features/walletService/balanceService";
import {
  isZeroAddress,
  truncateDecimals,
} from "../../features/walletService/utils";
import ERC20Abi from "../../utils/ERC20Abi";
import { isHex } from "../../utils/TransferApiUtil";
import { signMeta } from "./MetaFunctions";
import detectEthereumProvider from "@metamask/detect-provider";
import { getNetworkId, getNetworks } from "../../utils/NetworkList";

const ERROR_METAMASK_NOT_INSTALLED = "Metamask extension is not installed";
const ERROR_METAMASK_IS_NOT_MAIN =
  "You have many wallet extension and metamask is not main. Please, turn off other wallets in browser extension";
export const SIGN_KEY = "SIGNED_DATA";
export const MetamaskWebProvider = {
  ethereum: window.ethereum,
  provider: null,
  isMetamaskInstalled: async function () {
    const provider = await detectEthereumProvider({
      mustBeMetaMask: true,
      silent: true,
    });
    this.provider = provider;
    return typeof this.provider == "object"  ? true : false;
  },
  isMetaMainWallet: function () {
    return this.provider == window.ethereum && this.provider.isMetaMask &&  this.provider._metamask;
  },
  checkingMetaExtension: async function () {
    let res = {
      hasError: false,
      errorMessage: null,
    };
    let isInstalled = await this.isMetamaskInstalled();
    if (!isInstalled) {
      res.hasError = true;
      res.errorMessage = ERROR_METAMASK_NOT_INSTALLED;
    }
    if (!res.hasError && !this.isMetaMainWallet()) {
      res.hasError = true;
      res.errorMessage = ERROR_METAMASK_IS_NOT_MAIN;
    }
    return res;
  },
  getProvider : async function(){
    return await  detectEthereumProvider({
      mustBeMetaMask: true,
      silent: true,
    });
  },
  autoConnect: async function (dispatch, walletInfo) {
    let validate = await this.checkingMetaExtension();
    if (validate.hasError) {
      dispatch(
        showNotifaction({
          alertType: ALERT_WARNING,
          caption: validate.errorMessage,
        })
      );
      return walletInfo;
    }
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });
    if (accounts.length > 0) {
      walletInfo.networkId = getNetworkId(this.provider.networkVersion);
      walletInfo.accountAddress = accounts[0];
      walletInfo.networkChainId = this.provider.networkVersion;
      walletInfo.isConnected = true;
    }
    return walletInfo;
  },
  connect: async function (dispatch, walletInfo) {
    let validate = await this.checkingMetaExtension();
    if (validate.hasError) {
      dispatch(
        showNotifaction({
          alertType: ALERT_WARNING,
          caption: validate.errorMessage,
        })
      );
      return walletInfo;
    }

    try {
      // let isConnected = await window.ethereum._metamask.isUnlocked();
      // const timer = new Promise((resolve, reject) => {
      //   setTimeout(resolve, 5000, "timeout");
      // });

      // if (!isConnected) {
      //   console.error("Please connect to MetaMask.");
      // }
      let ascAccountList = this.provider.request({
        method: "eth_requestAccounts",
      });

      let accounts = [];
      // if (!isConnected) {
      //   let waiter = await Promise.race([timer, ascAccountList]);
      //   if (waiter === "timeout") {
      //     throw "timeout";
      //   }
      // } else {
      accounts = await ascAccountList;
      // }
      if(accounts.length > 0){
        walletInfo.accountAddress = accounts[0];
        walletInfo.networkChainId = this.provider.networkVersion;
        walletInfo.isConnected = true;
      }
    } catch (error) {
      let errorMessage =
        (error && error?.code == -32002) || error == "timeout"
          ? "Please unlock metamask"
          : error?.message || error;
      dispatch(
        showNotifaction({ alertType: ALERT_WARNING, caption: errorMessage })
      );
    }
    return walletInfo;
  },
  getNativeBalance: async function (address) {
    const provider = new ethers.providers.Web3Provider(this.provider);
    let bal = await provider.getBalance(address);
    return parseFloat(ethers.utils.formatEther(bal));
  },
  isMetaSigned: function (accountAddress) {
    let signedAccounts = localStorage.getItem(SIGN_KEY)
      ? JSON.parse(localStorage.getItem(SIGN_KEY))
      : null;
    if (signedAccounts && signedAccounts.includes(accountAddress)) {
      return true;
    }
    return false;
  },
  makeSign: async function (accountAddress) {
    if (this.isMetaSigned(accountAddress)) {
      return true;
    }
    let signedAccounts = [];
    let isSigned = await signMeta(accountAddress);
    if (isSigned) {
      signedAccounts.push(accountAddress);
      localStorage.setItem(SIGN_KEY, JSON.stringify(signedAccounts));
    }
    return isSigned;
  },
  approve: async function (
    amount,
    contractAddress,
    accountAddress,
    approvalAddress
  ) {
    let proccessResponce = {
      isApproved: false,
      networkResp: null,
    };
    let isSigned = await this.makeSign(accountAddress);
    if(!isSigned){
      return proccessResponce;
    }
    if (isZeroAddress(contractAddress)) {
      proccessResponce.isApproved = true;
      return proccessResponce;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    let contract = await new ethers.Contract(contractAddress, ERC20Abi, signer);

    try {
      const allowance = await contract.allowance(
        accountAddress,
        approvalAddress
      );

      if (allowance.lt(amount)) {
        const res = await contract.approve(approvalAddress, amount);
        proccessResponce.isApproved = true;
        proccessResponce.networkResp = res;
      } else {
        proccessResponce.isApproved = true;
      }
    } catch (error) {
      proccessResponce.isApproved = false;
    }
    return proccessResponce;
  },
  requestApprove: async function (tokenAddress, accountAddress, amount) {
    let abi = [
      "function approve(address _spender, uint256 _value) public returns (bool success)",
    ];
    const provider = new ethers.providers.Web3Provider(this.provider);
    // let provider = ethers.getDefaultProvider('ropsten')
    let contract = new ethers.Contract(tokenAddress, abi, provider);
    const res = await contract.approve(accountAddress, amount);
    return res;
  },
  getBalance: async function (token, accountAddress) {
    let balance = isZeroAddress(token.contractAddress)
      ? this.getNativeBalance(accountAddress)
      : this.getTokenBalance(token, accountAddress);
    return truncateDecimals(balance);
  },
  getTokenBalance: async function (token, accountAddress) {
    const provider = new ethers.providers.Web3Provider(this.provider);
    const contract = new Contract(token.contractAddress, ERC20Abi, provider);
    const balance = await contract.balanceOf(accountAddress);
    return utils.formatUnits(balance, token.decimals);
  },
  getTokenBalanceByContractAddress: async function (
    routeFrom,
    cryptoFrom,
    accountAddress,
    chainId
  ) {
    let balanceInfo = {
      hasError: false,
      error: null,
      balance: 0,
    };
    try {
      balanceInfo = await getBalanceByJsonRpc2(
        routeFrom.rpcUrls[0],
        cryptoFrom.contractAddress,
        cryptoFrom.decimals,
        accountAddress
      );
    } catch (error) {
      console.error(error, "Balance Error");
    }
    if (balanceInfo.hasError && chainId == routeFrom.chainId) {
      try {
        balanceInfo.hasError = false;
        balanceInfo.balance = await this.getBalance(
          cryptoFrom,
          accountAddress,
          routeFrom
        );
      } catch (error) {
        balanceInfo.hasError = true;
        balanceInfo.error = error;
      }
    }
    return balanceInfo;
  },
  addChain: async function (selectedNetwork) {
    let addInfo = {
      isSuccess: false,
      error: null,
    };
    try {
      await this.provider.request({
        method: "wallet_addEthereumChain",
        params: [selectedNetwork],
      });
      addInfo.isSuccess = true;
    } catch (error) {
      addInfo.error = error?.message || error;
    }
    return addInfo;
  },
  changeChain: async function (network) {
    let changeInfo = {
      isSuccess: false,
      error: null,
    };
    let crypto = network.cryptos.find((v) => isZeroAddress(v.contractAddress));
    if (!crypto) {
      crypto = network.cryptos.find((v) => v.contractAddress === null);
    }
    const selectedNetwork = {
      chainId: ethers.utils.hexValue(parseInt(network.chainId)),
      chainName: network.name,
      nativeCurrency: {
        name: crypto.name,
        decimals: crypto.decimals,
        symbol: crypto.symbol,
      },
      rpcUrls: network.rpcUrls,
    };
    console.log(selectedNetwork, crypto,'crypto')

    if (this.provider.networkVersion !== selectedNetwork.chainId) {
      try {
        await this.provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: selectedNetwork.chainId }],
          // params: [{ chainId: utils.toHex(chainId) }],
        });
        changeInfo.isSuccess = true;
      } catch (err) {
        if (err.code == 4902) {
          changeInfo = await this.addChain(selectedNetwork);
        } else {
          changeInfo.error = this.processChainError(err);
        }
      }
    } else {
      changeInfo.isSuccess = true;
    }
    return changeInfo;
  },
  processChainError: function (error) {
    let errMessage;
    if (error.code && error.code == -32002) {
      errMessage = CHAIN_CHANGE_ALREADY_PROCCESS_ERROR;
    } else {
      errMessage = error.message || error;
    }
    return errMessage;
  },
  getGasPrice: async function () {
    const provider = new ethers.providers.Web3Provider(this.provider);
    const price = await provider.getGasPrice();
    return price;
  },
  calcGas: async function () {
    const provider = new ethers.providers.Web3Provider(this.provider);
    const price = await provider.getGasPrice();
    const str = ethers.utils.formatEther(price);
    const eth = str * 2;
    const estimation = ethers.utils.parseEther(eth.toFixed(18));
    return estimation._hex;
  },
  calsTransGas: async function (transactionData) {
    const provider = new ethers.providers.Web3Provider(this.provider);
    const price = await provider.estimateGas({
      to: transactionData.to,

      // `function deposit() payable`
      data: transactionData.data,

      // 1 ether
      // value: parseEther("1.0")
    });
    return price;
  },
  sendTransaction: async function (transactionData, dispatch) {
    let hasError = true;
    let txHash = null;

    const gasPrice = await this.calcGas();

    const transactionParameters = {
      nonce: "0x00", // ignored by MetaMask
      gasPrice: gasPrice, //transactionData.gasPrice ? transactionData.gasPrice : '0x09184e72a000', // customizable by user during MetaMask confirmation.
      gas: null, // ?  transactionData.gasLimit : '0x5208', // customizable by user during MetaMask confirmation.
      to: transactionData.to, // Required except during contract publications.
      from: this.provider.selectedAddress, // must match user's active address.
      value: transactionData.value, // Only required to send ether to the recipient from the initiating external account.
      data: transactionData.data, // Optional, but used for defining smart contract creation and interaction.
      chainId: transactionData.chainId, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
    };
    try {
      txHash = await this.provider.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
      hasError = false;
    } catch (error) {
      dispatch(
        showNotifaction({
          alertType: ALERT_WARNING,
          caption: error?.message || error,
        })
      );
      console.error(error, "error");
    }
    return {
      hasError: hasError,
      txHash: txHash,
    };
  },
  sendTransactionNew: async function (
    senderAddress,
    reciverAddress,
    value,
    transactionData
  ) {
    let result = {
      isApproved: false,
      txHash: null,
      errorMessage: null,
    };

    const gasPrice = await this.calcGas();

    const transactionParameters = {
      nonce: "0x00", // ignored by MetaMask
      gasPrice: gasPrice, //transactionData.gasPrice ? transactionData.gasPrice : '0x09184e72a000', // customizable by user during MetaMask confirmation.
      gas: null, // ?  transactionData.gasLimit : '0x5208', // customizable by user during MetaMask confirmation.
      to: reciverAddress, //transactionData.to, // Required except during contract publications.
      from: senderAddress, //this.provider.selectedAddress, // must match user's active address.
      value: value, //transactionData.value, // Only required to send ether to the recipient from the initiating external account.
      data: transactionData, //transactionData.data, // Optional, but used for defining smart contract creation and interaction.
      // chainId: transactionData.chainId, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
    };
    // console.info(transactionParameters, "transactionParameters");
    try {
      let txHash = await this.provider.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
      result.isApproved = true;
      result.transaction = txHash;
    } catch (error) {
      result.errorMessage = error?.message || error;
    }
    return result;
  },
};
