import { useEffect } from "react";
import { useContract, useSigner, erc20ABI } from "wagmi";
import {
  APPROVAL_STATE_FINISHED,
  APPROVAL_STATE_STARTED,
} from "../walletGateway/useGatewayApprove";
import { useContractRead } from "wagmi";
import { getContract, getProvider } from "@wagmi/core";
import { prepareWriteContract,writeContract } from '@wagmi/core'
import { TRANSPORT_WAGMI } from "../../constants/ProviderTypes";

function useWagmiAlowance(approvalState, setApprovalState) {
  const { data: signer } = useSigner();

  useEffect(() => {
    const provider = getProvider();

    if (
      approvalState.state === APPROVAL_STATE_STARTED &&
      approvalState.transportType === TRANSPORT_WAGMI
    ) {
      async function getAllowance() {
        const contract = getContract({
          address: approvalState.tokenContractAddress,
          abi: erc20ABI,
          signerOrProvider: provider,
        });
        let approveTransaction = null;
        let isApproved = false;
        try {
          const token1Allowance = await contract.allowance(
            approvalState.accountAddress,
            approvalState.approvalAddress
          );

          if (token1Allowance.lt(approvalState.amount)) {
            // console.log('asas',signer,approvalState.amount)
            // approveTransaction = await contract.connect(signer).approve(
            //   approvalState.approvalAddress,
            //   approvalState.amount
            // );

            const config = await prepareWriteContract({
              address: approvalState.tokenContractAddress,
              abi: erc20ABI,
              functionName: 'approve',
              args: [approvalState.approvalAddress,approvalState.amount],
            })
            approveTransaction = await writeContract(config)

            
            // approvalState.transaction = approveTransaction;
          }
          isApproved = true;
        } catch (error) {
          console.error(error, "error");
        }
        setApprovalState((prevState) => ({
          ...prevState,
          isApproved: isApproved,
          transaction: approveTransaction,
          state: APPROVAL_STATE_FINISHED,
        }));
      }
      getAllowance();
    }
  }, [approvalState, setApprovalState]);
}

export default useWagmiAlowance;
