import { configureStore } from '@reduxjs/toolkit'
import anlyticReducer from '../features/analytic/analyticSlice'
import walletServiceProvider from '../features/walletService/walletService'
import menuSlice from '../features/menu/menuSlice'
import switchNetworkSlice from '../features/dialogs/switchNetworkSlice'
import notificationPopupSlice from '../features/dialogs/notificationPopupSlice'
import chooseTokenSlice from '../features/dialogs/chooseTokenSlice'
import venomwalletService from "../features/walletService/venomwalletService";

export default configureStore({
  reducer: {
    analyticShowMenu: anlyticReducer,
    menuManagment: menuSlice,
    walletServiceProvider: walletServiceProvider,
    switchNetworkPopup:switchNetworkSlice,
    notificationPopupManager:notificationPopupSlice,
    chooseTokenManager:chooseTokenSlice,
    venomWalletManager:venomwalletService,
  },
})
