import { useEffect } from "react";
import { setApprove, setHash } from "../../utils/TransferApiUtil";
import {
  STEP_APPROVE_APPROVED,
  STEP_APPROVE_REJECTED,
  STEP_APPROVE_STARTED,
  STEP_IN_TRANSFER_APPROVED,
  STEP_IN_TRANSFER_REJECTED,
  STEP_IN_TRANSFER_START,
  STEP_TRANSFER_APPROVED,
  STEP_TRANSFER_FINISHED,
  STEP_TRANSFER_START,
} from "../../constants/TransferConstants";
import {
  TYPE_REFUSED,
  TYPE_TRANSFER,
} from "../../constants/TransactionStepConstants";
import { ethers } from "ethers";
import { IsTvmApproveRequired, isVenomRoute } from "../../utils/NetworkList";
import { makeVenomPay } from "../venom/VenomUtil";

export function useTokenApprove(
  process,
  formData,
  setTransactionStep,
  handleTransactionProcess,
  transactionData,
  setTransactionList,
  curTransId
) {
  // useEffect(() => {}, [transactionStep]);

  const beforApprove = (isRetry = false) => {
    setTransactionStep(STEP_APPROVE_STARTED);
    if (!isRetry) {
      onInitTransaction();
    } else {
      handleTransactionProcess(STEP_APPROVE_STARTED);
    }
  };

  const onInitTransaction = () => {
    const newNum = Date.now();

    curTransId.current = newNum;

    let isTvmApproveRequired = IsTvmApproveRequired(formData);
    let newProccess = {
      id: newNum,
      state: STEP_APPROVE_STARTED,
      isApproved: false,
      approveTx: null,
      isTransferApproved: false,
      transfetTx: null,
      isInNetworkTokenApproved: true,
      isInNetworkApproveRequired: isTvmApproveRequired,
      isInNetworkApproved: isTvmApproveRequired ? false : true,
      inNetworkTx: null,
      inNetworkTxUrl: null,
      isFinished: false,
    };

    setTransactionList((prevState) => [...prevState, newProccess]);

    // onProcessTransactions.current = [
    //   ...onProcessTransactions.current,
    //   newProccess,
    // ];
  };


  const onApproveRejected = () => {
    setTransactionStep(STEP_APPROVE_REJECTED);
    // showStepPopup(TYPE_REFUSED);
    handleTransactionProcess(STEP_APPROVE_REJECTED);
  };

  const afterApprove = async (tx = null) => {
    setTransactionStep(STEP_APPROVE_APPROVED);
    handleTransactionProcess(STEP_APPROVE_APPROVED, tx ? tx.hash : null);
    // showStepPopup(TYPE_ALOWANCE);
    setApprove(transactionData.transactionId, tx ? tx.hash : null);
    if (tx) {
      let res = await tx.wait();
    }

    sendTransaction();
  };

  const sendTransaction = async () => {
    setTransactionStep(STEP_TRANSFER_START);
    handleTransactionProcess(STEP_TRANSFER_START);
  };

  return { onApproveRejected, beforApprove,afterApprove, sendTransaction };
}
