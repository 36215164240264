import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useMetasmaskBalance from "../metamask/useMetasmaskBalance";
import useWagmiBalance from "../wagmi/useWagmiBalance";
import { getPiceByCryptoSymbol } from "../../utils/TransferApiUtil";
import useMetasmaskCalcFee from "../metamask/useMetasmaskCalcFee";
import { isZeroAddress } from "../../features/walletService/utils";
import useWagmiCalcFee from "../wagmi/useWagmiCalcFee";
import { BigNumber, ethers } from "ethers";
import useTrustCalcFee from "../trust/useTrustCalcFee";
import { getWallet } from "../../utils/WalletUtil";

export const FEE_STATE_INACTIVE = 0;
export const FEE_STATE_START = 1;
export const FEE_STATE_GETED = 2;
export const FEE_STATE_NATIVE_SETED = 3;

const initFeeeState = {
  gasLimit: null,
  network: null,
  providerType: null,
  transportType: null,
  state: FEE_STATE_INACTIVE,
  error: null,
  gasPrice: null,
  feeNative: null,
  feeUsd: 0,
};

function useGatewayCalcFee(gasLimit, network, setDataTransaction) {
  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const walletInfo = getWallet(connectedWallets);
  const [feeState, setFeeState] = useState(initFeeeState);

  useEffect(() => {
    if (feeState.state == FEE_STATE_GETED) {
      const calcNativeFee = async () => {
        let amount = 0;
        let errorMsg = null;
        if (feeState.gasPrice) {
          try {
            let gasPrice = feeState.gasPrice;
            const roundDecimal = 4;
            const nativeDecimals = 18;
            amount = gasPrice
              .mul(BigNumber.from(feeState.gasLimit))
              .div(BigNumber.from(10).pow(nativeDecimals - roundDecimal));

            amount = parseFloat(ethers.utils.formatUnits(amount, roundDecimal));
          } catch (error) {
            errorMsg = error?.message || error;
          }
        }

        setFeeState((prevState) => ({
          ...prevState,
          feeNative: amount,
          state: FEE_STATE_NATIVE_SETED,
        }));
      };
      calcNativeFee();
    }

    if (feeState.state == FEE_STATE_NATIVE_SETED) {
      const convertToUsdt = async () => {
        let nativeCrypto = network.cryptos.find((token) =>
          isZeroAddress(token.contractAddress)
        );
        let amountInUsd = 0;
        if (nativeCrypto) {
          const usdCourse = await getPiceByCryptoSymbol(nativeCrypto.symbol);
          amountInUsd = usdCourse.hasError
            ? 0
            : feeState.feeNative * usdCourse.data;
        }
        setDataTransaction((prevState) => ({
          ...prevState,
          calcFee: {
            amountInNative: feeState.feeNative,
            amountInUsd: amountInUsd,
          },
        }));
      };
      if (network && feeState.feeNative) {
        convertToUsdt();
      }
    }
  }, [feeState]);

  useEffect(() => {
    if (!walletInfo.isConnected || !network || !gasLimit) {
      return;
    }

    setFeeState({
      gasLimit: gasLimit,
      network: network,
      providerType: walletInfo.providerType,
      transportType: walletInfo.transportType,
      state: FEE_STATE_START,
      gasPrice: null,
      error: null,
      feeNative: null,
    });
  }, [gasLimit, network, walletInfo]);

  useMetasmaskCalcFee(feeState, setFeeState);
  useWagmiCalcFee(feeState, setFeeState);
  useTrustCalcFee(feeState, setFeeState);
  // return tokenBalance;
}

export default useGatewayCalcFee;
