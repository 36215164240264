import { useState } from "react";
import { getFiterBridges } from "../../data/ranking";

function BridgeSelector(props){
    let filterBridges = getFiterBridges();
    const [selBridgeId, setBridge] = useState('All');

    return (         <div className={"filter-switch disabledBlock " +props.wrapClassname}>
    <label className="filter-switch__item">
      <input className="filter-switch__input" type="radio" name="filter" 
        defaultChecked={selBridgeId === 'All'}
        onClick={() => setBridge('All')}
        />
      <span className="filter-switch__button">All</span>
    </label>
    {filterBridges.map((bridge,index) =>(
    <label key={'bridge'+index} className="filter-switch__item" onClick={() => setBridge(bridge.id)}>
    <input className="filter-switch__input" type="radio" name="filter" defaultChecked={selBridgeId === bridge.id}/>
    <span className="filter-switch__button">{bridge.title}</span>
  </label>
    ))}
  </div>

    );
}

export default BridgeSelector;