import { useState, useEffect, useRef } from "react";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import {
  BALANCE_STATE_GETED,
  BALANCE_STATE_START,
} from "../walletGateway/useGatewayBalance";
import { useBalance, useFeeData } from "wagmi";
import {
  isZeroAddress,
  truncateDecimals,
} from "../../features/walletService/utils";
import { BigNumber, ethers } from "ethers";
import { FEE_STATE_GETED } from "../walletGateway/useGatewayCalcFee";
import { TRANSPORT_WAGMI } from "../../constants/ProviderTypes";

function useWagmiCalcFee(feeState, setFeeState) {
  const { data, isError, isLoading } = useFeeData({
    chainId: parseInt(feeState.network?.chainId),
    enabled:
      feeState.transportType == TRANSPORT_WAGMI &&
      feeState.state == BALANCE_STATE_START,
    onSettled(data, error) {
      setFeeState((prevState) => ({
        ...prevState,
        error: error?.message || error,
        gasPrice: error ? 0 : data.gasPrice,
        state: FEE_STATE_GETED,
      }));
    },
  });
}

export default useWagmiCalcFee;
