import { useState, useEffect, useRef } from "react";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import { TRANSFER_STATE_FINISHED, TRANSFER_STATE_INACTIVE, TRANSFER_STATE_STARTED } from "../walletGateway/useGatewayTransfer";
import { PROVIDER_METAMASK } from "../../constants/ProviderTypes";

function useMetasmaskTransfer(transferState, setTransferState) {
  useEffect(() => {
    if (
      transferState.state === TRANSFER_STATE_STARTED &&
      transferState.providerType === PROVIDER_METAMASK
    ) {
      async function getTransferApprove() {
        let transferResponce = await MetamaskWebProvider.sendTransactionNew(
          transferState.senderAddress,
          transferState.reciverAddress,
          transferState.value,
          transferState.transactionData
        );
        setTransferState((prevState) => ({
          ...prevState,
          isApproved: transferResponce.isApproved,
          transaction: transferResponce.transaction,
          error: transferResponce.errorMessage,
          state: TRANSFER_STATE_FINISHED,
        }));
      }
      getTransferApprove();
    }
  }, [transferState, setTransferState]);
}

export default useMetasmaskTransfer;
