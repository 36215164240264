export const MIN_USD_AMOUNT = 20;
export const CHECK_MIN_AMOUNT = false;

export const TOKEN_DIRECTION_FROM = "From";

export const STEP_FORM_FILL = 0;
export const STEP_FORM_NO_ROUTE_FOUND = -2;
export const STEP_LOAD_QUOTA = 1;
export const STEP_SWITCH_NETWORK = -1;
// export const STEP_READY_START_SWAP = 2;
export const STEP_UPDATE_RATES = 2;
export const STEP_READY_APPROVE = 3;
export const STEP_APPROVE_STARTED = 4;
export const STEP_APPROVE_APPROVED = 5;
export const STEP_APPROVE_REJECTED = 6;
export const STEP_TRANSFER_START = 7;
export const STEP_TRANSFER_REJECTED = 8;
export const STEP_TRANSFER_APPROVED = 9;
// export const STEP_OUT_TRANSFER_FINISHED = 10;
export const STEP_IN_TRANSFER_START = 11;
export const STEP_IN_TRANSFER_APPROVED = 12;
export const STEP_IN_TRANSFER_REJECTED = 13;
export const STEP_IN_TRANSFER_REJECTED_BY_CONTRACT = 14;
export const STEP_TRANSFER_FINISHED = 15;

export const PROCESS_FIND_ROUTE = 1;
export const PROCESS_SWAP_ROUTE = 2;

export const DEFAULT_TOKEN_SYMBOL = "USDT";
export const DEFAULT_TOKEN_SYMBOL2 = "USDC";

export const STATUS_APPROVED = "approved";
export const STATUS_IN_PROGRESS = "in_progress";
export const STATUS_FINISHED = "finished";
export const STATUS_REJECTED = "rejected";

export const GAS_AMOUNT_USD = 3;
export const GAS_AMOUNT_USD_ETHER = 10;

export const amountErrorText = "Amount too small";
export const amountWarning = "Open beta - minimum transaction amount is 50$";

export const ETH_CHAIN_ID = "1";
export const BSC_CHAIN_ID = "56";
export const VENOM_CHAIN_ID = "1000";
export const TCPT_TOKEN_SYMBOL = "CST";
export const TCPT_TOKEN_TITLE = "CrossTest";

export const MAX_PENDING_COUNT = 3;
export const MAX_COMPLETED_COUNT = 2;
export const MAX_COUNT_PER_PAGE = 5;

export const MAX_ROUTE_REFRESH_TIME = 60; //seconds

export const ALWAYS_TOP_TOKENS = [
  {
    symbol: TCPT_TOKEN_SYMBOL,
    chainId:BSC_CHAIN_ID
  }
];

export const POPULAR_TOKENS = [
  "USDT",
  "USDC",
  "BUSD",
  "DAI",
  "TUSD",
  "USDP",
  "GUSD",
  "sUSD",
  "FRAX",
  "ETH",
  "BNB",
  "AVAX",
  "WBTC",
  "WETH",
  "MATIC",
];

export const SIGN_TEXT = `I am not the person or entities who reside in, are citizens of, are incorporated in, or have a registered office in the United States of America or any Prohibited Localities, as defined in the Terms of Use.
I will not in the future access this site or use Chainspot dApp while located within the United States any Prohibited Localities, as defined in the Terms of Use.
I am not using, and will not in the future use, a VPN to mask my physical location from a restricted territory.
I am lawfully permitted to access this site and use Chainspot dApp under the laws of the jurisdiction on which I reside and am located.
I understand the risks associated with entering into using Chainspot Network protocols.
`;

export const WALLET_CONNECT_PROJECT_ID = "1328b79c271996c976a41d73a1b8f810";

export const STEP_CLAIM_INACTIVE = 0;
export const STEP_CLAIM_START = 1;
export const STEP_CLAIM_APPROVED = 2;
export const STEP_CLAIM_FINISHED = 3;

export const IS_TVM_NEED_GAS_PAYMENT = 1;
