import { SIGN_TEXT } from "../../constants/TransferConstants";

export async function signMeta(account, injectProvider = null) {
  const plainText = SIGN_TEXT;
  let isSigned = true;
  let provider = injectProvider ? injectProvider : window.ethereum; 
  try {
    const from = account;
    // const msg = `0x${Buffer.from(exampleMessage, "utf8").toString("hex")}`;
    const msg = `0x${hexEncode(plainText)}`;
    const sign = await provider.request({
      method: "personal_sign",
      params: [msg, from, "Example password"],
    });
    isSigned = true;
  } catch (err) {
    isSigned = false;
    console.error(err);
  }
  return isSigned;
}

// export const testEncoding = function(){
//   let testStr = SIGN_TEXT;
//   let data = hexEncode(testStr);
//   const msg = `0x${Buffer.from(testStr, "utf8").toString("hex")}`;
//   // let data = hexEncode('test');
// }

export const hexEncode = function (iString) {
  return iString.split("")
     .map(c => c.charCodeAt(0).toString(16).padStart(2, "0"))
     .join("");
};

export  const hexDecode = function (iString) {
  return iString.split(/(\w\w)/g)
   .filter(p => !!p)
   .map(c => String.fromCharCode(parseInt(c, 16)))
   .join("")
};
