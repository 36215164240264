import React from 'react'
import ReactApexChart from 'react-apexcharts';

class ReportDonutChartSection extends React.Component {

  render() {
    return                         <div className="donut-chart-section chart-section">
    <div className="content-tabs">
      <label className="content-tab">
        <input className="content-tab__input" type="radio" name="donutChartTab" defaultChecked={true}/>
        <span className="content-tab__button">Market shares</span>
      </label>
      <label className="content-tab disabledBlock">
        <input className="content-tab__input" type="radio" name="donutChartTab"/>
        <span className="content-tab__button">Asset distribution</span>
      </label>
      <label className="content-tab disabledBlock">
        <input className="content-tab__input" type="radio" name="donutChartTab"/>
        <span className="content-tab__button">TOP richest users distribution</span>
      </label>
    </div>
    <div className="chart-section__content">
      <div className="chart-section__header">
        <div className="chart-section__title-block">
          <h2 className="chart-section__title secondary-title">Market shares</h2>
          <button className="tooltip-btn tooltip-btn_m">?</button>
        </div>
        <div className="chart-section__legend">
        </div>
        <div className="chart-section__container" id="donutChart">
          {/* {this.props.donutData.series} */}
        { this.props.donutData.series && <ReactApexChart
              options={this.props.donutData.options}
              series={this.props.donutData.series}
              type="donut"
              // height={350}
              width={800}
            />
        }
        </div>
        <div className="chart-section__container" id="donutChart2"></div>
        <div className="chart-section__container" id="donutChart3"></div>
      </div>
    </div>
  </div>
  }
}
export default ReportDonutChartSection
