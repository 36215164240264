import { useSelector } from "react-redux";
import RouterInfo from "../../components/Swap/RouterInfo";
import SwapContentLoading from "./SwapContentLoading";
import SwapFilterBlock from "./SwapFilterBlock";
import SwapForm from "./SwapForm";
import SwapInfoBlock from "./SwapInfoBlock";
import SwapButton from "../../components/Swap/SwapButton";
import { useEffect, useState } from "react";
import CircleProgressBar from "../CircleProgressBar/CircleProgressBar";
import { MAX_ROUTE_REFRESH_TIME } from "../../constants/TransferConstants";
import { isWalletConnected } from "../../utils/WalletUtil";
import { CHAIN_TYPE_EVM } from "../../constants/ChainTypes";

export default function SwapFormBock({
  isLoading,
  handleSubmit,
  formData,
  replaceNetwork,
  fieldChange,
  tokenBalance,
  openTokenSelector,
  setMaxAmount,
  tokenOutBalance,
  transactionData,
  transactionStep,
  btnHandler,
  timeLeft,
  startLoadingQuota,
}) {
  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const isEvmWalletConnected = isWalletConnected(connectedWallets);

  return (
    <>
      {isLoading && <SwapContentLoading />}
      {false && timeLeft > 0 && (
        <div className="cirleWrap" onClick={startLoadingQuota}>
          <CircleProgressBar
            size={30}
            trackWidth={2}
            indicatorWidth={2}
            progress={timeLeft * 1.6}
          />
        </div>
      )}
      {/* {warning && false && (
        <div className="transfers__error error-block">
          <img src="./images/error-icon.svg" alt="" />
          <span>{warning}</span>
        </div>
      )} */}
      <RouterInfo />
      <form className="transfers-form" onSubmit={handleSubmit}>
        <fieldset className="transfers-form__main">
          <SwapForm
            formData={formData}
            replaceNetwork={replaceNetwork}
            fieldChange={fieldChange}
            tokenBalance={tokenBalance}
            openTokenSelector={openTokenSelector}
            setMaxAmount={setMaxAmount}
            tokenOutBalance={tokenOutBalance}
          />
          {isEvmWalletConnected && (
            <SwapInfoBlock
              formData={formData}
              transactionData={transactionData}
              transactionStep={transactionStep}
              tokenBalance={tokenBalance}
            />
          )}
          <SwapButton
            transactionStep={transactionStep}
            formData={formData}
            btnHandler={btnHandler}
          />
        </fieldset>
        <SwapFilterBlock />
      </form>
    </>
  );
}
