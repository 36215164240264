import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  convertTransactionTime,
  getTransactionHistory,
  historyStatus,
  pausedStatus,
} from "../../utils/TransferApiUtil";
import SwapHistoryTitle from "./SwapHistoryTitle";
import PendingTransfersList from "./PendingTransfersList";
import CompletedTransfers from "./CompletedTransfers";
import HistoryEmptyArea from "./HistoryEmptyArea";
import {
  MAX_COMPLETED_COUNT,
  MAX_COUNT_PER_PAGE,
  MAX_PENDING_COUNT,
} from "../../constants/TransferConstants";
import SwapContentLoading from "../Swap/SwapContentLoading";
import { getWallet, isWalletConnected } from "../../utils/WalletUtil";

export const PAGE_INIT = 1;
export const PAGE_COMPLETED = 2;
export const PAGE_PENDING = 3;

export default function SwapHistoryList() {
  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const walletInfo = getWallet(connectedWallets);

  const walletAddress = walletInfo ? walletInfo.accountAddress : null;

  const [pendingTransfers, setPendingTransfers] = useState([]);
  const [completedTransfers, setCompletedTransfers] = useState([]);
  const [pendingPageInfo, setPendingPageInfo] = useState(null);
  const [completedPageInfo, setCompletedPageInfo] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [pageType, setPageType] = useState(PAGE_INIT);
  const pageNum = useRef(1);

  useEffect(() => {
    if (walletAddress) {
      pageNum.current = 1;
      // if (pageType == PAGE_INIT) {
      loadTransferData();
      // }
    }
  }, [walletAddress, pageType]);

  const loadTransferData = async () => {
    setLoading(true);
    setCompletedTransfers([]);
    setPendingTransfers([]);
    let pendinglist = [];
    let completedList = [];
    if ([PAGE_INIT, PAGE_PENDING].includes(pageType)) {
      pendinglist = await loadTransfer(
        pausedStatus(),
        setPendingPageInfo,
        MAX_COUNT_PER_PAGE,
        pageNum.current
      );
      // setPendingTransfers(list);
    }
    if ([PAGE_INIT, PAGE_COMPLETED].includes(pageType)) {
      completedList = await loadTransfer(
        historyStatus(),
        setCompletedPageInfo,
        MAX_COUNT_PER_PAGE,
        pageNum.current
      );
    }
    setTransferList(pendinglist, completedList);
    setLoading(false);
  };

  const getShowCnt = (listCnt1, maxCnt1, listCnt2, maxCnt2) => {
    let showCnt;
    if (listCnt1 <= maxCnt1) {
      showCnt = listCnt1;
    } else {
      showCnt = maxCnt1 + (listCnt2 >= maxCnt2 ? 0 : maxCnt2 - listCnt2);
    }
    return showCnt;
  };

  const setTransferList = (pendinglist, completedList) => {
    if (pageType == PAGE_INIT) {
      let showCnt = getShowCnt(
        pendinglist.length,
        MAX_PENDING_COUNT,
        completedList.length,
        MAX_COMPLETED_COUNT
      );
      setPendingTransfers(pendinglist.filter((v, index) => index < showCnt));

      showCnt = getShowCnt(
        completedList.length,
        MAX_COMPLETED_COUNT,
        pendinglist.length,
        MAX_PENDING_COUNT
      );
      setCompletedTransfers(
        completedList.filter((v, index) => index < showCnt)
      );
    } else {
      setPendingTransfers(pendinglist);
      setCompletedTransfers(completedList);
    }
  };
  // useEffect(() => {
  //   routeLogosContainerHandler();
  // }, [transfers]);

  const loadTransfer = async (status, setPageInfo, count, page = 1) => {
    // cons
    const data = await getTransactionHistory(
      walletAddress,
      status,
      page,
      count
    );
    let list = [];
    if (!data.hasError) {
      // item.day
      list = data.data.data ? data.data.data : [];
      if (list) {
        list = convertTransactionTime(list);
      }
      setPageInfo(data.data.meta);
    }
    return list;
  };

  const gotoPrev = () => {
    pageNum.current = pageNum.current - 1;
    loadTransferData();
  };

  const gotoNext = () => {
    pageNum.current = pageNum.current + 1;
    loadTransferData();
  };

  return (
    <>
      <SwapHistoryTitle pageType={pageType} setPageType={setPageType} />
      <div className="history__container">
        {isLoading && <SwapContentLoading />}
        {pendingTransfers.length > 0 &&
          [PAGE_INIT, PAGE_PENDING].includes(pageType) && (
            <PendingTransfersList
              pendingTransfers={pendingTransfers}
              pendingPageInfo={pendingPageInfo}
              pageType={pageType}
              setPageType={setPageType}
              gotoPrev={gotoPrev}
              gotoNext={gotoNext}
            />
          )}
        {completedTransfers.length > 0 &&
          [PAGE_INIT, PAGE_COMPLETED].includes(pageType) && (
            <CompletedTransfers
              completedTransfers={completedTransfers}
              completedPageInfo={completedPageInfo}
              pageType={pageType}
              setPageType={setPageType}
              gotoPrev={gotoPrev}
              gotoNext={gotoNext}
            />
          )}
        {completedTransfers.length + pendingTransfers.length == 0 &&
          !isLoading && <HistoryEmptyArea />}
      </div>
    </>
  );
}
