export const slipageTypes = [
  {
    key: 1,
    val: "0.1%",
  },
  {
    key: 2,
    val: "0.5%",
  },
  {
    key: 3,
    val: "1%",
  },
  {
    key: 4,
    val: "3%",
  },
];

export const priorities = [
  {
    key: "Smart",
    val: "Smart",
  },
  {
    key: "Fastest",
    val: "Fastest",
  },
  {
    key: "Safest",
    val: "Safest",
  },
];

export const bridgeList = [
  {
    key: "Polygon",
    val: "Polygon",
  },
  {
    key: "Cbridge",
    val: "Cbridge",
  },
  {
    key: "Avalanche",
    val: "Avalanche",
  },
  {
    key: "Arbitrum",
    val: "Arbitrum",
  },
  {
    key: "Connext",
    val: "Connext",
  },
  {
    key: "Hop",
    val: "Hop",
  },
  {
    key: "Hyphen",
    val: "Hyphen",
  },
  {
    key: "Miltichain",
    val: "Miltichain",
  },
  {
    key: "Optimism",
    val: "Optimism",
  },
];

export const exchangeList = [
  {
    key: "Pancakeswap",
    val: "Pancakeswap",
  },
  {
    key: "Spookyswap",
    val: "Spookyswap",
  },
  {
    key: "Spiritswap",
    val: "Spiritswap",
  },
  {
    key: "Viperswap",
    val: "Viperswap",
  },
  {
    key: "Solarbeam",
    val: "Solarbeam",
  },
  {
    key: "Jswap",
    val: "Jswap",
  },
  {
    key: "Cronswap",
    val: "Cronswap",
  },
];
