import FaqItem from "./FaqItem";

export default function FaqList({ questionList }) {
  return (
    <>
      <div className="faq__header">
        <h1 className="faq__title">
          Frequently Asked <span>Questions</span>
        </h1>
      </div>
      <div className="faq__container">
        {questionList.map((item, index) => (
          <FaqItem item={item} key={index} />
        ))}
      </div>
    </>
  );
}
