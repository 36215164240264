import React from "react"

class PageHeader extends React.Component {

  render(){
    return (
      <>
    <section className="page-header">                
        <h1 className="page-title">{this.props.title}</h1>
        <div className="page-tabs">
          <a className="page-tabs__item" href="#/">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#959CA5" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.66982 14.0016H3.99982C2.89982 14.0016 1.99982 14.9016 1.99982 16.0016V21.0016C1.99982 21.5516 2.44982 22.0016 2.99982 22.0016H6.66982C7.21982 22.0016 7.66982 21.5516 7.66982 21.0016V15.0016C7.66982 14.4516 7.21982 14.0016 6.66982 14.0016Z"></path>
              <path d="M13.3302 9.99841H10.6602C9.56016 9.99841 8.66016 10.8984 8.66016 11.9984V20.9984C8.66016 21.5484 9.11016 21.9984 9.66016 21.9984H14.3302C14.8802 21.9984 15.3302 21.5484 15.3302 20.9984V11.9984C15.3302 10.8984 14.4402 9.99841 13.3302 9.99841Z"></path>
              <path d="M20.0001 17.0016H17.3301C16.7801 17.0016 16.3301 17.4516 16.3301 18.0016V21.0016C16.3301 21.5516 16.7801 22.0016 17.3301 22.0016H21.0001C21.5501 22.0016 22.0001 21.5516 22.0001 21.0016V19.0016C22.0001 17.9016 21.1001 17.0016 20.0001 17.0016Z"></path>
              <path d="M15.0101 4.84888C15.3201 4.53888 15.4401 4.16888 15.3401 3.84888C15.2401 3.52888 14.9301 3.29888 14.4901 3.22888L13.5301 3.06888C13.4901 3.06888 13.4001 2.99888 13.3801 2.95888L12.8501 1.89888C12.4501 1.08888 11.5401 1.08888 11.1401 1.89888L10.6101 2.95888C10.6001 2.99888 10.5101 3.06888 10.4701 3.06888L9.51006 3.22888C9.07006 3.29888 8.77006 3.52888 8.66006 3.84888C8.56006 4.16888 8.68006 4.53888 8.99006 4.84888L9.73006 5.59888C9.77006 5.62888 9.80006 5.74888 9.79006 5.78888L9.58006 6.70888C9.42006 7.39888 9.68006 7.70888 9.85006 7.82888C10.0201 7.94888 10.3901 8.10888 11.0001 7.74888L11.9001 7.21888C11.9401 7.18888 12.0701 7.18888 12.1101 7.21888L13.0001 7.74888C13.2801 7.91888 13.5101 7.96888 13.6901 7.96888C13.9001 7.96888 14.0501 7.88888 14.1401 7.82888C14.3101 7.70888 14.5701 7.39888 14.4101 6.70888L14.2001 5.78888C14.1901 5.73888 14.2201 5.62888 14.2601 5.59888L15.0101 4.84888Z"></path>
            </svg>
            Rating
          </a>
          <a className="page-tabs__item active" href="#/">
            Analytics
          </a>
        </div>                
      </section>
      </>
    )
  }
}
export default PageHeader;