import { useState, useEffect, useRef } from "react";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import {
  APPROVAL_STATE_FINISHED,
  APPROVAL_STATE_STARTED,
} from "../walletGateway/useGatewayApprove";
import { PROVIDER_METAMASK } from "../../constants/ProviderTypes";

function useMetasmaskAllowance(approvalState, setApprovalState) {
  useEffect(() => {
    if (
      approvalState.state === APPROVAL_STATE_STARTED &&
      approvalState.providerType === PROVIDER_METAMASK
    ) {
      async function getAllowance() {
        const proccessResponce = await MetamaskWebProvider.approve(
          approvalState.amount,
          approvalState.tokenContractAddress,
          approvalState.accountAddress,
          approvalState.approvalAddress
        );

        setApprovalState((prevState) => ({
          ...prevState,
          isApproved: proccessResponce.isApproved,
          transaction: proccessResponce.networkResp,
          state: APPROVAL_STATE_FINISHED,
        }));
      }
      getAllowance();
    }
  }, [approvalState, setApprovalState]);
}

export default useMetasmaskAllowance;
