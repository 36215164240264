import SwapDetail from "../../components/Swap/SwapDetail";
import SwapDetailLoading from "./SwapDetailLoading";
import SwapNoRouteFound from "../../components/Swap/SwapNoRouteFound";
import {
  STEP_FORM_NO_ROUTE_FOUND,
  STEP_LOAD_QUOTA,
  STEP_READY_APPROVE,
  STEP_SWITCH_NETWORK,
} from "../../constants/TransferConstants";

export default function SwapInfoBlock({
  formData,
  transactionData,
  transactionStep,
}) {
  return (
    <>
      {transactionStep == STEP_LOAD_QUOTA && <SwapDetailLoading />}
      {transactionStep == STEP_FORM_NO_ROUTE_FOUND && <SwapNoRouteFound />}
      {transactionData.transactionId &&
        [STEP_READY_APPROVE].includes(transactionStep) && (
          <SwapDetail formData={formData} transactionData={transactionData} />
        )}
    </>
  );
}
