import {  useEffect } from "react";
import {
  NETWORK_STATE_FINISHED,
  NETWORK_STATE_STARTED,
} from "../walletGateway/useGatewayNetworkChange";
import { TrustWalletWebProvider } from "../../services/trustWallet/TrustWalletWebProvider";
import { PROVIDER_TRUST_BROWSER } from "../../constants/ProviderTypes";
import { isNull } from "../../utils/CommonUtil";

function useTrustNetwokChange(changeNetworkState, setChangeNetworkState) {
  useEffect(() => {
    if (
      changeNetworkState.state === NETWORK_STATE_STARTED &&
      isNull(changeNetworkState?.transportType) &&
      changeNetworkState.providerType === PROVIDER_TRUST_BROWSER
    ) {
      async function providerChangeNetwork() {
        let changeInfo = await TrustWalletWebProvider.changeChain(
          changeNetworkState.selectedNetwork
        );

        setChangeNetworkState((prevState) => ({
          ...prevState,
          isChanged: changeInfo.isSuccess,
          error: changeInfo.error,
          state: NETWORK_STATE_FINISHED,
        }));
      }
      providerChangeNetwork();
    }
  }, [changeNetworkState, setChangeNetworkState]);
}

export default useTrustNetwokChange;
