import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useMetasmaskNetwokChange from "../metamask/useMetasmaskNetwokChange";
import useWagmiNetworkChange from "../wagmi/useWagmiNetworkChange";
import useTrustNetwokChange from "../trust/useTrustNetwokChange";
import { getWallet } from "../../utils/WalletUtil";

export const NETWORK_STATE_INACTIVE = 0;
export const NETWORK_STATE_STARTED = 1;
export const NETWORK_STATE_FINISHED = 2;

const initNetworkChangeState = {
  selectedNetwork: null,
  state: NETWORK_STATE_INACTIVE,
  providerType: null,
  transportType: null,
  isChanged: false,
  error: null,
};

function useGatewayNetworkChange(selectedNetwork, networkChangeCallback) {
  let [changeNetworkState, setChangeNetworkState] = useState(
    initNetworkChangeState
  );

  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const walletInfo = getWallet(connectedWallets);

  useEffect(() => {
    if (changeNetworkState.state === NETWORK_STATE_FINISHED) {
      networkChangeCallback({
        isSuccess: changeNetworkState.isChanged,
        error: changeNetworkState.error,
      });
      setChangeNetworkState((prevState) => ({
        ...prevState,
        state: NETWORK_STATE_INACTIVE,
      }));
    }
  }, [changeNetworkState]);

  const handleChainChange = () => {
    setChangeNetworkState({
      selectedNetwork: selectedNetwork,
      state: NETWORK_STATE_STARTED,
      providerType: walletInfo.providerType,
      transportType: walletInfo.transportType,
    });
  };

  useMetasmaskNetwokChange(changeNetworkState, setChangeNetworkState);
  useWagmiNetworkChange(changeNetworkState, setChangeNetworkState);
  useTrustNetwokChange(changeNetworkState, setChangeNetworkState);

  return { handleChainChange };
}

export default useGatewayNetworkChange;
