import {
  PAGE_COMPLETED,
  PAGE_INIT,
  PAGE_PENDING,
} from "../../pages/SwapHistory/SwapHistory";

export default function SwapHistoryTitle({ pageType, setPageType }) {
  return (
    <>
      <div
        className="history__header"
        style={pageType == PAGE_INIT ? { justifyContent: "center" } : {}}
      >
        {pageType == PAGE_INIT && (
          <h1 className="history__title">Swap history</h1>
        )}
        {[PAGE_COMPLETED, PAGE_PENDING].includes(pageType) && (
          <>
            <a
              href="#"
              className="back-btn"
              onClick={() => setPageType(PAGE_INIT)}
            ></a>
            <h1 className="history__title">
              {pageType == PAGE_COMPLETED ? "Completed swaps" : "Pending swaps"}{" "}
            </h1>
            {/* <button className="history__remove remove-btn"></button> */}
          </>
        )}
      </div>
    </>
  );
}
