import React from 'react'
import axios from "../../app/axios";

class BridgeListSelector extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      isOpen:false,
      selectedBridges: [],
      bridges:[],
    }
  }
    
     loadBridges(){
      axios.get('bridge/list').then(response =>{
        let bridges = [];
        response.data.forEach((bridgeData) => {
          bridges.push({
            id:bridgeData.id,
            title:bridgeData.title
          })
        })
        this.setState({
          bridges:bridges
        })
      })
    }

    // const filterNetworks = getBrigeList();

     toogleBridge(id){
        if(this.state.selectedBridges.includes(id)){
          this.state.selectedBridges.splice(this.state.selectedBridges.indexOf(id),1);
        }else{
          this.state.selectedBridges.push(id);
        }
        this.setState({
          selectedBridges:this.state.selectedBridges
        })
        if(this.props.onBridgeSelected)
        this.props.onBridgeSelected(this.state.selectedBridges);
    }

    close(){
      this.setState({
        isOpen : false
      })
    }

    componentDidMount() {
      this.loadBridges();
    }    
    
    render(){
    return  <div className={this.props.wrapperClass +' ' + (this.state.isOpen ? 'active' : '')}>
        <div className="select__button" onClick={() => {
          this.setState({
            isOpen: !this.state.isOpen
          });
            }} onBlur={() => this.close()}>
          {this.state.selectedBridges.length ===0 ? 'Select a bridge' : 'Selected Bridges ('+this.state.selectedBridges.length+')'} 
        </div>
        <div className="select__dropdown">
        {this.state.bridges.map(bridge => (
          <div key={'bridge' + bridge.id} className={"select__item " + (this.state.selectedBridges.includes(bridge.id) ? ' select__item_active' : '')} onClick={() => this.toogleBridge(bridge.id)} >
          {/* <span className="select__item-logo">
            <img src={"./images/currencies-logos/"+network.logo} alt=""/>
          </span> */}
          <span className="select__item-name">{bridge.title}</span>
        </div>
      ))
        }
        </div>
        </div>
    
      }
}

export default BridgeListSelector;