import { useState, useEffect, useRef } from "react";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import {
  BALANCE_STATE_GETED,
  BALANCE_STATE_START,
} from "../walletGateway/useGatewayBalance";
import { useBalance } from "wagmi";
import {
  isZeroAddress,
  truncateDecimals,
} from "../../features/walletService/utils";
import { TRANSPORT_WAGMI } from "../../constants/ProviderTypes";

function useWagmiBalance(balanceState, setBalanceState) {
  const { data, isError, isLoading } = useBalance({
    address: balanceState.accountAddress,
    chainId: parseInt(balanceState.network?.chainId),
    enabled:
      balanceState.transportType == TRANSPORT_WAGMI &&
      balanceState.state == BALANCE_STATE_START,
    token:
      !balanceState.crypto || isZeroAddress(balanceState.crypto.contractAddress)
        ? undefined
        : balanceState.crypto.contractAddress,
    onSettled(data, error) {
      if (
        balanceState.transportType == TRANSPORT_WAGMI &&
        balanceState.state == BALANCE_STATE_START
      ) {
        setBalanceState((prevState) => ({
          ...prevState,
          error: error?.message || error,
          balance: data ? truncateDecimals(data.formatted) : 0,
          state: BALANCE_STATE_GETED,
        }));
      }
    },
  });
}

export default useWagmiBalance;
