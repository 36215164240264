import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { truncateDecimals } from "../../../../features/walletService/utils";
import {
  disconnect,
  emptyWalletInfo,
  walletDisconnectDialogToggle,
  walletInfoDialogToggle,
} from "../../../../features/walletService/walletService";
import { MetamaskWebProvider } from "../../../../services/metamask/MetamaskWebProvider";
import {
  getNetworkImage,
  getNetworkImageByChainId,
  getNetworks,
  getWalletServiceImg,
  networkListDat
} from "../../../../utils/NetworkList";
import { copyToBuffer } from "../../../../utils/TransferApiUtil";
import useWagmiConnect from "../../../../hooks/wagmi/useWagmiConnect";
import { TrustWalletWebProvider } from "../../../../services/trustWallet/TrustWalletWebProvider";
import { PROVIDER_METAMASK, PROVIDER_TRUST_BROWSER, TRANSPORT_WAGMI } from "../../../../constants/ProviderTypes";
import { WalletList } from "../../../../constants/WalletList";

function WalletInfoDialog() {
  const showWalletInfoPopup = useSelector(
    (state) => state.walletServiceProvider.showWalletInfoPopup
  );
  const connectedWallets = useSelector((state) => state.walletServiceProvider.allWallets);

  let dispatch = useDispatch();
  const networkList = getNetworks();
  const [isCopied, setCopied] = useState(false);
  const [balance, setBalance] = useState(0);

  function copyAddress() {
    copyToBuffer(walletInfo.accountAddress);
    setCopied(true);
  }

  // useEffect(() => {
  //   loadBalance();
  // }, [walletInfo.accountAddress]);

  const walletInfo = connectedWallets.length == 0 ? emptyWalletInfo : connectedWallets[0]; 

  useEffect(() => {
    loadBalance();
  }, [walletInfo]);

  const loadBalance = async () => {
    if (!walletInfo.accountAddress) return;
    let balance = 0;
    try {
      if (walletInfo.providerType == PROVIDER_METAMASK) {
        balance = truncateDecimals(
          await MetamaskWebProvider.getNativeBalance(walletInfo.accountAddress)
        );
      }
      if (walletInfo.providerType == PROVIDER_TRUST_BROWSER) {
        balance = truncateDecimals(
          await TrustWalletWebProvider.getNativeBalance(walletInfo.accountAddress)
        );
      }
    } catch (error) {}
    setBalance(balance);
  };

  function showDisconnectPopup() {
    dispatch(disconnect());
    dispatch(walletInfoDialogToggle());
    if (walletInfo.transportType == TRANSPORT_WAGMI) {
      disconnectWallet();
    }
    // dispatch(walletDisconnectDialogToggle());
  }

  function closeDialog() {
    dispatch(walletInfoDialogToggle());
    setCopied(false);
  }

  const { disconnectWallet } = useWagmiConnect();

  return (
    walletInfo.isConnected && (
      <div
        className={
          "wallet-actions-popup popup " + (showWalletInfoPopup ? "active" : "")
        }
        id="walletActionsPopup"
      >
        <div className="popup__container">
          <a
            className="popup__close"
            href="#"
            onClick={() => closeDialog()}
          ></a>
          <div className="wallet-actions-popup__logo">
            <picture>
              <img
                className="wallet-actions-popup__logo-wallet"
                src={getWalletServiceImg(walletInfo.providerType)}
                alt=""
              />
            </picture>
            <picture>
              <img
                className="wallet-actions-popup__logo-network"
                src={getNetworkImageByChainId(walletInfo.networkChainId)}
                alt=""
              />
            </picture>
          </div>

          <h1 className="wallet-actions-popup__title popup__title">
            {WalletList.find((v) => v.key == walletInfo.providerType)?.title}
          </h1>

          <div className="wallet-actions-popup__wallet-info">
            {!isCopied && walletInfo.accountAddress && (
              <div className="wallet-actions-popup__copy-field">
                <span>
                  {walletInfo.accountAddress.slice(0, 6) +
                    "..." +
                    walletInfo.accountAddress.slice(-4)}
                </span>
                <button className="copy-button" onClick={copyAddress}></button>
              </div>
            )}
            {isCopied && (
              <span className="wallet-actions-popup__copied">
                Copied
                <img src="/images/copied.svg" alt="" />
              </span>
            )}

            <div className="wallet-actions-popup__balance">
              <span>
                {balance}{" "}
                {
                  networkList.find(
                    (v) => v.chainId == walletInfo.networkChainId
                  )?.currency
                }
              </span>
              <img src="/images/wallet-icon_blue.svg" alt="" />
            </div>
          </div>

          <div className="wallet-actions-popup__actions">
            <div
              className="wallet-actions-popup__select select select_outlined"
              style={{ display: "none" }}
            >
              <div className="choose-token-popup__button select__button">
                All networks
              </div>
              <div className="select__dropdown">
                {networkList.map((network) => (
                  <a
                    className="select__item select__item_active"
                    href="#"
                    key={network.id}
                  >
                    <span className="select__item-logo">
                      <img
                        src={getNetworkImageByChainId(network.chainId)}
                        alt=""
                      />
                    </span>
                    <span className="select__item-name">{network.title}</span>
                  </a>
                ))}
              </div>
            </div>
            <button
              className="wallet-actions-popup__disconnect btn btn_middle btn_outlined-red"
              onClick={() => showDisconnectPopup()}
            >
              <img src="/images/close-circle.svg" alt=""></img>
              Disconnect
            </button>
          </div>
        </div>
      </div>
    )
  );
}
export default WalletInfoDialog;
