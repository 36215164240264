
const rankRowItem = {
    img: './images/bridge-item-logo.png',
    title: 'Binance Bridge',
    subtitle: 'Cross-chain bridge',
    popupItems: [
      {
        logo: './images/bridge-item-logo.png',
        link: '/',
        title: 'Avalanche',
      },
      {
        logo: './images/bridge-item-logo.png',
        link: '/',
        title: 'Avalanche',
      },
      {
        logo: './images/bridge-item-logo.png',
        link: '/',
        title: 'Avalanche',
      },
    ],
    avgVal: '$30 000',
    avgGrowth: '+5.42%',
    volVal: '$30 000',
    volChange: '-16.05%',
    transactionVal: '$30 000',
    transactionGrow: '+5.42%',
    limitVal: '$50—$10 000',
    limitDescription: '$10 000 per day',
    rankVal: '4.7',
    rankDesc: '337 reviews',
    rankAmount: '$50—$10 000',
  }

  export function getRankData(){
    let items = new Array(11)
    items.fill(rankRowItem)
    return items; 
  }

  const crypto_networks = [
    {
      id:'30dd6563-9de6-46ab-bbe4-b312b7b28183',
      logo:'eth.svg',
      title:'Ethereum'
    },
    {
      id:'b4a1bdc1-c9da-4639-8504-4918206950b0',
      logo:'bnb.svg',
      title:'Binance Smart Chain'
    },
    {
      id:'3a3dac36-817d-42f3-925b-19fdc3c2a324',
      logo:'polygon.svg',
      title:'Polygon'
    }
  ];

  export function getFiterNetworks(){
    return crypto_networks;
  }

  const bridgesType = [
    {
      id:1,
      title:'DEX Bridges'
    },
    {
      id:2,
      title:'Wrapped'
    },
    {
      id:3,
      title:'NFT'
    },
  ]

  export function getFiterBridges(){
    return bridgesType;
  }


  const periods = [
    // {
    //   id:'year',
    //   title:'This year'
    // },
    {
      id:'day',
      title:'24h'
    },
    {
      id:'week',
      title:'week'
    },
    {
      id:'month',
      title:'month'
    },
  ];

  export function getPeriods(){
    return periods;
  }
