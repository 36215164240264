import React from 'react'
import ReactApexChart from 'react-apexcharts';

class ReportAreaChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isDataLoaded: false,
      options: {
        chart: {
          type: 'area',
          width: '100%',
          // height: 350,
          stacked: true,
          events: {
            selection: function (chart, e) {
            }
          },
        },
        colors: ['#008FFB', '#00E396', '#CED4DC'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left'
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'dd/MM/yyyy',
          }
        },
        yaxis:{
          min: (min) => {
            let minIn = 9999999;
            if(this.props.series){
              this.props.series.forEach( val=>{
                val.data.forEach(item =>{
                  minIn = Math.min(minIn,item.y);
                })
              })
            }
            // min = Math.min(...this.props.series[0]["data"]);
            return minIn > 10000 ?  minIn-10000 :  minIn;
          }
    // min:4043590
        }
      },
    };
  }

  isDataLoaded = false;
  generateDayWiseTimeSeries(baseval, count, yrange) {
    var i = 0;
    var series = [];
    while (i < count) {
      var x = baseval;
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
  
      series.push([x, y]);
      baseval += 86400000;
      i++;
    }
    return series;
  }

  render() {
    return                  <div className="area-chart-section chart-section">
    <div className="content-tabs">
      <label className="content-tab">
        <input className="content-tab__input" type="radio" name="areaChartTab" defaultChecked={true}/>
        <span className="content-tab__button">Bridges TVL changing</span>
      </label>
      <label className="content-tab disabledBlock">
        <input className="content-tab__input" type="radio" name="areaChartTab"/>
        <span className="content-tab__button">Overall profit</span>
      </label>
      <label className="content-tab disabledBlock">
        <input className="content-tab__input" type="radio" name="areaChartTab"/>
        <span className="content-tab__button">Daily unique depositors</span>
      </label>
    </div>
    <div className="chart-section__content">
      <div className="chart-section__header">
        <div className="chart-section__title-block">
          <h2 className="chart-section__title secondary-title">Bridges TVL changing</h2>
          <button className="tooltip-btn tooltip-btn_m">?</button>
        </div>
        <div className="chart-section__container" id="areaChart">
        { this.props.tvlData.series && <ReactApexChart
              options={this.props.tvlData.options}
              series={this.props.tvlData.series}
              type="area"
              height={350}
              width={800}
            /> }
            {/* <Line options={this.options} data={this.props.bridgeData} />; */}

        </div>
        <div className="chart-section__container" id="areaChart2"></div>
        <div className="chart-section__container" id="areaChart3"></div>
      </div>
    </div>                    
  </div>


  }
}
export default ReportAreaChart
