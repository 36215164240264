import React from "react";
import {
  agreementDialogToggle,
  connectWallet,
  walletTypeDialogToggle,
} from "../../../../features/walletService/walletService";
import { useDispatch, useSelector } from "react-redux";
import { setAgreed } from "../../../../utils/BrowserUtil";
// import Cookies from 'universal-cookie';

function TermsAgreement() {
  const dispatch = useDispatch();
  let showAgreementPopup = useSelector(
    (state) => state.walletServiceProvider.showAgreementPopup
  );
  let preselectedWalletKey = useSelector(
    (state) => state.walletServiceProvider.preselectedWalletKey
  );

  const makeApprove = () =>{
    setAgreed();
    dispatch(agreementDialogToggle(true))
    // dispatch(connectWallet(preselectedWalletKey));
  }

  return (
    <div
      className={
        "agreement-popup popup " + (showAgreementPopup ? "active" : "")
      }
      id="agreementPopup"
    >
      <div className="popup__container">
        <div className="agreement-popup__header">
          <a
            className="popup__close"
            href="#"
            onClick={() => dispatch(agreementDialogToggle())}
          ></a>
          <h1 className="agreement-popup__title popup__title">
            Terms & Conditions agreement
          </h1>
        </div>
        <p className="agreement-popup__subtitle">
          By using Chainspot router, I agree to the{" "}
          <a className="text-link" href="#">
            Terms of Use
          </a>{" "}
          and{" "}
          <a className="text-link" href="#">
            Privacy Policy.
          </a>
        </p>
        <div className="agreement-popup__therms">
          <ul className="marked-list">
            <li>
              I hereby acknowledge that I am not a person or entity who resides,
              is a citizen of, is incorporated in, or has a registered office in
              the United States of America or any Prohibited Locations as
              defined in the Terms of Use. I will not access or use the
              Chainspot Router (App) in the future while in the United States
              and/or Prohibited Locations as defined in the Terms of Use.
            </li>
            <li>
              I have legal permission to access this site and use the Chainspot
              Router (App) under the laws of the jurisdiction in which I reside
              and am located. I do not and will not use VPNs to mask my physical
              location from prohibited areas.
            </li>
            <li>
              Chainspot Router is an App that operates and executes cross-chain
              swaps through third-party blockchain bridges, DEXes and Routers.
              As a high-level solution, it doesn't have its own liquidity and
              can’t take responsibility for third-party services' stability and
              security. Please use it at your own risk. I understand the
              potential risks that may arise when using Chainspot Router (App)
              network protocols.
            </li>
          </ul>
        </div>
        <button
          className="agreement-popup__btn btn btn_primary"
          onClick={() => {
            makeApprove()
          }}
        >
          Sign and proceed
        </button>
      </div>
    </div>
  );
}
export default TermsAgreement;
