import { useEffect } from "react";
import { setHash } from "../../utils/TransferApiUtil";
import {
  STEP_APPROVE_REJECTED,
  STEP_IN_TRANSFER_APPROVED,
  STEP_IN_TRANSFER_REJECTED,
  STEP_IN_TRANSFER_START,
  STEP_TRANSFER_APPROVED,
  STEP_TRANSFER_FINISHED,
} from "../../constants/TransferConstants";
import {
  TYPE_REFUSED,
  TYPE_TRANSFER,
} from "../../constants/TransactionStepConstants";
import { ethers } from "ethers";
import { isVenomRoute } from "../../utils/NetworkList";
import { makeVenomPay } from "../venom/VenomUtil";

export function useTransferApprove(
  process,
  formData,
  setTransactionStep,
  handleTransactionProcess,
  tvmWallet,
  venomProvider,
  setErrorMsg
) {

  const afterTransferApproved = async (transactionId, txHash, wait = null) => {
    // showStepPopup(TYPE_TRANSFER);
    handleTransactionProcess(STEP_TRANSFER_APPROVED, txHash);
    setHash(transactionId, txHash);

    await mintTransaction(txHash);
    if (!process.isInNetworkApproveRequired) {
      handleTransactionProcess(STEP_TRANSFER_FINISHED);
    } else {
      callInNetworkApprove();
    }
  };

  const mintTransaction = async (txHash) => {
    if (!formData.routeFrom) return;
    const Provider2 = new ethers.providers.JsonRpcProvider(
      formData.routeFrom.rpcUrls[0]
    );
    let mint = await Provider2.waitForTransaction(txHash);
  };

  const callInNetworkApprove = async () => {
    if (isVenomRoute(formData)) {
      setTransactionStep(STEP_IN_TRANSFER_START);
      handleTransactionProcess(STEP_IN_TRANSFER_START);

      let resp = await makeVenomPay(
        process.transfetTx,
        tvmWallet.accountAddress,
        formData.routeFrom.chainId,
        venomProvider
      );
      if (resp.hasError) {
        setTransactionStep(STEP_IN_TRANSFER_REJECTED);
        handleTransactionProcess(STEP_IN_TRANSFER_REJECTED);
        setErrorMsg(resp.errorText);
      } else {
        setTransactionStep(STEP_IN_TRANSFER_APPROVED);
        handleTransactionProcess(STEP_IN_TRANSFER_APPROVED);
      }
    }
  };

  return { afterTransferApproved, callInNetworkApprove };
}
