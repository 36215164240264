import React from 'react'

class ReportWidgets extends React.Component {
  render() {
    return                         <div className="grid-block grid-block_2-cols bottom-widgets">
    <div className="widget widget-l disabledBlock">
      <div className="widget-l__header">
        <div className="widget__icon">
          <img src="./images/transactions-overall-icon.svg" alt=""/>
        </div>              
        <h2 className="widget-l__title secondary-title">
          Transactional<br/>addresses overall
          <button className="tooltip-btn tooltip-btn_m">?</button>
        </h2>                
        <div className="widget__period select select_s">
          <div className="select__button">
            Period: This year
          </div>
          <div className="select__dropdown"></div>
        </div>
      </div>
      <div className="widget-l__value-block widget__value-block">
        <span className="widget-l__value widget__value">$5,00,874</span>
        <span className="widget__increment widget__increment_up">1080</span>
      </div>
      <span className="widget__caption">2,345,567 unique</span>
    </div>
    <div className="widget widget-l disabledBlock">
      <div className="widget-l__header">
        <div className="widget__icon">
          <img src="./images/total-btc-icon.svg" alt=""/>
        </div>
        <h2 className="widget-l__title secondary-title">
          Total BTC
          <button className="tooltip-btn tooltip-btn_m">?</button>
        </h2>
        <div className="widget__period select select_s">
          <div className="select__button">
            Period: This year
          </div>
          <div className="select__dropdown"></div>
        </div>
      </div>
      <div className="widget-l__value-block widget__value-block">
        <span className="widget-l__value widget__value">232,867 BTC</span>
        <span className="widget__increment widget__increment_up">980</span>
      </div>
      <span className="widget__caption">$6,986,010,000</span>
    </div>
  </div>
}
}
export default ReportWidgets
