import { useEffect } from "react";
import {
  NETWORK_STATE_FINISHED,
  NETWORK_STATE_STARTED,
} from "../walletGateway/useGatewayNetworkChange";
import { switchNetwork } from "@wagmi/core";
import { ethers } from "ethers";
// import { useSwitchNetwork } from "wagmi";
import { disconnect, connect } from "@wagmi/core";
import { WalletConnectConfig } from "../../services/wagmi/config";
import { TRANSPORT_WAGMI } from "../../constants/ProviderTypes";

function useWagmiNetworkChange(changeNetworkState, setChangeNetworkState) {
  // const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()
  // console.log(chains,error,'chains')
  useEffect(() => {
    if (
      changeNetworkState.state === NETWORK_STATE_STARTED &&
      changeNetworkState?.transportType === TRANSPORT_WAGMI
      // changeNetworkState.providerType === PROVIDER_WALLET_CONNECT
    ) {
      let changeNetworkHandle = async () => {
        let errorObj = null;
        await disconnect();

        try {
        const result = await connect({
          chainId: parseInt(changeNetworkState.selectedNetwork.chainId), //optimism.id,
          connector: WalletConnectConfig,
        });
        // const network = await switchNetwork({
        //   chainId: 56, //parseInt(changeNetworkState.selectedNetwork.chainId),
        // });
        } catch (error) {
          console.error(error,'error')
          errorObj = error;
        }
        setChangeNetworkState((prevState) => ({
          ...prevState,
          isChanged: errorObj === null,
          error: errorObj?.message || errorObj,
          state: NETWORK_STATE_FINISHED,
        }));
      };
      changeNetworkHandle();
    }
  }, [changeNetworkState, setChangeNetworkState]);
}

export default useWagmiNetworkChange;
