import React from 'react'
import PageHeader from '../../layouts/page/PageHeader'
import ReportAreaChart from './ReportAreaChart'
import ReportDonutChartSection from './ReportDonutChartSection'
import ReportRankingTable from './ReportRankingTable'
import ReportsOverview from './ReportsOverview'
import ReportWidgets from './ReportWidgets'
import Axios from '../../app/axios';

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {totalInfo: {},graphicData:{},filterItem:{
        networks: [],
        periodType: "day",
        bridges: []
      },
      bridgeGraphicData:[],
      marketShares:{},
      tokensTable:[]
    };
    // this.loadData();
  }

  componentDidMount() {
    this.loadData();
  }

  loadData(){
    Axios.get('statistic/totals',{
      params: this.state.filterItem
    })
      .then(res => {
        this.setState({
          totalInfo: res.data
        });
      })

    Axios.get('statistic/graphics',{
      params: this.state.filterItem
    })
      .then(res => {
        this.setState({
          graphicData: res.data
        },this.setGraphicData);
      })
  
  }

  onFilterChangeHandle(name,val){
    this.setState(prevState => ({
      filterItem: {                   // object that we want to update
          ...prevState.filterItem,    // keep all other key-value pairs
          [name]: val       // update the value of specific key
      }
    }),this.loadData)
  }

  setGraphicData(){
    this.setMarketShare();
    this.setBridgeTvlChange();

    // let data = [...]
    let data = this.state.graphicData.cryptoTable.sort(function(a, b){
      return parseFloat(b.tvlInUsd) - parseFloat(a.tvlInUsd)
    });

    
    this.setState({
        tokensTable:data
    })
  }

  colors = [];
  
  setMarketShare(){
    let marketShareItem = {
      series:[],
      options:{
        chart: {
          type: 'donut',
        },
        labels:[],
        legend: {
          position: 'bottom'
        },
        colors:[],
        // responsive: [{
        //   breakpoint: 480,
        //   options: {
        //     chart: {
        //       width: 200
        //     },
        //   }
        // }]
      }
    }

    if(this.state.graphicData && this.state.graphicData.bridgeMarketShares){
      let data = [];
      Object.values(this.state.graphicData.bridgeMarketShares).forEach((bridgeData) => {
        if(parseFloat(bridgeData.data[0]) !== 0){
          data.push({
            val:parseFloat(bridgeData.data[0]),
            title:bridgeData.name
          })
        }
      })
      data.sort((a,b) => {
        return a.val > b.val ? -1 : ( (a.val < b.val) ? 1 : 0  )
      })
      this.colors = [];
      data.forEach( v=>{
          marketShareItem.series.push(v.val)
          marketShareItem.options.labels.push(v.title);
          this.colors.push(this.getMyColor())
      })
      marketShareItem.options.colors = [...this.colors];     

    }
    this.setState({
      marketShares:marketShareItem
    })

  }

  getMyColor(colors){
    let color ;
    for (let index = 0; index < 50; index++) {
      let n = (Math.random() * 0xfffff * 1000000).toString(16);
      color = '#' + n.slice(0, 6);
      if(!this.colors.includes(color)){
        break;
      }
    }
    return color;
  };

  setBridgeTvlChange(){
    let series = [];
    let options =  {
      chart: {
        type: 'area',
        width: '100%',
        // height: 350,
        stacked: true,
        events: {
          selection: function (chart, e) {
          }
        },
      },
      colors: [],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8,
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left'
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'dd/MM/yyyy',
        }
      },
      yaxis:{
      }
    };

    if(this.state.graphicData && this.state.graphicData.brdigeTvlChangeGraphic && this.state.graphicData.brdigeTvlChangeGraphic.points ){
      let graphicData = this.state.graphicData.brdigeTvlChangeGraphic
      this.bridgeData.labels = [...graphicData.days];
      this.bridgeData.datasets = [];
      Object.values(graphicData.points).forEach((bridge,indexMain) => {
        // if(index > 0)
        //   return;
        let total = 0;
        let data = [];
        bridge.data.forEach((val,index)=>{
          data.push({
            x:new Date(graphicData.days[index]).getTime(),
            y:val
          })
          total += parseFloat(val);
        })
        if(total > 0 ){
        series.push({
          name:bridge.name,
          data:data
        })
        this.bridgeData.datasets.push({
          label:bridge.name,
          data:bridge.data,
          // borderColor: 'rgb(53, 162, 235)',
          // backgroundColor: 'rgba(53, 162, 235, 0.5)',
          fill: true
        })
        return;
        }
      });
      options.colors = [...this.colors];
      this.setState({
        bridgeGraphicData:{series:series,options:options}
      })
    }
  }

  bridgeData = {
    labels:[],
    datasets:[]
  };

  render() {
    return <main className="main">
      <PageHeader title="Reports"/>
      <section className='page-container page-container_vertical'>
        <ReportsOverview totalInfo={this.state.totalInfo} filterItem={this.state.filterItem} onFilterChange={(name,val) => this.onFilterChangeHandle(name,val)} />
        <ReportAreaChart tvlData={this.state.bridgeGraphicData} bridgeData={this.bridgeData} />
        <ReportDonutChartSection donutData={this.state.marketShares}/>
        <ReportWidgets/>
        <ReportRankingTable tableData={this.state.tokensTable}/>
      </section>
    </main>
  }
}
export default Reports

