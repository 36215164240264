import React, { useRef } from "react";
import {
  PROVIDER_METAMASK,
  connectWalletPopupToggle,
  walletInfoDialogToggle,
  // walletTypeDialogToggle,
} from "../../../../features/walletService/walletService";
import { useDispatch, useSelector } from "react-redux";
import { getNetworkImageByChainId } from "../../../../utils/NetworkList";
import { WalletList } from "../../../../constants/WalletList";
import { ConnectWalletPopup } from "../ConnectWalletPopup/ConnectWalletPopup";
import { useMouseClickOutside } from "../../../../hooks/common/useMouseClickOutside";
import { cutAddressFormat } from "../../../../utils/WalletUtil";

function WallectConnectButtonBlock() {
  let dispatch = useDispatch();

  const openWalletList = (e) => {
    // e.stopPropagation();
    dispatch(connectWalletPopupToggle());
  };
  return (
    <button
      className="btn btn_dropdown btn_middle select-button"
      onClick={(e) => openWalletList(e)}
    >
      Connect wallets
      <img src="/img/dropdown-arrow_white.svg" alt="" />
    </button>
  );
}

function WallectConnectedButtonBlock() {
  let dispatch = useDispatch();
  let wallets = useSelector((state) => state.walletServiceProvider.allWallets);

  const walletInfo = wallets[0];

  let showConnectWalletPopup = useSelector(
    (state) => state.walletServiceProvider.showConnectWalletPopup
  );
  const openWalletList = (e) => {
    // e.preventDefault();
    dispatch(connectWalletPopupToggle());
  };

  // const showConnectPopup = () => {
  //   dispatch(connectWalletPopupToggle());
  // };

  if (wallets.length == 0) {
    return;
  }

  if (wallets.length == 1) {
    return (
      <>
        {" "}
        <button
          className={
            "wallet-info-button select-button " +
            (showConnectWalletPopup ? "active" : "")
          }
          onClick={(e) => openWalletList(e)}
        >
          <div className="wallet-info-button__logo tooltip-cnt">
            <img
              className="wallet-info-button__main-logo"
              src={
                WalletList.find((v) => v.key == walletInfo.providerType)
                  ?.imgForMenu
              }
              alt=""
            />
            <img
              className="wallet-info-button__network-logo"
              src={getNetworkImageByChainId(walletInfo.networkChainId)}
              alt=""
            />
            <span className="tooltip-popup tooltip-popup_num tooltip-popup_num-small">
              {cutAddressFormat(walletInfo.accountAddress)}
            </span>
          </div>
          <div className="wallet-info-button__wallet-num">
            <div>
              <span className="wallet-info-button__num">
                {cutAddressFormat(walletInfo.accountAddress)}
              </span>
            </div>
          </div>
          <img className="wallet-info-button__arrow" src="/images/select-button-arrow_dark.svg" alt=""/>
        </button>
      </>
    );
  } else {
    return (
      <button
        className={
          "wallet-info-button wallet-info-button_multiple select-button " +
          (showConnectWalletPopup ? "active" : "")
        }
        onClick={(e) => openWalletList(e)}
      >
        {wallets.map((walletInfo, key) => (
          <div
            className="wallet-info-button__logo tooltip-cnt"
            key={walletInfo.providerType}
          >
            <img
              className="wallet-info-button__main-logo"
              src={
                WalletList.find((v) => v.key == walletInfo.providerType)
                  ?.imgForMenu
              }
              alt=""
            />
            <img
              className="wallet-info-button__network-logo"
              src={getNetworkImageByChainId(walletInfo.networkChainId)}
              alt=""
            />
            <span className="tooltip-popup tooltip-popup_num tooltip-popup_num-small">
              {cutAddressFormat(walletInfo.accountAddress)}
            </span>
          </div>
        ))}
        <img
          className="wallet-info-button__arrow"
          src="/images/select-button-arrow_dark.svg"
          alt=""
        />
      </button>
    );
  }
}

function WalletConnectButton() {
  let dispatch = useDispatch();
  let wallets = useSelector((state) => state.walletServiceProvider.allWallets);
  const wrapperRef = useRef(null);
  let showConnectWalletPopup = useSelector(
    (state) => state.walletServiceProvider.showConnectWalletPopup
  );

  const onClickOutsideHandle = () => {
    dispatch(connectWalletPopupToggle());
  };

  useMouseClickOutside(
    wrapperRef,
    showConnectWalletPopup,
    onClickOutsideHandle
  );

  return (
    <div className="header__cta" ref={wrapperRef}>
      {wallets.length == 0 ? (
        <WallectConnectButtonBlock />
      ) : (
        <WallectConnectedButtonBlock />
      )}
      <ConnectWalletPopup />
    </div>
  );
}
export default WalletConnectButton;
