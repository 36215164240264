import { useEffect, useRef } from "react";
import {
  accountChanged,
  autoConnectWallet,
  connectWallet,
  disconnect,
  networkChanged,
} from "../../features/walletService/walletService";
import { useDispatch, useSelector } from "react-redux";
import { localStorageObj } from "../../features/localstorage";
import { TrustWalletWebProvider } from "../../services/trustWallet/TrustWalletWebProvider";
import { PROVIDER_TRUST_BROWSER } from "../../constants/ProviderTypes";
import { getWalletByProviderType, hasOldConnectionWallet } from "../../utils/WalletUtil";
import { CHAIN_TYPE_EVM } from "../../constants/ChainTypes";

function useTrustAccount() {
  let dispatch = useDispatch();
  // dispatch(connectWallet())
  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const walletInfo = getWalletByProviderType(
    connectedWallets,
    PROVIDER_TRUST_BROWSER,
    true
  );

  const walletData = useRef();
  useEffect(() => {
    walletData.current = walletInfo;
  }, [walletInfo]);

  useEffect(() => {
    const listenTrust = async () => {
      let injectedProvider =
        await TrustWalletWebProvider.getTrustWalletInjectedProvider();
      if (injectedProvider && injectedProvider.addListener) {
        injectedProvider.addListener("chainChanged", (_chainId) => {
          dispatch(
            networkChanged({
              networkType: CHAIN_TYPE_EVM,
              networkChainId: parseInt(_chainId,16),
            })
          );
            // dispatch(networkChanged(parseInt(_chainId)));
        });
        injectedProvider.addListener("accountsChanged", (accounts) => {
          if (hasOldConnectionWallet(PROVIDER_TRUST_BROWSER) && accounts.length > 0 && !walletInfo) {
            dispatch(connectWallet(PROVIDER_TRUST_BROWSER));
          }
  
          if (
            walletData.current.providerType == PROVIDER_TRUST_BROWSER &&
            walletData.current.isConnected
          ) {
            if (accounts.length == 0) {
              dispatch(disconnect());
            }
            if (typeof accounts == "object" && accounts.length > 0) {
              dispatch(accountChanged(accounts[0]));
            }
          }
          if (!walletData.current.isConnected && accounts.length > 0) {
            dispatch(connectWallet(PROVIDER_TRUST_BROWSER));
          }
        });

        // window.ethereum.on('disconnect', () => {
        //   window.location.reload();
        // })
      }
    };
    listenTrust();

    const transportType = localStorageObj.get("transportType");
    const providerType = localStorageObj.get("providerType");
    if (hasOldConnectionWallet(PROVIDER_TRUST_BROWSER)) {
    //   if (
    //   (transportType === null || transportType === "") &&
    //   providerType === PROVIDER_TRUST_BROWSER
    // ) {
      dispatch(autoConnectWallet());
    }
  }, []);
}

export default useTrustAccount;
