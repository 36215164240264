export const TokenTransferEthEvent = {
  "ABI version": 2,
  version: "2.2",
  header: ["pubkey", "time", "expire"],
  functions: [
    {
      name: "constructor",
      inputs: [
        { name: "_initializer", type: "address" },
        { name: "_meta", type: "cell" },
      ],
      outputs: [],
    },
    {
      name: "getDetails",
      inputs: [{ name: "answerId", type: "uint32" }],
      outputs: [
        {
          components: [
            {
              components: [
                { name: "eventTransaction", type: "uint256" },
                { name: "eventIndex", type: "uint32" },
                { name: "eventData", type: "cell" },
                { name: "eventBlockNumber", type: "uint32" },
                { name: "eventBlock", type: "uint256" },
              ],
              name: "voteData",
              type: "tuple",
            },
            { name: "configuration", type: "address" },
            { name: "staking", type: "address" },
            { name: "chainId", type: "uint32" },
          ],
          name: "_eventInitData",
          type: "tuple",
        },
        { name: "_status", type: "uint8" },
        { name: "_confirms", type: "uint256[]" },
        { name: "_rejects", type: "uint256[]" },
        { name: "empty", type: "uint256[]" },
        { name: "balance", type: "uint128" },
        { name: "_initializer", type: "address" },
        { name: "_meta", type: "cell" },
        { name: "_requiredVotes", type: "uint32" },
      ],
    },
    {
      name: "getDecodedData",
      inputs: [{ name: "answerId", type: "uint32" }],
      outputs: [
        { name: "tokens", type: "uint128" },
        { name: "wid", type: "int8" },
        { name: "owner_addr", type: "uint256" },
        { name: "owner_address", type: "address" },
      ],
    },
    {
      name: "getEventInitData",
      inputs: [{ name: "answerId", type: "uint32" }],
      outputs: [
        {
          components: [
            {
              components: [
                { name: "eventTransaction", type: "uint256" },
                { name: "eventIndex", type: "uint32" },
                { name: "eventData", type: "cell" },
                { name: "eventBlockNumber", type: "uint32" },
                { name: "eventBlock", type: "uint256" },
              ],
              name: "voteData",
              type: "tuple",
            },
            { name: "configuration", type: "address" },
            { name: "staking", type: "address" },
            { name: "chainId", type: "uint32" },
          ],
          name: "value0",
          type: "tuple",
        },
      ],
    },
    {
      name: "confirm",
      inputs: [{ name: "voteReceiver", type: "address" }],
      outputs: [],
    },
    {
      name: "reject",
      inputs: [{ name: "voteReceiver", type: "address" }],
      outputs: [],
    },
    {
      name: "receiveRoundAddress",
      inputs: [
        { name: "roundContract", type: "address" },
        { name: "roundNum", type: "uint32" },
      ],
      outputs: [],
    },
    {
      name: "receiveRoundRelays",
      inputs: [{ name: "keys", type: "uint256[]" }],
      outputs: [],
    },
    {
      name: "getVoters",
      inputs: [
        { name: "answerId", type: "uint32" },
        { name: "vote", type: "uint8" },
      ],
      outputs: [{ name: "voters", type: "uint256[]" }],
    },
    {
      name: "getVote",
      inputs: [
        { name: "answerId", type: "uint32" },
        { name: "voter", type: "uint256" },
      ],
      outputs: [{ name: "vote", type: "optional(uint8)" }],
    },
    {
      name: "getApiVersion",
      inputs: [{ name: "answerId", type: "uint32" }],
      outputs: [{ name: "value0", type: "uint32" }],
    },
    {
      name: "encodeEthereumEventData",
      inputs: [
        { name: "tokens", type: "uint256" },
        { name: "wid", type: "int128" },
        { name: "owner_addr", type: "uint256" },
      ],
      outputs: [{ name: "data", type: "cell" }],
    },
    {
      name: "decodeEthereumEventData",
      inputs: [{ name: "data", type: "cell" }],
      outputs: [
        { name: "tokens", type: "uint128" },
        { name: "wid", type: "int8" },
        { name: "owner_addr", type: "uint256" },
      ],
    },
    {
      name: "encodeTonEventData",
      inputs: [
        { name: "wid", type: "int8" },
        { name: "addr", type: "uint256" },
        { name: "tokens", type: "uint128" },
        { name: "ethereum_address", type: "uint160" },
        { name: "chainId", type: "uint32" },
      ],
      outputs: [{ name: "data", type: "cell" }],
    },
    {
      name: "decodeTonEventData",
      inputs: [{ name: "data", type: "cell" }],
      outputs: [
        { name: "wid", type: "int8" },
        { name: "addr", type: "uint256" },
        { name: "tokens", type: "uint128" },
        { name: "ethereum_address", type: "uint160" },
        { name: "chainId", type: "uint32" },
      ],
    },
    {
      name: "status",
      inputs: [],
      outputs: [{ name: "status", type: "uint8" }],
    },
    {
      name: "votes",
      inputs: [],
      outputs: [{ name: "votes", type: "map(uint256,uint8)" }],
    },
    {
      name: "initializer",
      inputs: [],
      outputs: [{ name: "initializer", type: "address" }],
    },
    {
      name: "meta",
      inputs: [],
      outputs: [{ name: "meta", type: "cell" }],
    },
    {
      name: "requiredVotes",
      inputs: [],
      outputs: [{ name: "requiredVotes", type: "uint32" }],
    },
    {
      name: "confirms",
      inputs: [],
      outputs: [{ name: "confirms", type: "uint16" }],
    },
    {
      name: "rejects",
      inputs: [],
      outputs: [{ name: "rejects", type: "uint16" }],
    },
    {
      name: "relay_round",
      inputs: [],
      outputs: [{ name: "relay_round", type: "address" }],
    },
    {
      name: "round_number",
      inputs: [],
      outputs: [{ name: "round_number", type: "uint32" }],
    },
  ],
  data: [
    {
      components: [
        {
          components: [
            { name: "eventTransaction", type: "uint256" },
            { name: "eventIndex", type: "uint32" },
            { name: "eventData", type: "cell" },
            { name: "eventBlockNumber", type: "uint32" },
            { name: "eventBlock", type: "uint256" },
          ],
          name: "voteData",
          type: "tuple",
        },
        { name: "configuration", type: "address" },
        { name: "staking", type: "address" },
        { name: "chainId", type: "uint32" },
      ],
      key: 1,
      name: "eventInitData",
      type: "tuple",
    },
  ],
  events: [
    {
      name: "Confirm",
      inputs: [{ name: "relay", type: "uint256" }],
      outputs: [],
    },
    {
      name: "Reject",
      inputs: [{ name: "relay", type: "uint256" }],
      outputs: [],
    },
    {
      name: "Closed",
      inputs: [],
      outputs: [],
    },
  ],
  fields: [
    { name: "_pubkey", type: "uint256" },
    { name: "_constructorFlag", type: "bool" },
    { name: "status", type: "uint8" },
    { name: "votes", type: "map(uint256,uint8)" },
    { name: "initializer", type: "address" },
    { name: "meta", type: "cell" },
    { name: "requiredVotes", type: "uint32" },
    { name: "confirms", type: "uint16" },
    { name: "rejects", type: "uint16" },
    { name: "relay_round", type: "address" },
    { name: "round_number", type: "uint32" },
    {
      components: [
        {
          components: [
            { name: "eventTransaction", type: "uint256" },
            { name: "eventIndex", type: "uint32" },
            { name: "eventData", type: "cell" },
            { name: "eventBlockNumber", type: "uint32" },
            { name: "eventBlock", type: "uint256" },
          ],
          name: "voteData",
          type: "tuple",
        },
        { name: "configuration", type: "address" },
        { name: "staking", type: "address" },
        { name: "chainId", type: "uint32" },
      ],
      name: "eventInitData",
      type: "tuple",
    },
  ],
};
