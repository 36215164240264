import { useDispatch, useSelector } from "react-redux";
import { toggle } from "../../features/analytic/analyticSlice";
import BridgeSelector from "../BridgeSelector/BridgeSelector";
import NetworkDropdown from "../NetworkDropdown/NetworkDropdown";

function FilterMobilePanel(props){
    const isShowMenu = useSelector((state) => state.analyticShowMenu.value)
    const dispatch = useDispatch()
    return (
        <div className={"filters-mobile-panel " + (isShowMenu ? 'active' : '')}>
        <div className="filters-mobile-panel__header">
          <button className="filters-mobile-panel__back-btn back-btn" onClick={()=> dispatch(toggle())}>Back</button>
          <h1 className="filters-mobile-panel__title page-title">Filters</h1>
          <button className="filters-mobile-panel__reset-btn">Reset</button>
        </div>
        <div className="filters-mobile-panel__content">
          <NetworkDropdown wrapperclassName="select select_outlined"></NetworkDropdown>
          <div className="filters-mobile-panel__input-block">
            <span className="input-label">Type of the bridge</span>
            <BridgeSelector wrapClassname="filter-switch_blue"/>
          </div>
          <div className="filters-mobile-panel__input-block">
            <span className="input-label">Bridges</span>
            <div className="select select_outlined">
              <div className="select__button">
                All
              </div>
              <div className="select__dropdown">
                <a className="select__item select__item_active" href="#">
                  <span className="select__item-logo">
                    <img src="./images/currencies-logos/eth.svg" alt=""/>
                  </span>
                  <span className="select__item-name">Etherium</span>
                </a>
                <a className="select__item" href="#">
                  <span className="select__item-logo">
                    <img src="./images/currencies-logos/bnb.svg" alt=""/>
                  </span>
                  <span className="select__item-name">Binance Smart Chain</span>
                </a>
                <a className="select__item" href="#">
                  <span className="select__item-logo">
                    <img src="./images/currencies-logos/polygon.svg" alt=""/>
                  </span>
                  <span className="select__item-name">Polygon</span>
                </a>
              </div>
            </div>
          </div>
          <div className="filters-mobile-panel__input-block">
            <span className="input-label">Time</span>
            <div className="select select_outlined">
              <div className="select__button select-date">
                Last 7 days
              </div>          
            </div>
          </div>
        </div>    
      </div>
    
    );
}
export default FilterMobilePanel
