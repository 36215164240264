import { useState, useEffect, useRef } from "react";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import { NETWORK_STATE_FINISHED, NETWORK_STATE_STARTED } from "../walletGateway/useGatewayNetworkChange";
import { PROVIDER_METAMASK } from "../../constants/ProviderTypes";
import { isNull } from "../../utils/CommonUtil";

function useMetasmaskNetwokChange(changeNetworkState, setChangeNetworkState) {
  useEffect(() => {
    if (
      changeNetworkState.state === NETWORK_STATE_STARTED &&
      isNull(changeNetworkState?.transportType) &&
      changeNetworkState.providerType === PROVIDER_METAMASK
    ) {
      async function getAllowance() {
        let changeInfo  = await MetamaskWebProvider.changeChain(
          changeNetworkState.selectedNetwork
        );
        
        setChangeNetworkState((prevState) => ({
          ...prevState,
          isChanged: changeInfo.isSuccess,
          error: changeInfo.error,
          state: NETWORK_STATE_FINISHED,
        }));
      }
      getAllowance();
    }
  }, [changeNetworkState, setChangeNetworkState]);
}

export default useMetasmaskNetwokChange;
