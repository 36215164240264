import { useState } from "react";
import { getFiterNetworks } from "../../data/ranking";

function NetworkDropdown(props){
    const [isOpen, toogleDropdown] = useState(false);
    const [selectedNetworks, toogleNetworkState] = useState([]);
    
    const filterNetworks = getFiterNetworks();

    function toogleNetwork(id){
        if(selectedNetworks.includes(id)){
            selectedNetworks.splice(selectedNetworks.indexOf(id),1);
        }else{
            selectedNetworks.push(id);
        }
        toogleNetworkState([...selectedNetworks]);
        if(props.onNetworkSelected)
        props.onNetworkSelected(selectedNetworks);
    }

    function close(){
      toogleDropdown(!isOpen)
    }
    
    return ( <div className={props.wrapperClass +' ' + (isOpen ? 'active' : '')}>
        <div className="select__button" onClick={() => {
            toogleDropdown(!isOpen)
            }} onBlur={close}>
          {selectedNetworks.length ===0 ? 'Select a network' : filterNetworks.reduce((prev,curr) => 
             prev  + ( selectedNetworks.includes(curr.id) ? curr.title + ', ' : '' ) 
            ,'' )} 
        </div>
        <div className="select__dropdown">
        {filterNetworks.map((network, index) => (
          <div key={'network_d' + index} className={"select__item " + (selectedNetworks.includes(network.id) ? ' select__item_active' : '')} onClick={() => toogleNetwork(network.id)} >
          <span className="select__item-logo">
            <img src={"/images/currencies-logos/"+network.logo} alt=""/>
          </span>
          <span className="select__item-name">{network.title}</span>
        </div>
      ))
        }
        </div>
        </div>
    );
}

export default NetworkDropdown;