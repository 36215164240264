import { BigNumber, ethers } from "ethers";
import { ERC20Abi } from "../abis/ERC20abi";
import { PROXY_CONTRACT_ADDRESS } from "../constants/CustomContracts";
import { ProxyAbi } from "../abis/ProxyAbi";

export const proxyCall = async (
  provider,
  tokenAddress,
  senderAddress,
  reciverAddress,
  transferAmount,
  tokenDecimals,
  transactionData
) => {
  const signer = provider.getSigner();

  let ERC20Token = new ethers.Contract(tokenAddress, ERC20Abi.abi, signer);

  const allowance = await ERC20Token.allowance(
    senderAddress,
    PROXY_CONTRACT_ADDRESS
  );

  if (allowance.lt(transferAmount)) {
    // Approving the MultiVault contract
    await ERC20Token.approve(
      PROXY_CONTRACT_ADDRESS,
      ethers.utils.parseUnits(transferAmount, tokenDecimals).toString()
    );
  }

  let proxyContract = new ethers.Contract(
    PROXY_CONTRACT_ADDRESS,
    ProxyAbi,
    signer
  );
  const minProxyValue = await proxyContract.minProxyValue();
  const totalValue = BigNumber.from(transactionData.value).add(minProxyValue);
  const hash = await proxyContract.proxy(transactionData.data, {
    value: totalValue.toHexString(),
  });
};

export const getProxyCallData = async (provider, transactionData) => {
  const signer = provider.getSigner();

  let proxyContract = new ethers.Contract(
    PROXY_CONTRACT_ADDRESS,
    ProxyAbi,
    signer
  );
  const minProxyValue = await proxyContract.minProxyValue();
  const totalValue = BigNumber.from(transactionData.value).add(minProxyValue);

  let iface = new ethers.utils.Interface(ProxyAbi);
  let data = iface.encodeFunctionData("proxy", [transactionData.data]);

  return { data: data, value: totalValue.toHexString() };
};
