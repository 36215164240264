import { useState, useEffect, useRef } from "react";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import {
  BALANCE_STATE_GETED,
  BALANCE_STATE_START,
} from "../walletGateway/useGatewayBalance";
import { TrustWalletWebProvider } from "../../services/trustWallet/TrustWalletWebProvider";
import { PROVIDER_TRUST_BROWSER } from "../../constants/ProviderTypes";

function useTrustBalance(balanceState, setBalanceState) {
  useEffect(() => {
    const getTrustBalance = async () => {
      const balance =
        await TrustWalletWebProvider.getTokenBalanceByContractAddress(
          balanceState.network,
          balanceState.crypto,
          balanceState.accountAddress,
          balanceState.curChainId
        );
      // console.log(balanceState.network,balanceState.crypto,balanceState.accountAddress,balance, "balance trust");
      setBalanceState((prevState) => ({
        ...prevState,
        error: balance.hasError ? balance.error : null,
        balance: balance.hasError ? 0 : balance.balance,
        state: BALANCE_STATE_GETED,
      }));
    };
    if (
      balanceState.providerType == PROVIDER_TRUST_BROWSER &&
      balanceState.state == BALANCE_STATE_START
    ) {
      getTrustBalance();
    }
  }, [balanceState, setBalanceState]);
}

export default useTrustBalance;
