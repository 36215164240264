import { ethers } from "ethers";
import { ClaimAbi } from "../abis/ClaimAbi";

export const claimToken = async (provider, contractAddress) => {
  const signer = provider.getSigner();
  let contract = await new ethers.Contract(contractAddress, ClaimAbi, signer);
  const claimPrice = await contract.claimPrice();
  const claimAmount = await contract.claimAmount();

  const options = { value: claimPrice.toHexString() };
  const reciept = await contract.claim(options);
};

export const claimTokenEncodeData = async (provider, contractAddress) => {
  const signer = provider.getSigner();
  let contract = await new ethers.Contract(contractAddress, ClaimAbi, signer);
  const claimPrice = await contract.claimPrice();
  const claimAmount = await contract.claimAmount();

  let iface = new ethers.utils.Interface(ClaimAbi);
  let data = iface.encodeFunctionData("claim");
  return { data: data, value: claimPrice.toHexString() };
};
