import { MAX_COMPLETED_COUNT } from "../../constants/TransferConstants";
import { PAGE_COMPLETED } from "../../pages/SwapHistory/SwapHistory";
import HistoryTransferItem from "./HistoryTransferItem";
import { PAGE_INIT } from "./SwapHistoryList";

export default function CompletedTransfers({
  completedTransfers,
  completedPageInfo,
  pageType,
  setPageType,
  gotoPrev,
  gotoNext,
}) {
  return (
    <section className="history__section">
      {pageType == PAGE_INIT && (
        <div className="history__section-header">
          <h2 className="history__section-title">Completed</h2>
          {pageType == PAGE_INIT &&
            completedPageInfo.totalCount > MAX_COMPLETED_COUNT && (
              <a
                onClick={() => setPageType(PAGE_COMPLETED)}
                className="show-all-btn"
              >
                Show all
              </a>
            )}
        </div>
      )}
      <div className="history__items">
        {completedTransfers.map((transfer) => (
          <HistoryTransferItem
            key={transfer.id}
            transfer={transfer}
          ></HistoryTransferItem>
        ))}
      </div>
      {pageType == PAGE_COMPLETED && completedPageInfo && (
        <div className="page-block__footer">
          <div className="pagination">
            <span>Page</span>
            <input
              className="pagination__input"
              type="text"
              value={completedPageInfo.page}
              onChange={() => {}}
            />
            <span>of {completedPageInfo.pageCount}</span>
            <div className="pagination__buttons">
              <button
                className="pagination__button pagination__button_prev"
                disabled={completedPageInfo.page == 1}
                onClick={gotoPrev}
              ></button>
              <button
                className="pagination__button pagination__button_next"
                disabled={completedPageInfo.page == completedPageInfo.pageCount}
                onClick={gotoNext}
              ></button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
