import { BTN_KEY_TRANSFER_RETRY } from "../../constants/SwapButtonConstants";
import {
  STEP_TRANSFER_APPROVED,
  STEP_TRANSFER_FINISHED,
  STEP_TRANSFER_REJECTED,
  STEP_TRANSFER_START,
} from "../../constants/TransferConstants";
import { getTxScanUrl } from "../../utils/NetworkList";
import SwapSuccessLinks from "./SwapSuccessLinks";

export default function SwapTransferApproveBlock({
  process,
  btnHandler,
  formData,
  transactionData,
}) {
  return (
    <>
      {!process.isTransferApproved && (
        <>
          {process.state == STEP_TRANSFER_START && (
            <div className="transfers__notification transfers__notification_proccess">
              <span className="loader-blue"></span>
              <span>Transfer transaction approve required in {formData.routeFrom.name}</span>
            </div>
          )}
          {process.state == STEP_TRANSFER_REJECTED && (
            <div className="transfers__notification transfers__notification_attention">
              Transfer approve was rejected in {formData.routeFrom.name} {" "} 
              <a
                className="link"
                href="#"
                onClick={() => btnHandler(BTN_KEY_TRANSFER_RETRY)}
              >
                Try one more time
              </a>
            </div>
          )}
        </>
      )}
      {process.isApproved && process.state >= STEP_TRANSFER_APPROVED && (
        <div className="transfers__notification transfers__notification_success">
          <span>
            Transaction approved successfully in {formData.routeFrom.name}
          </span>
        </div>
      )}
      {process.isTransferApproved &&
        process.isInNetworkApproveRequired &&
        process.state == STEP_TRANSFER_APPROVED && (
          <div className="transfers__notification transfers__notification_proccess">
            <span className="loader-blue"></span>
            <span>
              Transfer transaction is minting in {formData.routeFrom.name}
            </span>
          </div>
        )}
      {process.isTransferApproved &&
        !process.isInNetworkApproveRequired &&
        process.state == STEP_TRANSFER_APPROVED && (
          <div className="success-message">
            <span className="loader-blue"></span>
            <span className="success-message__title">
              {!process.isInNetworkApproveRequired && (
                <>You will recieve in a few minutes</>
              )}
              {process.isInNetworkApproveRequired && (
                <>Transaction proccesing by network {formData.routeFrom.name}</>
              )}
            </span>
            {!process.isInNetworkApproveRequired && (
              <SwapSuccessLinks
                formData={formData}
                process={process}
                btnHandler={btnHandler}
                transactionData={transactionData}
              ></SwapSuccessLinks>
            )}
          </div>
        )}
    </>
  );
}
