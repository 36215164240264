import { useState } from "react";
import { getPeriods } from "../../data/ranking";

function PeriodSelector(props){
    const [isOpen, toogleDropdown] = useState(false);
    
    const periods =getPeriods();
    const [periodId, setPeriod] = useState('day');
    
    let currentPeriod = periods.find( v => v.id === periodId);
    function setPeriodItem(period){
      setPeriod(period.id);
      toogleDropdown(false);
      if(props.onPeriodSelected){
        props.onPeriodSelected(period.id);
      }
    }
    
    return ( <div className={props.wrapperClass +' ' + (isOpen ? 'active' : '')}>
        <div className="select__button" onClick={() => {
            toogleDropdown(!isOpen)
            }} onBlur={() => {toogleDropdown(!isOpen)}}>
          Period: {currentPeriod.title}
        </div>
        <div className="select__dropdown">
        {periods.map((period, index) => (
          <div key={'period' + index} className={"select__item " + (periodId === period.id ? ' select__item_active' : '')} onClick={() => setPeriodItem(period)} >
          <span className="select__item-name">{period.title}</span>
        </div>
      ))
        }
        </div>
        </div>
    );
}

export default PeriodSelector;