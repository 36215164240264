import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BTN_KEY_APPROVE,
  BTN_KEY_CHAIN_CHANGE,
  BTN_KEY_UPDATE_RATE,
} from "../../constants/SwapButtonConstants";
import {
  amountErrorText,
  STEP_FORM_FILL,
  STEP_FORM_NO_ROUTE_FOUND,
  STEP_LOAD_QUOTA,
  STEP_READY_APPROVE,
  STEP_SWITCH_NETWORK,
  STEP_UPDATE_RATES,
} from "../../constants/TransferConstants";
import {
  connectWalletPopupToggle,
  walletTypeDialogToggle,
} from "../../features/walletService/walletService";
import {
  IsTvmApproveRequired,
  getNetworkNameByChainId,
  isVenomRoute,
} from "../../utils/NetworkList";
import { isAmountEmpty, isAmountLess } from "../../utils/TransferApiUtil";
import { CHAIN_TYPE_EVM, CHAIN_TYPE_TVM } from "../../constants/ChainTypes";
import { getWallet } from "../../utils/WalletUtil";

export default function SwapButton({
  transactionStep,
  formData,
  tokenBalance,
  btnHandler,
}) {
  const notificationState = useSelector(
    (state) => state.notificationPopupManager
  );
  const dispatch = useDispatch();

  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const connectingWallet = useSelector(
    (state) => state.walletServiceProvider.connectingWallet
  );

  const walletInfo = getWallet(connectedWallets);

  let isWalletConnected = walletInfo?.isConnected;

  const isOverBalance = useMemo(() => {
    if (
      notificationState.show &&
      notificationState.caption.startsWith(amountErrorText)
    ) {
      return true;
    }
    if (!isWalletConnected) {
      return false;
    }
    if (isAmountEmpty(formData.amountFrom)) {
      return false;
    }
    if (isAmountLess(formData.amountFromUSD)) {
      return true;
    }
    return parseFloat(formData.amountFrom) > parseFloat(tokenBalance);
  }, [
    formData.amountFrom,
    formData.amountFromUSD,
    tokenBalance,
    isWalletConnected,
    notificationState,
    amountErrorText,
  ]);

  const isVenomRequired = isVenomRoute(formData);

  const tvmWallet = getWallet(connectedWallets, CHAIN_TYPE_TVM,false);

  isWalletConnected =
    isWalletConnected && (!isVenomRequired || (isVenomRequired && tvmWallet));
  return (
    <>
      {!isWalletConnected && (
        <button
          type="button"
          className="transfers-form__submit btn btn_primary"
          onClick={() => dispatch(connectWalletPopupToggle())}
        >
          {connectingWallet && <span className="loader"></span>}
          Connect your  {!tvmWallet && isVenomRequired ? 'Venom' : 'Metamask'} wallet
        </button>
      )}
      {isWalletConnected &&
        transactionStep == STEP_FORM_FILL &&
        !isOverBalance && (
          <button
            type="button"
            className="transfers-form__submit btn btn_primary"
            onClick={() => {}}
            disabled
          >
            Swap
          </button>
        )}
      {isWalletConnected && transactionStep == STEP_FORM_NO_ROUTE_FOUND && (
        <button
          type="button"
          className="transfers-form__submit btn btn_primary"
          onClick={() => {}}
          disabled
        >
          Change destination token
        </button>
      )}
      {isWalletConnected &&
        transactionStep == STEP_LOAD_QUOTA &&
        !isOverBalance && (
          <button className="transfers-form__submit btn btn_primary">
            {/* <span className="loader"></span> */}
            Searching Routes
          </button>
        )}
      {isWalletConnected &&
        formData.routeFrom &&
        transactionStep == STEP_SWITCH_NETWORK && (
          <button
            type="button"
            className="transfers-form__submit btn btn_primary"
            onClick={() => btnHandler(BTN_KEY_CHAIN_CHANGE)}
          >
            Switch network to{" "}
            {getNetworkNameByChainId(
              formData.routeFrom.chainId,
              formData.routeFrom.name
            )}
          </button>
        )}
      {isWalletConnected &&
        !isOverBalance &&
        transactionStep == STEP_READY_APPROVE && (
          <button
            type="button"
            className="transfers-form__submit btn btn_primary"
            onClick={() => btnHandler(BTN_KEY_APPROVE)}
          >
            Swap
          </button>
        )}
      {isWalletConnected &&
        !isOverBalance &&
        transactionStep == STEP_UPDATE_RATES && (
          <button
            type="button"
            className="transfers-form__submit btn btn_primary"
            onClick={() => btnHandler(BTN_KEY_UPDATE_RATE)}
          >
            Update rates
          </button>
        )}
      {isWalletConnected &&
        ![STEP_SWITCH_NETWORK, STEP_FORM_NO_ROUTE_FOUND].includes(
          transactionStep
        ) &&
        isOverBalance && (
          <button
            type="button"
            disabled
            className="transfers-form__submit btn btn_primary"
          >
            Insufficient Funds
            <br /> on departure Chain
          </button>
        )}
    </>
  );
}
