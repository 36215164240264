import { useEffect } from "react";
import { STEP_READY_APPROVE } from "../../constants/TransferConstants";
import { useTimer } from "../common/useTimer";

export function useTransferRouteRefresh(
  transactionStep,
  callbackAfterTimerEnd
) {

  useEffect(() => {
    if (transactionStep != STEP_READY_APPROVE) {
      endTimer();
    }
  }, [transactionStep]);

  const callback = () => {
    if (transactionStep == STEP_READY_APPROVE) {
      callbackAfterTimerEnd();
    }
  };
  const { startTimer, endTimer, timeLeft } = useTimer(callback);

  return { startTimer, endTimer, timeLeft };
}
