import { useState, useEffect, useRef } from "react";
import { BALANCE_STATE_GETED, BALANCE_STATE_START } from "../walletGateway/useGatewayBalance";
import {  PROVIDER_VENOM } from "../../constants/ProviderTypes";
import { VenomWalletWebProvider } from "../../services/venom/venomWalletWebProvider";
import { ethers } from "ethers";
import { truncateDecimals } from "../../features/walletService/utils";

function useVenomBalance(balanceState, setBalanceState) {
  useEffect(() => {

    const getVenomBalance = async (
      contractAddress,
      decimals,
      venomProvider
    ) => {
      let result = {
        hasError: false,
        error: null,
        balance: 0,
      };
      try {
        console.log(contractAddress,'contractAddress')
        let balance = await VenomWalletWebProvider.getTokenBalance(
          contractAddress,
          venomProvider
        );
        balance = parseFloat(ethers.utils.formatUnits(balance, decimals));
        balance = truncateDecimals(balance); //.toFixed(4);
        result.balance = balance;
      } catch (error) {
        result.hasError = true;
        result.error = error;
      }
      return result;
    };

    
    const getVenomBalanceStart = async () => {
      const balance = await getVenomBalance(
        balanceState.crypto.contractAddress,
        balanceState.crypto.decimals,
        balanceState.venomProvider
      );
      setBalanceState((prevState) => ({
        ...prevState,
        error: balance.hasError ? balance.error : null,
        balance: balance.hasError ? 0 : balance.balance,
        state: BALANCE_STATE_GETED,
      }));
    };
    if (balanceState.providerType == PROVIDER_VENOM && balanceState.state == BALANCE_STATE_START) {
      getVenomBalanceStart();
    }
  }, [balanceState, setBalanceState]);
}

export default useVenomBalance;
