import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Outlet } from "react-router-dom";
import TopMenu from "./Header/TopMenu/TopMenu";
import FilterMobilePanel from "../FilterMobile/FilterMobilePanel";
import PopupBlock from "./Header/PopupBlock/PopupBlock";
import { IsAsterizmPage } from "../../utils/RouteUtils";

function Layout() {
  return (
    <>
      <Header />
      <FilterMobilePanel />
      <PopupBlock/>
      <div className={"container " + (IsAsterizmPage() ? 'container_dark' : '')}>
        <TopMenu />
        <Outlet />
        <Footer />
      </div>
    </>
  );
}
export default Layout;
