import { useState, useEffect, useRef } from "react";
import { utils } from "ethers";
import { useAccount } from "wagmi";
import { getNetwork } from "@wagmi/core";
import {
  disconnect,
  networkChanged,
  setWallet,
} from "../../features/walletService/walletService";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "../common/usePrevious";
import { localStorageObj } from "../../features/localstorage";
import { WalletList } from "../../constants/WalletList";
import { getWalletByTransport } from "../../utils/WalletUtil";
import { TRANSPORT_WAGMI } from "../../constants/ProviderTypes";

function useWagmiAccount() {
  const dispatch = useDispatch();
  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const walletInfo = getWalletByTransport(
    connectedWallets,
    TRANSPORT_WAGMI,
    true
  );

  const { chain, chains } = getNetwork();
  const { address, connector, isConnected, isConnecting } = useAccount({
    onConnect({ address, connector, isReconnected }) {
      const walletType = WalletList.find((v) => v?.connector == connector.id);
      localStorageObj.set("providerType", walletType.key);
      localStorageObj.set("transportType", TRANSPORT_WAGMI);
      dispatch(
        setWallet({
          accountAddress: address,
          networkChainId: chain?.id,
          providerType: walletType.key,
          isConnected: true,
          transportType: TRANSPORT_WAGMI,
        })
      );
    },
    onDisconnect() {
      if (
        isConnected &&
        walletInfo.isConnected &&
        walletInfo.transportType == TRANSPORT_WAGMI
      ) {
        dispatch(disconnect());
      }
    },
  });

  useEffect(() => {
    if (chain) {
      dispatch(networkChanged(chain.id));
    }
  }, [chain]);


  return;
}

export default useWagmiAccount;
