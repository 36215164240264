import React from 'react'

class ReportRankingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {curPage:0,pageCount:15};
  }

  nextPage(){
    if( this.state.curPage + 1 < Math.ceil(this.props.tableData.length / this.state.pageCount) +1  )
    this.setState({
      curPage:this.state.curPage + 1
    })
  }

  prevPage(){
    if( this.state.curPage > 0)
    this.setState({
      curPage:this.state.curPage - 1
    })
  }
  
  formatTvlInUsd(tvlInUsd) {
    let amount = parseFloat(tvlInUsd || '0').toFixed(2);
    return parseFloat(amount).toLocaleString("en-US",{style: 'currency', currency:'USD'})
  }

  render() {
    return                                 <div className="rankings-tables">
    <div className="content-tabs">
      <label className="content-tab">
        <input className="content-tab__input" type="radio" name="tablesTab" defaultChecked={true}/>
        <span className="content-tab__button">Asset ranking</span>
      </label>
      <label className="content-tab disabledBlock">
        <input className="content-tab__input" type="radio" name="tablesTab"/>
        <span className="content-tab__button">TOP profitable bridges</span>
      </label>
      <label className="content-tab disabledBlock">
        <input className="content-tab__input" type="radio" name="tablesTab"/>
        <span className="content-tab__button">TOP richest users ranking</span>
      </label>
    </div>
    <div className="rankings-tables__container">
      <div className="rankings-tables__content-block" id="assetsRankingCnt">
        <h2 className="rankings-tables__title">
          Asset ranking
          <button className="tooltip-btn tooltip-btn_m">?</button>
        </h2>
        <table className="rankings-table">
          <thead>
            <tr>
              <th>Ranking</th>
              <th>Label</th>
              <th>TVL (USD)</th>
            </tr>
          </thead>
        </table>              
        <div className="rankings-tables__table-container">
          <table className="rankings-table">
            <tbody>
              {
                this.props.tableData.filter((value,index) =>{
                  return index >= this.state.curPage * this.state.pageCount && index < ( (this.state.curPage + 1) * this.state.pageCount)  
                }).map((item,key) => (
                  <tr key={item.title}>
                   <td>{this.state.curPage * this.state.pageCount + key+ 1}</td>
                   <td>{item.title}</td>
                  <td>{this.formatTvlInUsd(item.tvlInUsd)}</td>
                </tr>
                ))
              } 
            </tbody>
          </table>
        </div>  
        <div className="pagination">
          <span className="pagination__title">Page</span>
          <input className="pagination__input" type="text" defaultValue={this.state.curPage + 1}/>
          <span className="pagination__total">of { Math.ceil(this.props.tableData.length / this.state.pageCount) +1 }</span>
          <div className="pagination__arrows">
            <button className="pagination__arrow pagination__arrow_left " onClick={()=> this.prevPage()}></button>
            <button className="pagination__arrow pagination__arrow_right" onClick={()=> this.nextPage()}></button>
          </div>
        </div>            
      </div>
    </div>
  </div>

}
}
export default ReportRankingTable
