import React from "react";
import FaqList from "../../components/Faq/FaqList";
import SwapMenu from "../../components/SwapMenu/SwapMenu";
import { TELEGRAM_CHAT, TWITTER_LINK } from "../../constants/SocialLinks";

export default function Faq(props) {
  const questionList = [
    {
      question: "What is Chainspot Router?",
      answer:
        "Chainspot is a cross-chain messaging and asset-transferring protocol that allows transferring liquidity across supported networks and chains in a secure, efficient and decentralized manner.",
    },
    {
      question: "Can I use Chainspot Router? What are the steps?",
      answer:
        `Sure you can. Just connect your wallet with enough liquidity to carry out the desired transactions, choose a destination and a target token, enter the desired amount, choose a route and press swap - et voilà! Chainspot Router is truly a no-brainer.`,
    },
    {
      question: 'What does "Open Beta" status mean? Are there any limitations?',
      answer: ` The only limitation we currently have is the minimum transaction amount (the equivalent of $25). This is a temporary measure - we introduced it because we are constantly connecting new bridges and DEXes, so the service may be unavailable periodically due to the updates. This doesn’t affect transaction safety. 
      There are no other functional or operational limitations for the open beta.`,
    },
    {
      question: "What networks are supported by Chainspot Router?",
      answer: `As of October 2022, Chainspot Router supports transactions between the following networks:
      Moonriver, Harmony, Ethereum, BSC, Moonbeam, CELO, Evmos, Fantom, Arbitrum One, FUSE, Avalanche, OKXChain, Polygon, Aurora, Cronos, Telos, Optimism, Boba, Gnosis. 
      The list of supported networks is constantly expanding - we're keeping an eye on it!
      `,
    },
    {
      question:
        "What are the criteria for the optimal route? Can I choose an alternative route?",
      answer: `<span>The optimal route is chosen based on the following parameters:</span>
      <ul className="marked-list">                    
        <li>Reliability of the blockchain bridge through which the transfer occurs</li>
        <li>Reliability of DEX</li>
        <li>The fees of involved bridge and networks</li>
        <li>Expected transaction time</li>
      </ul>`,
    },
    {
      question: "Can I choose different routes?",
      answer: `As practice shows, well-adjusted algorithms of route search pick the most adequate route (taking into account the ratio of swap execution time, gas and network fees and transaction security). To streamline the swap process, we currently offer the most efficient route based on the mentioned parameters.
      However, route customization (along with a visual representation of available routes) is in the development pipeline - we will surely provide this feature in the foreseeable future.
       <a href="${TWITTER_LINK}" target="_blank">Stay tuned </a>, we are planning to introduce even more functional updates soon.`,
    },
    {
      question:
        'Why do I see the "Insufficient funds on departure chain" notification?',
      answer: `This error means there are not enough tokens in the connected wallet to complete the transaction. Firstly, make sure that you connected the correct wallet (it happens even to the best of us from time to time). 
      The solution is either to reduce the transaction amount or transfer more funds to the connected wallet. Please keep in mind that there have to be enough tokens in the connected wallet to cover the gas fee.
      `,
    },
    {
      question:
        "Why is the exchange rate automatically updated and the destination token amount recalculated before any transaction?",
      answer: `Hundreds and thousands of transactions occur every second on various DEXes, which can affect the user's chosen route and change the value of the token transfer.`,
    },
    {
      question: "Is there a transaction fee?",
      answer: `Yes, a modest fee is included in the gas price (hence the “transaction fee and gas price” data provided in our router and marked with this icon
         <img src="/images/est-icon-fuel.svg" width="16" height="16" style="position: relative; bottom: -3px">). 
         Unfortunately, there is no way to get rid of gas fees which are volatile and depend on the commissions of the networks involved in each transaction.`,
    },
    {
      question:
        "Is there any way to contact you in case I have questions or feedback?",
      answer: `Sure, please click <a className="text-link" href="${TELEGRAM_CHAT}" target="_blank">here</a> to contact our Support team. We will be happy to help or hear comments and suggestions about our products.`,
    },
  ];
  return (
    <main className="main">
      <section className="page-container">
        <div className="wrapper">
          <div className="transfers-wrapper">
            <div className="faq">
              <SwapMenu />
              <FaqList questionList={questionList} />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
