import {
  STEP_TRANSFER_FINISHED,
} from "../../constants/TransferConstants";
import SwapSuccessLinks from "./SwapSuccessLinks";

export default function SwapTransferFinishedBlock({
  process,
  btnHandler,
  formData,
  transactionData,
}) {
  return (
    <>
      {process.isFinished && process.state == STEP_TRANSFER_FINISHED && (
        <div className="success-message">
          <span className="success-message__title">You will soon receive</span>

          <SwapSuccessLinks
            formData={formData}
            process={process}
            btnHandler={btnHandler}
            transactionData={transactionData}
          ></SwapSuccessLinks>
        </div>
      )}
    </>
  );
}
