import { BTN_KEY_IN_TRANSFER_RETRY, BTN_KEY_TRANSFER_RETRY } from "../../constants/SwapButtonConstants";
import {
  STEP_IN_TRANSFER_APPROVED,
  STEP_IN_TRANSFER_REJECTED,
  STEP_IN_TRANSFER_REJECTED_BY_CONTRACT,
  STEP_IN_TRANSFER_START,
  STEP_TRANSFER_APPROVED,
  STEP_TRANSFER_FINISHED,
} from "../../constants/TransferConstants";
import { makeBrigeUrl } from "../../hooks/venom/VenomUtil";
import { isVenomRoute } from "../../utils/NetworkList";
import SwapSuccessLinks from "./SwapSuccessLinks";

export default function SwapTransferInNetworkApproveBlock({
  process,
  btnHandler,
  formData,
  transactionData,
}) {
  let isVenomPath = isVenomRoute(formData);
  return (
    <>
      {!process.isInNetworkApproved && (
        <>
          {process.state == STEP_IN_TRANSFER_START && (
            <div className="transfers__notification transfers__notification_proccess">
              <span className="loader-blue"></span>
              <span>Transfer transaction approve required in {formData.routeTo.name}</span>
            </div>
          )}
          {process.state == STEP_IN_TRANSFER_REJECTED && (
            <div className="transfers__notification transfers__notification_attention">
              Transfer approve was rejected in {formData.routeTo.name} {" "}
              <a
                className="link"
                href="#"
                onClick={() => btnHandler(BTN_KEY_IN_TRANSFER_RETRY)}
              >
                Try one more time
              </a>
            </div>
          )}
        </>
      )}
      {process.isInNetworkApproved &&
        [STEP_IN_TRANSFER_APPROVED, STEP_TRANSFER_FINISHED].includes(
          process.state
        ) && (
          <div className="transfers__notification transfers__notification_success">
            <span>Transaction approved successfully in {formData.routeTo.name}</span>
          </div>
        )}
      {process.state == STEP_IN_TRANSFER_REJECTED_BY_CONTRACT && (
        <div className="transfers__notification transfers__notification_attention">
          Transfer was rejected by contract in {formData.routeTo.name} {" "}
        </div>
      )}
      {process.isInNetworkApproved &&
        process.state == STEP_IN_TRANSFER_APPROVED && (
          <div className="success-message">
            <span className="loader-blue"></span>
            <span className="success-message__title">
              <>You will receive tokens in a few minutes</>
            </span>
            {!process.isInNetworkApproveRequired && (
              <SwapSuccessLinks
                formData={formData}
                process={process}
                btnHandler={btnHandler}
                transactionData={transactionData}
              ></SwapSuccessLinks>
            )}
          </div>
        )}
      {
        isVenomPath && process.state >= STEP_IN_TRANSFER_START && (
          <div className="transfers__notification transfers__notification_success">
            <span>Check your transaction on Venom Bridge <a href={makeBrigeUrl(process.transfetTx)} target="_blank"> scan
            </a> </span>
          </div>
        )
      }
    </>
  );
}
