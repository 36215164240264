import { useState, useEffect, useRef } from "react";
import { useContract, useSigner, erc20ABI } from "wagmi";
import { PROVIDER_METAMASK } from "../../features/walletService/walletService";
import { useSelector } from "react-redux";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import { STEP_APPROVE_STARTED } from "../../constants/TransferConstants";
import useWagmiAlowance from "../wagmi/useWagmiAlowance";
import useMetasmaskAllowance from "../metamask/useMetasmaskAllowance";
import { isZeroAddress } from "../../features/walletService/utils";
import useTrustAllowance from "../trust/useTrustAllowance";
import { getWallet } from "../../utils/WalletUtil";

export const APPROVAL_STATE_INACTIVE = 0;
export const APPROVAL_STATE_STARTED = 1;
export const APPROVAL_STATE_FINISHED = 2;

const initApprovalState = {
  amount: null,
  tokenContractAddress: null,
  accountAddress: null,
  approvalAddress: null,
  providerType: null,
  transportType: null,
  isApproved: false,
  transaction: null,
  state: APPROVAL_STATE_INACTIVE,
};

function useGatewayApprove(
  amount,
  tokenContractAddress,
  approvalAddress,
  transactionStep,
  callback
) {
  let [approvalState, setApprovalState] = useState(initApprovalState);
  // const callbackRef = useRef();
  // useEffect(() => {
  //   callbackRef.current = callback; // Update ref to the latest callback.
  // }, [callback]);

  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  const walletInfo = getWallet(connectedWallets);

  useEffect(() => {
    if (
      approvalState.state === APPROVAL_STATE_FINISHED &&
      transactionStep === STEP_APPROVE_STARTED
    ) {
      callback({
        isApproved: approvalState.isApproved,
        transaction: approvalState.transaction,
      });
      setApprovalState((prevState) => ({
        ...prevState,
        state: APPROVAL_STATE_INACTIVE,
      }));
    }
  }, [approvalState, transactionStep]);

  useEffect(() => {
    if (
      transactionStep === STEP_APPROVE_STARTED &&
      approvalState.state === APPROVAL_STATE_INACTIVE
    ) {
      if (isZeroAddress(tokenContractAddress)) {
        callback({
          isApproved: true,
          transaction: null,
        });
        // return proccessResponce;
      } else {
        setApprovalState({
          amount: amount,
          tokenContractAddress: tokenContractAddress,
          accountAddress: walletInfo.accountAddress,
          approvalAddress: approvalAddress,
          providerType: walletInfo.providerType,
          transportType: walletInfo.transportType,
          isApproved: false,
          transaction: null,
          state: APPROVAL_STATE_STARTED,
        });
      }
      // getAllowance();
    }
  }, [
    amount,
    tokenContractAddress,
    approvalAddress,
    transactionStep,
    callback,
    walletInfo,
  ]);

  useWagmiAlowance(approvalState, setApprovalState);
  useMetasmaskAllowance(approvalState, setApprovalState);
  useTrustAllowance(approvalState, setApprovalState);
  return;
}

export default useGatewayApprove;
