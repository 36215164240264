import { MAX_PENDING_COUNT } from "../../constants/TransferConstants";
import { PAGE_PENDING } from "../../pages/SwapHistory/SwapHistory";
import HistoryTransferItem from "./HistoryTransferItem";
import { PAGE_INIT } from "./SwapHistoryList";

export default function PendingTransfersList({
  pendingTransfers,
  pendingPageInfo,
  pageType,
  setPageType,
  gotoPrev,
  gotoNext,
}) {
  return (
    <section className="history__section">
      {pageType == PAGE_INIT && (
        <div className="history__section-header">
          <h2 className="history__section-title">Pending execution</h2>
          {pendingPageInfo.totalCount > MAX_PENDING_COUNT && (
            <a
              href="#"
              className="show-all-btn"
              onClick={() => setPageType(PAGE_PENDING)}
            >
              Show all
            </a>
          )}
        </div>
      )}
      <div className="history__items">
        {pendingTransfers.map((transfer) => (
          <HistoryTransferItem key={transfer.id} transfer={transfer} />
        ))}
      </div>
      {pageType == PAGE_PENDING && pendingPageInfo && (
        <div className="page-block__footer">
          <div className="pagination">
            <span>Page</span>
            <input
              className="pagination__input"
              type="text"
              value={pendingPageInfo.page}
              onChange={() => {}}
            />
            <span>of {pendingPageInfo.pageCount}</span>
            <div className="pagination__buttons">
              <button
                className="pagination__button pagination__button_prev"
                disabled={pendingPageInfo.page == 1}
                onClick={gotoPrev}
              ></button>
              <button
                className="pagination__button pagination__button_next"
                disabled={pendingPageInfo.page == pendingPageInfo.pageCount}
                onClick={gotoNext}
              ></button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
